import React, { useEffect, useState } from "react";
import TextEditor from "./reportComponents/TextEditor";
import { postSwotDetails, getAttributeDetails, fetchChallengesAndRecommendation } from "../service/QuestionnaireApi";
import { ChallengeDetail, SWOTProps } from "../interface/GenerateReportModel";
import { useDatabricksContextObject } from "./reportComponents/DatabricksContextObject";
interface AttributeDetail {
  attributeId: string;
  attributeName: string;
  attributeMasterId: string;
}
interface Attribute {
  attributeId: string;
  attributeName: string;
  attributeValue: string;
}

//PS_Databricks_Swot_02 - PS_Databricks_Swot_19
const SWOT: React.FC<SWOTProps> = ({ reportData, setIsLoading,isBusinessOverviewUpdated,convertswot }) => {

  const [swotData, setSwotData] = useState<Attribute[]>([]);
  const [swotDescription, setSwotDescription] = useState("");
  const [error, setError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showGenerateToaster, setShowGenerateToaster] = useState(false);
  const [showSaveToaster, setShowSaveToaster] = useState(false);
  const [showGenerateMessage, setShowGenerateMessage] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [toastState, setToastState] = useState({ loading: false, success: false, error: false, message: "" });
  const [isGenerated, setIsGenerated] = useState<boolean>();
  const [hasExistingSwotData, setHasExistingSwotData] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editComponentDescription, setEditComponentDescription] = useState("");
  const [editName, setEditName] = useState("");
  const [attributeMasterId, setAttributeMasterId] = useState<string>("");
  const [attributeMapping, setAttributeMapping] = useState<any>()
  const [challengesAndRecommendation, setChallengesAndRecommendation] = useState<any>();

  // const { isBusinessOverviewUpdated, setIsBusinessOverviewUpdated } = useDatabricksContextObject()
  const{businessOverviewData} = useDatabricksContextObject();
  const { isChallengesRecommendationsDataUpdatedswot, setIsChallengesRecommendationsDataUpdatedswot } = useDatabricksContextObject()
  
  const isBusinessOverviewEmpty = businessOverviewData.trim() === "";
  const [isChallengesEmpty, setIsChallengesEmpty] = useState(false); 

  useEffect(() => {
    setIsChallengesEmpty(challengesAndRecommendation?.length === 0);
}, [challengesAndRecommendation]);

  //PS_Databricks_Swot_20
  useEffect(() => {
    getChallengesAndRecommendation();
    if(!(isBusinessOverviewUpdated ||isChallengesRecommendationsDataUpdatedswot) ){
    getInitialPageData(); 
    }

  }, []);

  //PS_Databricks_Swot_21 - PS_Databricks_Swot_43
  const getInitialPageData = async () => {
    setIsLoading(true);
    const payload = {
      organizationId: reportData.organizationId,
      verticalId: reportData.verticalId,
      projectId: reportData.projectId,
      connectorId: reportData.connectors.find((c: any) => c.connectorName === "SWOT")?.connectorId,
    };
    try {
      const swotResponse = await getAttributeDetails(payload);
      if (swotResponse.status === 200) {
        setIsLoading(false);
        setSwotData(swotResponse.data || []);
        setHasExistingSwotData(swotResponse.data.length > 0);
        const mapping: { [key: string]: string } = {};
        swotResponse.data.forEach((item: AttributeDetail) => {
          mapping[item.attributeName] = item.attributeId;
        });
        setSwotData(swotResponse.data);
        setAttributeMapping(mapping);
        setAttributeMasterId(swotResponse.data[0].attributeMasterId);

      } else {
        setIsLoading(false);
        setShowGenerateToaster(false);
        console.error('Failed to fetch SWOT details:', swotResponse);
      }
    } catch (error) {
      setIsLoading(false);
      setShowGenerateToaster(false);
      console.error('Failed to fetch SWOT details:', error);
    }
  };
  const getChallengesAndRecommendation = async () => {
    setIsLoading(true);
    let payload = {
        organizationId: reportData.organizationId,
        projectId: reportData.projectId,
        verticalId: reportData.verticalId,
    };

    try {
        let response = await fetchChallengesAndRecommendation(payload);
        setIsLoading(false);
        if (response.status === 200) {
            setChallengesAndRecommendation(response.data)
        } else {
            setIsLoading(false);
            console.error('API call failed');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};


  //PS_Databricks_Swot_45 - PS_Databricks_Swot_77
  const getSwotDescription = async () => {
    setShowGenerateToaster(true);
    const challenges = challengesAndRecommendation?.map((item: any) => ({
      title: item.title,
      challenges: item.challenges,
      recommendations: item.recommendation
    }));
    const payload = {
      organizationId: reportData.organizationId,
      verticalId: reportData.verticalId,
      VerticalName: reportData.verticalName,
      projectId: reportData.projectId,
      connectorId: reportData.connectors.find((c: any) => c.connectorName === "SWOT")?.connectorId,
      connectorName: "SWOT",
      businessOverview: businessOverviewData,
      attributeMasterId: "",
      attributeRecords: [
        { attributeId: "", attributeName: "Strengths", attributeValue: "" },
        { attributeId: "", attributeName: "Weaknesses", attributeValue: "" },
        { attributeId: "", attributeName: "Opportunities", attributeValue: "" },
        { attributeId: "", attributeName: "Threats", attributeValue: "" }
      ],
      challenges:challenges
    };

    try {
      const generateSwotApiResponse = await postSwotDetails(payload);
      if (generateSwotApiResponse?.data && generateSwotApiResponse?.status === 200) {
        setShowGenerateMessage(true);
        setIsGenerated(true);
        convertswot(false) 
        setIsChallengesRecommendationsDataUpdatedswot(false)             
        setSwotData(generateSwotApiResponse?.data);

      } else {
        setToastState({
          error: true,
          loading: false,
          success: false,
          message: "API failed"
        });
      }
    } catch (error) {
      setToastState({
        error: true,
        loading: false,
        success: false,
        message: "API failed"
      });
      console.error('Error generating SWOT analysis:', error);
    } finally {
      setShowGenerateToaster(false);
      setShowGenerateMessage(false);
    }
  };

  //PS_Databricks_Swot_80 - PS_Databricks_Swot_106
  const handleSave = async (newValue: string) => {

    const updatedSwotData = JSON.parse(newValue);
    const attributeRecords: Attribute[] = updatedSwotData.map((item: Attribute) => ({
      attributeId: item.attributeId,
      attributeName: item.attributeName,
      attributeValue: item.attributeValue,
    }));
    const challenges = challengesAndRecommendation?.map((item: any) => ({
      title: item.title,
      challenges: item.challenges,
      recommendations: item.recommendation
    }));

    const payload = {
      organizationId: reportData.organizationId,
      verticalId: reportData.verticalId,
      VerticalName: reportData.verticalName,
      projectId: reportData.projectId,
      connectorId: reportData.connectors.find((c: any) => c.connectorName === "SWOT")?.connectorId,
      connectorName: "SWOT",
      businessOverview: businessOverviewData,
      attributeMasterId: attributeMasterId,
      attributeRecords,
      challenges:challenges
    };
    handleCloseEditModal();
    setShowSaveToaster(true);

    try {
      const updatedGenerateSwotApiResponse = await postSwotDetails(payload);
      if (updatedGenerateSwotApiResponse.data && updatedGenerateSwotApiResponse.status === 200) {
        setShowSaveMessage(true);
        await new Promise(resolve => setTimeout(resolve, 5000));
        setShowSaveMessage(false);

        const updatedSwotData = updatedGenerateSwotApiResponse.data.map((item: any) => ({
          attributeId: item.attributeId,
          attributeName: item.attributeName,
          attributeValue: item.attributeValue,
        }));
        

        setSwotData(updatedSwotData);

      } else {
        setToastState({
          error: true,
          loading: false,
          success: false,
          message: "API failed"
        });
      }
    } catch (error) {
      setToastState({
        error: true,
        loading: false,
        success: false,
        message: "API failed"
      })
    } finally {
      setShowSaveToaster(false);
    }
  };

  //PS_Databricks_Swot_108 - PS_Databricks_Swot_109
  const handleEdit = (attributeId: string) => {
    const itemToEdit = swotData.find((item: Attribute) => item.attributeId === attributeId);
    if (itemToEdit) {
      setEditingKey(itemToEdit.attributeId);
      setEditName(itemToEdit.attributeName)
      setEditComponentDescription(itemToEdit.attributeValue); // Set the value to edit
      setIsEditPopupOpen(true);
    }
  };

  //PS_Databricks_Swot_111
  const handleCloseEditModal = () => {
    setIsEditPopupOpen(false);
    setEditingKey(null);
  };

  //PS_Databricks_Swot_112 - PS_Databricks_Swot_116
  const handleEditSave = (newValue: string) => {
    if (editingKey) {
      const indexToEdit = swotData.findIndex((item: Attribute) => item.attributeId === editingKey);

      if (indexToEdit !== -1) {
        const updatedSwotData = [...swotData];
        updatedSwotData[indexToEdit] = {
          ...updatedSwotData[indexToEdit],
          attributeValue: newValue 
        };

        setSwotData(updatedSwotData);
        handleSave(JSON.stringify(updatedSwotData)); 
        // handleCloseEditModal();
      }
    }
  };

  //PS_Databricks_Swot_117
  const handleClearEditModal = () => {
    setEditComponentDescription("");
  };

  //PS_Databricks_Swot_118 - PS_Databricks_Swot_122
  const renderMessage = () => {
    if (hasExistingSwotData) {
      return null;
    }
    if (isBusinessOverviewEmpty && isChallengesEmpty) {
      return "Please Upload a transcript and provide challenges and recommendation to generate SWOT";
    } else if (isBusinessOverviewEmpty) {
      return "Please upload a transcript to generate SWOT";
    } else if (isChallengesEmpty) {
      return "Please add challenges and recommendation to generate SWOT";
    }
    return null;
  };

  return (
    <>
      <div className="" id="v-pills-performEff" role="tabpanel">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 mb-5">
            <div className="ribbon py-2 px-3 ms-md-4 d-flex">
              <span className="font-12 font-medium">
                Please click Generate SWOT again if a new transcript is uploaded or if there are changes in the Challenges and Recommendations.
              </span>
              <span className="ribbon-border"></span>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-12">
            <div className="ps-lg-4">
              <h3 className="font-20 font-semibold mt-1 mb-4">SWOT</h3>
              {renderMessage() ? (
                <div className="mt-5">
                  <p className="font-medium font-14 color-black">
                    {renderMessage()}
                  </p>
                </div>
              ) : (swotData.length==0) ? (
                <div className="mt-5">
                  <button
                    className="black-btn px-3 p-2 font-14 font-semibold"
                    onClick={getSwotDescription}
                  >
                    Generate SWOT
                  </button>
                </div>
              ) : ( 
                swotData?.map((item: Attribute) => (
                  <div className="mt-5" key={item.attributeId}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h3 className="font-18 font-semibold color-black mb-0">{item.attributeName}</h3>
                      <button
                        type="button"
                        className="btn edit-btn font-12 font-semibold"
                        onClick={() => handleEdit(item.attributeId)}
                      >
                        Edit
                      </button>
                    </div>
                    <ul className="mt-3 ps-4">
                      <li className="color-black font-regular font-14 mb-1">
                        <div dangerouslySetInnerHTML={{ __html: item.attributeValue }} />
                      </li>
                    </ul>
                  </div>
                ))
              )}

            </div>
          </div>
        </div>
      </div>

      {isEditPopupOpen && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content custom-popup w-650">
                <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                  <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                    Edit {editName}
                  </h2>
                  <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close">
                    <img src="images/popup-close.svg" alt="Close" onClick={handleCloseEditModal} />
                  </button>
                </div>
                <div className="modal-body border-0 px-4">
                  <div className="row">
                    <div className="mt-3">
                      <label className="form-label font-14 font-medium color-black">
                        Description<span className="mandate">*</span>
                      </label>
                      <div className="mt-2">
                        <TextEditor
                          value={editComponentDescription}
                          onSave={(newValue: any) => {
                            setEditComponentDescription(newValue);
                            handleEditSave(newValue);
                          }}
                          onCancel={handleClearEditModal}
                          summaryType={editName}
                          verticalName={reportData.verticalName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showGenerateToaster && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img
                  src="images/loading.gif"
                  alt="toast-success"
                  style={{ height: "15px", width: "15px" }}
                />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Generating...
                </span>
              </div>
            </div>
          </div>
        </div>

      )}

      {showSaveToaster && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img
                  src="images/loading.gif"
                  alt="toast-success"
                  style={{ height: "15px", width: "15px" }}
                />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  Saving...
                </span>
              </div>
            </div>
          </div>
        </div>

      )
      }

      {showSaveMessage && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/toast-success.svg" alt="toast-success" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  saved successfully
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {showGenerateMessage && (
        <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
          <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
            <div className="toast-body d-flex align-items-center ">
              <div className="d-block me-3">
                <img src="images/toast-success.svg" alt="toast-success" />
              </div>
              <div className="d-block">
                <span className="font-medium font-16 color-grey">
                  SWOT has been generated successfully
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SWOT;