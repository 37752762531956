
import React, { useEffect, useRef, useState } from "react";
import JSZip from 'jszip';

export default function NotebookBasedConnector(props: any) {
  const { 
    activeConnector, 
    notebookFiles, 
    setNotebookFiles, 
    validateNotebookInput, 
    setConnectorFormData, 
    resetValidationMessages, 
    setShowConnectorForm 
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notebookFormValidationMsg, setNotebookFormValidationMsg] = useState<any>("");
  const attachment = useRef<HTMLInputElement | null>(null);
  const dropRef = useRef(null);

  const validateZipContents = async (file: File): Promise<string | null> => {
    const jszip = new JSZip();
    try {
      const zip = await jszip.loadAsync(file);
      for (const filename in zip.files) {
        const file = zip.files[filename];
        if (!file.dir && !file.name.toLowerCase().endsWith('.py') && !file.name.toLowerCase().endsWith('.ipynb')) {
          return `Invalid file detected: ${file.name}`;
        }
      }
      return null;
    } catch (error) {
      return "Failed to process the ZIP file.";
    }
  };

  const handleFiles = async (files: FileList | File[]) => {
    const fileArray = Array.from(files);
    const zipFiles = fileArray.filter(file => 
      file.type === 'application/zip' || file.type === 'application/x-zip-compressed'
    );

    if (zipFiles.length !== fileArray.length) {
      setNotebookFormValidationMsg("Please upload only ZIP files.");
      return;
    }

    for (const zipFile of zipFiles) {
      const errorMessage = await validateZipContents(zipFile);
      if (errorMessage) {
        setNotebookFormValidationMsg(errorMessage);
        return;
      }
    }

    setNotebookFiles((prevFiles: any) => [...prevFiles, ...zipFiles]);
    setNotebookFormValidationMsg("");
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFiles(event.target.files);
    }
  };

  const handleUpload = () => {
    setShowModal(false);
  };

  const handleRemoveFile = (fileName: string) => {
    setNotebookFiles((prevFiles: any) =>
      prevFiles.filter((file: any) => file.name !== fileName)
    );
  };

  const handleClear = () => {
    setNotebookFormValidationMsg("");
    setNotebookFiles([]);
    if (attachment.current) {
      attachment.current.value = '';
    }
  };

  useEffect(() => {
    const dropArea: any = dropRef.current;
    if (dropArea) {
      const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
      };

      const handleDragLeave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
      };

      const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer?.files) {
          handleFiles(e.dataTransfer.files);
        }
      };

      dropArea.addEventListener('dragover', handleDragOver);
      dropArea.addEventListener('dragleave', handleDragLeave);
      dropArea.addEventListener('drop', handleDrop);
      
      return () => {
        dropArea.removeEventListener('dragover', handleDragOver);
        dropArea.removeEventListener('dragleave', handleDragLeave);
        dropArea.removeEventListener('drop', handleDrop);
      };
    }
  }, []);

  return (
    <>
      <div ref={dropRef} className={`drag-drop-area ${isDragging ? 'dragging' : ''}`}>
        <div className="ribbon py-2 px-3 ms-md-4 mb-4">
          <span className="font-12 font-medium">
            Please note that some notebooks are not accessible directly. You will need to upload these manually, while the rest of the notebook codes can be accessed through the API.
          </span>
          <span className="ribbon-border" />
        </div>
        <h3 className="font-14 font-medium mt-4 mb-2 ms-md-4">Upload ZIP File <span className="mandate">*</span></h3>
        <div className="d-flex align-items-center gap-3 mb-5 ms-md-4">
          <button
            className="btn file-upload-outline rounded-3 d-flex align-items-center gap-2 font-12 font-semibold mt-2"
            onClick={() => setShowModal(true)}
          >
            <img src="../images/upload-icon-blue.svg" alt="upload-icon" />
            <span className="upload-text">
              Upload{notebookFiles?.length > 0 && <span>({notebookFiles?.length})</span>}
            </span>
          </button>
          <input
            type="file"
            ref={attachment}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".zip"
            multiple
          />
          {notebookFiles?.length > 0 && (
            <span className="font-12 font-medium color-grey">
              ({notebookFiles?.map((file: any) => file.name).join(', ')})
            </span>
          )}
        </div>
        {/* Validation Message */}
        {notebookFormValidationMsg && (
          <p className="mt-2 mb-0 color-red font-14 font-regular">
            {notebookFormValidationMsg}
          </p>
        )}
        {notebookFiles?.length > 0 && (
          <>
            <h3 className="font-18 color-black font-medium ms-md-4">Uploaded Files</h3>
            {notebookFiles?.map((file: any, index: any) => (
              <div key={index} className="d-flex align-items-center justify-content-between p-3 px-4 uploaded-file-container mb-2 gap-3 flex-wrap ms-md-4">
                <span className="d-flex align-items-center gap-3 font-14 font-medium color-black-v2">
                  <img src="../images/folder.svg" alt="doc-icon" />{file.name}
                </span>
                <button
                  type="button"
                  className="btn delete-btn font-medium font-12"
                  onClick={() => handleRemoveFile(file.name)}
                >
                  Delete
                </button>
              </div>
            ))}
          </>
        )}
        {isDragging && (
          <div className="drag-overlay">
            <p>Drop ZIP files here</p>
          </div>
        )}
        {showModal && (
          <>
            <div className="modal-backdrop fade show"></div>
            <div className="modal fade show" id="_addSessionPopup" style={{ display: 'block' }} aria-modal="true" role="dialog">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-popup">
                  <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                    <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                      Upload Notebook
                    </h2>
                    <button type="button" className="btn close-icon" onClick={() => setShowModal(false)}>
                      <img src="../images/popup-close.svg" alt="Close" />
                    </button>
                  </div>
                  <div className="modal-body border-0 px-4">
                    <button
                      className="btn upload-btn p-4 d-flex align-items-center justify-content-center flex-column gap-2 position-relative mb-3"
                      onClick={() => attachment.current?.click()}
                    >
                      <img src="../images/upload-icon.svg" alt="upload-icon" />
                      <span className="font-12 font-regular color-grey-v8 px-5 mx-sm-5">
                        Browse or drag and drop or click to choose files
                      </span>
                      <input
                        type="file"
                        className="form-control custom-file w-auto cust-upload"
                        onChange={handleFileChange}
                        ref={attachment}
                        multiple
                        style={{ display: 'none' }}
                      />
                    </button>
                    {notebookFiles?.map((file: any, index: any) => (
                      <div key={index} className="d-flex align-items-center justify-content-between p-3 px-4 uploaded-file-container mb-2">
                        <span className="d-flex align-items-center gap-3 font-14 font-medium color-black-v2">
                          <img src="../images/folder.svg" alt="doc-icon" />
                          {file.name}
                        </span>
                        <button
                          className="btn p-0 border-0 bg-transparent"
                          onClick={() => handleRemoveFile(file.name)}
                        >
                          <img className="close-icon" src="../images/close-icon-black.svg" alt="close-icon-black" />
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <p className='color-red mt-3 mx-4'>{notebookFormValidationMsg}</p>
                  </div>
                  <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={handleClear}>Clear</a>
                    <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleUpload}>Upload</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
        <button
          type="button"
          className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold"
          disabled={notebookFiles?.length === 0}
          onClick={() => { validateNotebookInput(notebookFiles) }}
        >
          Save
        </button>
        <a
          className="theme-link-btn cursor-pointer font-semibold text-center order-md-first"
          onClick={() => {
            setConnectorFormData({});
            resetValidationMessages(activeConnector);
            setShowConnectorForm(true);
          }}
        >
          Cancel
        </a>
      </div>
    </>
  );
}