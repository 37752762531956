import { useRef, useState, useEffect } from "react";
import ReportHorizontalBarChart from "./downloadReportComponents/ReportHorizontalChart";
import { getDownloadedPdfUrl, getDownloadReport, getSasToken } from "../service/ReportApi";
import { useLocation } from "react-router-dom";
import ReportTableComponent from "./downloadReportComponents/RecommendationForReport";
import ReportDonutChart from "./downloadReportComponents/ReportDonutChart";
import ReportAppLineChart from "./downloadReportComponents/ReportGraphChart";
import ReportGaugeChart from "./downloadReportComponents/ReportGaugeChart";
import uploadFileToBlob1 from "../helpers/BlobUpload";
import { floatButtonPrefixCls } from "antd/es/float-button/FloatButton";
import { getOrganization } from "../service/AssesmentApi";
import moment from "moment";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import ThreeReportTableComponent from "./downloadReportComponents/ReportRecommendation";
import CommonReportTempForServiceNow from "./downloadReportComponents/CommonTemplateForServiceNow";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import ToastComponent from "./ToastComponent";
import { toastModel } from "../interface/AdminModel";
import InfrastructureMapDownloadReport from "./InfrastructureMapDownloadReport"
import WafrDownloadReport from "./WafrDownloadReport";
import AwsMdpwDowloadReport from "./AwsMdpwDownloadReport";
import VMwareDownloadReport from "./VMwareDownloadReport";
import { encryptStorage } from "../constant/constant";
import { jwtDecode } from "jwt-decode";
import { getConnectorsData } from "../helpers/encryption";
import DatabricksDownloadReport from "./reportComponents/DatabricksDownloadReport";

export default function Pdf() {
  const toastData: toastModel = {
    toastType: "",
    toastHeaderMessage: "",
    toastBodyMessage: "",
  };

  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useState<any>();
  const [organizationName, setOrganizationName] = useState<any>();
  const [reportTime, setReportTime] = useState<any>();
  const [percentage, setPercentage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [triggerFunctions, setTriggerFunctions] = useState(false);
  const [awsLogo, setAwsLogo] = useState<string>("")
  const [frontPageLogo, setFrontPageLogo] = useState<string>("")
  const [zebLogo, setZebLogo] = useState<string>("")
  const [avasoftLogo, setAvasoftLogo] = useState<string>("")
  const [toDownload, setToDownload]= useState<string>("")
  const location = useLocation();
  const [reportData, setReportData] = useState<any>(location.state);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toastProp, setToastProp] = useState<toastModel>(toastData);
  const [hideToast, setHideToast] = useState<boolean>(true);
  const [reportPdfData, setReportPdfData] = useState<any>()

  const contentRef = useRef<any>(null);

  const saveHtmlAsFile = async () => {
    const htmlContent = contentRef.current.innerHTML;
    // Create a new Blob with the HTML content
    const blob = new Blob([htmlContent], { type: "text/html" });

    // Pass the created Blob directly to the upload function
    await uploadFileToBlob1(blob);
    await downloadReportApi(htmlContent);
  };

  const downloadReportApi = async (htmlContent: string) => {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    // Check for the presence of the class name
    const hasClassName = tempDiv.getElementsByClassName('new-page').length > 0;

    const verticalName = reportData?.data?.verticalName || reportData.verticalName;
    let response;
    if (hasClassName){
      response = await getDownloadedPdfUrl({ verticalName: verticalName, toDownload: toDownload})
    }
    else{
      response = await getDownloadReport({ toPdf: false, verticalName: verticalName });
    }
    setReportPdfData(response)

    try {
      if (response.status == 200) {
        const sas = await getSasToken("r")
        await downloadPdf(hasClassName, response.data, sas)
        setButtonVisible(true);
        setIsLoading(false);

      } else {
        setIsLoading(false);
        let toastData = {
          toastType: "error",
          toastHeaderMessage: "Error",
          toastBodyMessage: "Api Failed",
        };
        setToastProp(toastData);
        setHideToast(false);
        setButtonVisible(true);
      }
    } catch (error) {
      setIsLoading(false);
      let toastData = {
        toastType: "error",
        toastHeaderMessage: "Error",
        toastBodyMessage: "Api Failed",
      };
      setToastProp(toastData);
      setHideToast(false);
      setButtonVisible(true);
    }
  };


  const downloadPdf = async (hasClassName:boolean, reportPdfData: any, sas: any) => {
    let blobUrl: any

    const verticalName = reportData?.data?.verticalName || reportData.verticalName
    const a = document.createElement('a');
    blobUrl = `${reportPdfData.downloadLink}?${sas.data}`
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    a.href = url;
    if(hasClassName){
    a.download = `${organizationName}_${reportData.verticalName == "Application Architecture" ||
      reportData.verticalName == "Mobility"
      ? reportData.projectName
      : ""
      }_${verticalName}.${toDownload==="pdf"? "pdf":"docx"}`;
    a.click();
    }
    else{

    const decodedData = atob(reportPdfData.pdfBuffer);

    const uint8Array = new Uint8Array(decodedData.length);


    for (let i = 0; i < decodedData.length; i++) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    const blob1 = new Blob([uint8Array], { type: "application/pdf" });
    blobUrl = URL.createObjectURL(blob1);
    a.href = blobUrl;
    a.download = `${organizationName}_${reportData.verticalName == "Application Architecture" ||
      reportData.verticalName == "Mobility"
      ? reportData.projectName
      : ""
      }_${verticalName}.pdf`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
    }
  }

  useEffect(() => {
    if (triggerFunctions) {
      saveHtmlAsFile();
      // window.print();
      // setButtonVisible(true);
      setTriggerFunctions(false);
    }
  }, [triggerFunctions]);

  /**PS_AG_15
   * This function is used to show and hide the toast message
   * hideToast state variable is passed as the dependency
   */
  useEffect(() => {
    setTimeout(() => {
      setHideToast(true);
    }, 2000);
  }, [hideToast]);

  useEffect(() => {
    getOrgLogo();
  }, []);

  const handleButtonClick = async () => {
    setButtonVisible(false);
    setTriggerFunctions(true);
    setIsLoading(true);
  };

  const TimeFormatter = ({ inputTime, outputFormat }: any) => {
    const parsedTime = moment(inputTime);
    const formattedTime = parsedTime.format(outputFormat);

    return formattedTime;
  };

  function formatCurrentDate() {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    return `${month} ${day}, ${year}`;
  }
  const inputTime = reportTime;

  const outputFormat = "MMMM DD, YYYY";
  const dateforReport = TimeFormatter({ inputTime, outputFormat });

  const outputFormatForTime = "hh:mm A";
  const timeForReport = TimeFormatter({ inputTime, outputFormatForTime });

  function bindControlArea() {
    return reportData?.controlAreas.map(
      (item: { controlAreaName: string }, index: number) => (
        <li
          key={index}
          style={{
            margin: "24px 0px",
            fontFamily: "SFProText-Medium",
            color: "#000000",
            fontWeight: 400,
            fontSize: 15,
          }}
        >
          {item.controlAreaName}
        </li>
      )
    );
  }

  /**
                         <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                        }}
                      >
                        Time |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {timeForReport}
                        </span>
                      </span>
   */

  async function getOrgLogo() {
    let organizationId1, organizationLogoUrl, organizationName, modifiedAt
    if (reportData?.data?.verticalAssessmentTypeName == "MAP Assessment") {
      organizationId1 = { organizationId: reportData.data.organizationId };
      organizationLogoUrl = reportData?.data?.organizationLogo
      organizationName = reportData?.data.organizationName
      modifiedAt = reportData?.data?.modifiedAt
    }
    else {
      organizationId1 = { organizationId: reportData?.organizationId || reportData?.data?.organizationId };
      // organizationLogoUrl = reportDataresponse.data[0].organizationLogo'
      organizationId1 = { organizationId: reportData?.organizationId || reportData?.data?.organizationId };
    }

    let response: any = await getOrganization(organizationId1);
    let value = encryptStorage.getItem("jwt")
    let parsedJtk: any = ""
    let storageAccountName: any, containerName: any;
    if (value) {
      //PS-133 Retrieve JWT from browser storage for authorization purposes.    
      parsedJtk = jwtDecode(value)
      storageAccountName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME! });
      containerName = getConnectorsData({ key: parsedJtk.ek, encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER! })
    }
    const sas = await getSasToken("r");
    setLogoUrl(response.data[0].organizationLogo + `?${sas.data}`);
    setAwsLogo(`https://${storageAccountName}.blob.core.windows.net/${containerName}//aws-large-logo.${reportData?.data?.verticalName === "WAFR" ? "png" : "svg"}?${sas.data}`)
    setFrontPageLogo(`https://${storageAccountName}.blob.core.windows.net/${containerName}//pdf-bg-vector.svg?${sas.data}`)
    setZebLogo(`https://${storageAccountName}.blob.core.windows.net/${containerName}//zeb-logo.svg?${sas.data}`)
    setAvasoftLogo(`https://${storageAccountName}.blob.core.windows.net/${containerName}/avasoft-logo.svg?${sas.data}`)
    setOrganizationName(response.data[0].organizationName);
    setReportTime(response.data[0].modifiedAt);
  }

  const bindDatabricks = () => {
    switch (reportData?.data?.verticalName) {
      case "Databricks":
        return (
          <>
            <div ref={contentRef}>
              <table style={{ width: '100%' }} className ="new-page">
              <tbody>
                    <tr>
                      <td>
                        {/*Reports page starts here */}
                        <div style={{ width: '98%', height: '1100px', float: 'left', padding: '2% 2% 3% 0', pageBreakInside: 'avoid', position: 'relative' }}>
                          <img src={frontPageLogo} alt="background-vector" style={{ width: '100%', height: '80vh', position: 'absolute', zIndex: 1, bottom: 20, right: 0 }} />
                          <div style={{ width: '99%', height: '1100px', float: 'left', margin: '1% 0%', padding: '3%', backgroundColor: '#F3F3F3', position: 'relative' }}>
                            {/* Main header style */}
                            <img src={logoUrl} width="80px" height="80px" alt="prenuvo-logo" />
                            <div style={{ width: '40%', position: 'absolute', bottom: '30%', right: '10%', textAlign: 'center' }}>
                              <img src={awsLogo} alt="aws-logo" style={{ marginBottom: '10%' }} />
                              <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '20px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                              Databricks Assessment Review</p>
                            </div>
                          </div>
                          <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '14px', marginTop: '2%', float: 'left' }}>Date | {formatCurrentDate()}</p>
                          <img src={zebLogo} alt="zeb-logo" style={{ float: 'right', marginTop: '2%' }} />
                        </div>
                        {/*Reports page ends here */}
                      </td>
                    </tr>
                  </tbody>
              </table>
              <DatabricksDownloadReport 
                props={reportData.data} 
                selectedOption={reportData.selectedOption}
                selectedCategory={reportData.selectedCategory}
              />
            </div>

          </>)
    }
  }

  
  const bindAwsMdpw = () => {
    switch (reportData?.data?.verticalName) {
      case "MDPW":
        return (

          <>
            <div ref={contentRef}>
              <table style={{ width: '100%' }} className="new-page">
                <tbody>
                  <tr>
                    <td>
                      {/*Reports page starts here */}
                      <div style={{ width: '100%', height: '1150px', float: 'left', padding: ' 0', pageBreakInside: 'avoid', position: 'relative', marginLeft: "-10px", marginTop: "-20px" }}>
                        <img src={frontPageLogo} alt="background-vector" style={{ width: '100%', height: '80vh', position: 'absolute', zIndex: 1, bottom: 18, right: 0 }} />
                        <div style={{ width: '99%', height: '1100px', float: 'left', margin: '1% 0%', padding: '3%', backgroundColor: '#F3F3F3', position: 'relative' }}>
                          {/* Main header style */}
                          <img src={logoUrl} width="80px" height="80px" alt="prenuvo-logo" />
                          <div style={{ width: '40%', position: 'absolute', bottom: '30%', right: '10%', textAlign: 'center' }}>
                            {/* <img src={awsLogo} alt="aws-logo" style={{ marginBottom: '10%' }} /> */}
                            <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '30px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                              Modern Data Platform</p>
                            <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '30px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                              Workshop</p>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: '94%', float: 'left', margin: '1% 3%', pageBreakInside: "avoid", marginTop: "5%" }}>
                        <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '18px', marginTop: '2%', float: 'left' }}>Date | {formatCurrentDate()}</p>
                        <img src={zebLogo} alt="zeb-logo" style={{ float: 'right', marginTop: '2%' }} />
                      </div>
                      {/*Reports page ends here */}
                    </td>
                  </tr>
                </tbody>
                {/* <tfoot>
                  <tr>
                    <td style={{ paddingTop: '80px' }}>
                    </td>
                  </tr>
                </tfoot> */}
              </table>
              <AwsMdpwDowloadReport props={reportData.data} />
            </div>

          </>

        )
    }
  }

  const bindVMware = () => {
    switch (reportData?.data?.verticalName) {
      case "VMware":
        return (
          <>
            <div ref={contentRef}>
              <table style={{ width: '100%' }} className="new-page">
                <tbody>
                  <tr>
                    <td>
                      {/*Reports page starts here */}
                      <div style={{ width: '98%', height: '1100px', float: 'left', padding: '2% 2% 3% 0', pageBreakInside: 'avoid', position: 'relative' }}>
                        <img src={frontPageLogo} alt="background-vector" style={{ width: '100%', height: '80vh', position: 'absolute', zIndex: 1, bottom: 20, right: 0 }} />
                        <div style={{ width: '99%', height: '1100px', float: 'left', margin: '1% 0%', padding: '3%', backgroundColor: '#F3F3F3', position: 'relative' }}>
                          {/* Main header style */}
                          <img src={logoUrl} width="80px" height="80px" alt="prenuvo-logo" />
                          <div style={{ width: '40%', position: 'absolute', bottom: '30%', right: '10%', textAlign: 'center' }}>
                            <img src={awsLogo} alt="aws-logo" style={{ marginBottom: '10%' }} />
                            <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '20px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                              VMware Assessment Review</p>
                          </div>
                        </div>
                        <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '14px', marginTop: '2%', float: 'left' }}>Date | {formatCurrentDate()}</p>
                        <img src={zebLogo} alt="zeb-logo" style={{ float: 'right', marginTop: '2%' }} />
                      </div>
                      {/*Reports page ends here */}
                    </td>
                  </tr>
                </tbody>
              </table>
              <VMwareDownloadReport props={reportData.data} />
            </div>

          </>)
    }
  }
  const bindInfrastructureReport = () => {
    switch (reportData?.data?.verticalName) {
      case "MAP":
        return (
          <>
            <div ref={contentRef}>
              <table style={{ width: '100%' }} className="new-page">
                <tbody>
                  <tr>
                    <td>
                      {/*Reports page starts here */}
                      <div style={{ width: '98%', height: '1100px', float: 'left', padding: '2% 2% 3% 0', pageBreakInside: 'avoid', position: 'relative' }}>
                        <img src={frontPageLogo} alt="background-vector" style={{ width: '100%', height: '80vh', position: 'absolute', zIndex: 1, bottom: 20, right: 0 }} />
                        <div style={{ width: '99%', height: '1100px', float: 'left', margin: '1% 0%', padding: '3%', backgroundColor: '#F3F3F3', position: 'relative' }}>
                          {/* Main header style */}
                          <img src={logoUrl} width="80px" height="80px" alt="prenuvo-logo" />
                          <div style={{ width: '40%', position: 'absolute', bottom: '30%', right: '10%', textAlign: 'center' }}>
                            <img src={awsLogo} alt="aws-logo" style={{ marginBottom: '10%' }} />
                            <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '20px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                              MAP Assessment Review</p>
                          </div>
                        </div>
                        <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '14px', marginTop: '2%', float: 'left' }}>Date | {formatCurrentDate()}</p>
                        <img src={zebLogo} alt="zeb-logo" style={{ float: 'right', marginTop: '2%' }} />
                      </div>
                      {/*Reports page ends here */}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td style={{ paddingTop: '80px' }}>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <InfrastructureMapDownloadReport reportData={location?.state?.data} />
            </div>

          </>)

      default:
        return (<div ref={contentRef}>
          <div style={{ WebkitPrintColorAdjust: "exact" }}>
            <div style={{ width: "100%", backgroundColor: "white" }}>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  height: "1420px",
                  marginTop: "2%",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "50vh",
                    textAlign: "center",
                    marginTop: "600px",
                  }}
                >
                  <img
                    src={logoUrl}
                    alt="aven-logo"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <h5
                  style={{
                    color: "#050505",
                    fontSize: 28,
                    fontWeight: 600,
                    textAlign: "center",
                    wordSpacing: 8,
                    fontFamily: "SFProText-Medium",
                    paddingTop: "75px",
                  }}
                >
                  {organizationName}
                </h5>
              </div>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <span
                    style={{
                      color: "#000000",
                      fontSize: 16,
                      fontWeight: 600,
                      fontFamily: "SFProText-Medium",
                      marginRight: 16,
                    }}
                  >
                    Date |
                    <span
                      style={{
                        color: "#4B4A4A",
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      {" "}
                      {dateforReport}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              float: "left",
              marginTop: 40,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          ></div>
          <div style={{ WebkitPrintColorAdjust: "exact" }}>
            <div style={{ width: "100%", backgroundColor: "white" }}>
              {/*Header starts here */}
              {/*Header  ends here */}
              <div
                style={{
                  width: "100%",
                  float: "left",
                  padding: "60px 0px 110px 0px",
                  marginBottom: "70%",
                }}
              >
                <div
                  style={{
                    width: "98%",
                    justifyContent: "center",
                    padding: "2% 1%",
                    backgroundColor: "#F5F5F5",
                  }}
                >
                  <h5
                    style={{
                      color: "#050505",
                      fontFamily: "SFProText-Medium",
                      fontSize: 22,
                      fontWeight: 600,
                      margin: 0,
                      textAlign: "center",
                      wordSpacing: 8,
                    }}
                  >
                    Reports
                  </h5>
                </div>
                <div style={{ margin: "16px 0px" }}>
                  <h1
                    style={{
                      fontFamily: "SFProText-Medium",
                      color: "#000000",
                      fontWeight: 400,
                      fontSize: 15,
                      margin: 0,
                    }}
                  >
                    Overall Summary
                  </h1>
                </div>
                <h1
                  style={{
                    fontSize: 16,
                    color: "#151515",
                    fontFamily: "SFProText-Medium",
                    margin: "24px 0px",
                  }}
                  className="underlineCA"
                >
                  Control Areas
                </h1>
                <ul
                  style={{
                    listStyle: "none",
                    marginBottom: 0,
                    paddingLeft: 0,
                  }}
                >
                  {bindControlArea()}
                </ul>
              </div>
              {/* index ends here */}
            </div>
          </div>

          <div
            style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
          >
            <div style={{ WebkitPrintColorAdjust: "exact" }}>
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {/*Header starts here */}
                        <div
                          style={{
                            pageBreakInside: "avoid",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "98%",
                              justifyContent: "center",
                              padding: "2% 1%",
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <h5
                              style={{
                                color: "#050505",
                                fontFamily: "SFProText-Medium",
                                fontSize: 22,
                                fontWeight: 600,
                                margin: 0,
                                textAlign: "center",
                                wordSpacing: 8,
                              }}
                            >
                              Overall Summary
                            </h5>
                          </div>
                          {/*Header  ends here */}
                          {/* about score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "3% 0%",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                // marginTop: 20
                              }}
                            >
                              Know about your score
                            </h2>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#F0FAEF",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Overall Proficiency Index
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.overallProficiencyIndex}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#FFF3E9",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Peer Score
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.peerScore}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#EDFAFE",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Control Areas
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.controlAreaNames.length}`}
                              </p>
                            </div>
                          </div>
                          {/* about score ends here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "1% 0%",
                            }}
                          >
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingRight: "3%",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                }}
                              >
                                Control areas that influenced your results
                              </h2>
                              <ReportHorizontalBarChart
                                dataNameValue={
                                  reportData.data
                                    .controlAreaProficiencyIndex
                                }
                              ></ReportHorizontalBarChart>
                            </div>
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingLeft: "3%",
                                textAlign: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                  textAlign: "left",
                                }}
                              >
                                Recommendations based on Impacts
                              </h2>
                              <ReportDonutChart
                                dataNameValue2={reportData.overallDonuchart}
                              ></ReportDonutChart>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FFA79D",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    High Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {reportData.impact.highImpactCount}
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingLeft: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FCD269",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Medium Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {reportData.impact.mediumImpactCount}
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "3%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#9FF5A2",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Low Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {reportData.impact.lowImpactCount}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Code Metrics and Analysis starts here*/}
                          {/* Code Metrics and Analysis ends here*/}
                          {/* Based on your score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "0% 0%",
                              pageBreakInside: "avoid",
                              overflow: "auto", // Adding overflow to handle content overflow
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                marginTop: 25,
                              }}
                            >
                              Based on your Score
                            </h2>
                            <div style={{ width: "98%" }}>
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "2%",
                                  textAlign: "center",
                                  margin: "0% 2% 2% 0%",
                                }}
                              >
                                <ReportGaugeChart
                                  dataValue={reportData.GaugeValue}
                                  Gaugeval={reportData.overallGaugeChart}
                                ></ReportGaugeChart>
                              </div>
                              <div>
                                {" "}
                                {/* Adjusted marginTop */}
                                <ul
                                  style={{ listStyle: "none", padding: 0 }}
                                >
                                  <li
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                      marginBottom: 20,
                                      marginTop: 20, // Adjusted marginTop here too
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {
                                      reportData.data.overallRecommendations
                                        .description
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* Based on your score ends here*/}
                          {/* Recommendation table starts here */}
                          <ThreeReportTableComponent
                            dataForGrid={reportData.overallRecommendation}
                            areas={false}
                            overall={true}
                          />
                          {/* Recommendation table ends here */}
                          {/* Recommendation table starts here */}
                          {/* Recommendation table ends here */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
                {/* footer style ends here */}
              </div>
            </div>
            {/** security  start*/}
            <div style={{ WebkitPrintColorAdjust: "exact" }}>
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {/*Header starts here */}
                        <div
                          style={{
                            pageBreakInside: "avoid",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "98%",
                              justifyContent: "center",
                              padding: "2% 1%",
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <h5
                              style={{
                                color: "#050505",
                                fontFamily: "SFProText-Medium",
                                fontSize: 22,
                                fontWeight: 600,
                                margin: 0,
                                textAlign: "center",
                                wordSpacing: 8,
                              }}
                            >
                              Security
                            </h5>
                          </div>
                          {/*Header  ends here */}
                          {/* about score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "3% 0%",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                // marginTop: 20
                              }}
                            >
                              Know about your score
                            </h2>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#F0FAEF",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Overall Proficiency Index
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.overallProficiencyIndex}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#FFF3E9",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Peer Score
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.peerScore}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#EDFAFE",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Security
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                              </p>
                            </div>
                          </div>
                          {/* about score ends here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "1% 0%",
                            }}
                          >
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingRight: "3%",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                }}
                              >
                                Control areas that influenced your results
                              </h2>
                              <ReportHorizontalBarChart
                                dataNameValue={
                                  reportData?.data?.controlAreaMenuReport
                                    ?.security?.controlAreaBarChart
                                }
                              ></ReportHorizontalBarChart>
                            </div>
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingLeft: "3%",
                                textAlign: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                  textAlign: "left",
                                }}
                              >
                                Recommendations based on Impacts
                              </h2>
                              <ReportDonutChart
                                dataNameValue2={
                                  reportData.securityDonutChart
                                }
                              ></ReportDonutChart>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FFA79D",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    High Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {reportData.securityDonutChart[0].value}
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingLeft: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FCD269",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Medium Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {reportData.securityDonutChart[1].value}
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "3%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#9FF5A2",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Low Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {reportData.securityDonutChart[2].value}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Code Metrics and Analysis starts here*/}
                          {/* Code Metrics and Analysis ends here*/}
                          {/* Based on your score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "0% 0%",
                              pageBreakInside: "avoid",
                              overflow: "auto", // Adding overflow to handle content overflow
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                marginTop: 25,
                              }}
                            >
                              Based on your Score
                            </h2>
                            <div style={{ width: "98%" }}>
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "2%",
                                  textAlign: "center",
                                  margin: "0% 2% 2% 0%",
                                }}
                              >
                                <ReportGaugeChart
                                  dataValue={reportData.GaugeValue}
                                  Gaugeval={
                                    reportData.data
                                      .controlAreaProficiencyIndex[0].value
                                  }
                                ></ReportGaugeChart>
                              </div>
                              <div>
                                {" "}
                                {/* Adjusted marginTop */}
                                <ul
                                  style={{ listStyle: "none", padding: 0 }}
                                >
                                  <li
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                      marginBottom: 20,
                                      marginTop: 20, // Adjusted marginTop here too
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {
                                      reportData.data.controlAreaMenuReport
                                        ?.security?.overallRecommendations
                                        ?.description
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* Based on your score ends here*/}
                          {/* Recommendation table starts here */}
                          <ThreeReportTableComponent
                            dataForGrid={reportData?.securityOverall}
                            areas={false}
                          />
                          {/* Recommendation table ends here */}
                          {/* Recommendation table starts here */}
                          {/* Recommendation table ends here */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
                {/* footer style ends here */}
              </div>
            </div>
            {/** Services Architecture end*/}
            {/**  cost optimi start */}
            <div style={{ WebkitPrintColorAdjust: "exact" }}>
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {/*Header starts here */}
                        <div
                          style={{
                            pageBreakInside: "avoid",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "98%",
                              justifyContent: "center",
                              padding: "2% 1%",
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <h5
                              style={{
                                color: "#050505",
                                fontFamily: "SFProText-Medium",
                                fontSize: 22,
                                fontWeight: 600,
                                margin: 0,
                                textAlign: "center",
                                wordSpacing: 8,
                              }}
                            >
                              Cost Optimization
                            </h5>
                          </div>
                          {/*Header  ends here */}
                          {/* about score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "3% 0%",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                // marginTop: 20
                              }}
                            >
                              Know about your score
                            </h2>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#F0FAEF",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Overall Proficiency Index
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.overallProficiencyIndex}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#FFF3E9",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Peer Score
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.peerScore}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#EDFAFE",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Cost Optimization
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                              </p>
                            </div>
                          </div>
                          {/* about score ends here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "1% 0%",
                            }}
                          >
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingRight: "3%",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                }}
                              >
                                Control areas that influenced your results
                              </h2>
                              <ReportHorizontalBarChart
                                dataNameValue={
                                  reportData.data.controlAreaMenuReport
                                    .costOptimization.controlAreaBarChart
                                }
                              ></ReportHorizontalBarChart>
                            </div>
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingLeft: "3%",
                                textAlign: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                  textAlign: "left",
                                }}
                              >
                                Recommendations based on Impacts
                              </h2>
                              <ReportDonutChart
                                dataNameValue2={
                                  reportData.costOptimizationDonutChart
                                }
                              ></ReportDonutChart>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FFA79D",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    High Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .costOptimizationDonutChart[0].value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingLeft: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FCD269",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Medium Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .costOptimizationDonutChart[1].value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "3%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#9FF5A2",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Low Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .costOptimizationDonutChart[2].value
                                    }
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Code Metrics and Analysis starts here*/}
                          {/* Code Metrics and Analysis ends here*/}
                          {/* Based on your score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "0% 0%",
                              pageBreakInside: "avoid",
                              overflow: "auto", // Adding overflow to handle content overflow
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                marginTop: 25,
                              }}
                            >
                              Based on your Score
                            </h2>
                            <div style={{ width: "98%" }}>
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "2%",
                                  textAlign: "center",
                                  margin: "0% 2% 2% 0%",
                                }}
                              >
                                <ReportGaugeChart
                                  dataValue={reportData.GaugeValue}
                                  Gaugeval={
                                    reportData.data
                                      .controlAreaProficiencyIndex[1].value
                                  }
                                ></ReportGaugeChart>
                              </div>
                              <div>
                                {" "}
                                {/* Adjusted marginTop */}
                                <ul
                                  style={{ listStyle: "none", padding: 0 }}
                                >
                                  <li
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                      marginBottom: 20,
                                      marginTop: 20, // Adjusted marginTop here too
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {
                                      reportData.data.controlAreaMenuReport
                                        ?.costOptimization
                                        ?.overallRecommendations
                                        ?.description
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* Based on your score ends here*/}
                          {/* Recommendation table starts here */}
                          <ThreeReportTableComponent
                            dataForGrid={
                              reportData?.costOptimizationOverall
                            }
                            areas={false}
                          />
                          {/* Recommendation table ends here */}
                          {/* Recommendation table starts here */}
                          {/* Recommendation table ends here */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
                {/* footer style ends here */}
              </div>
            </div>
            {/**Event Based Architecture start */}
            {/**reliab start */}
            <div style={{ WebkitPrintColorAdjust: "exact" }}>
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {/*Header starts here */}
                        <div
                          style={{
                            pageBreakInside: "avoid",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "98%",
                              justifyContent: "center",
                              padding: "2% 1%",
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <h5
                              style={{
                                color: "#050505",
                                fontFamily: "SFProText-Medium",
                                fontSize: 22,
                                fontWeight: 600,
                                margin: 0,
                                textAlign: "center",
                                wordSpacing: 8,
                              }}
                            >
                              Reliability
                            </h5>
                          </div>
                          {/*Header  ends here */}
                          {/* about score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "3% 0%",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                // marginTop: 20
                              }}
                            >
                              Know about your score
                            </h2>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#F0FAEF",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Overall Proficiency Index
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.overallProficiencyIndex}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#FFF3E9",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Peer Score
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.peerScore}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#EDFAFE",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Reliability
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                              </p>
                            </div>
                          </div>
                          {/* about score ends here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "1% 0%",
                            }}
                          >
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingRight: "3%",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                }}
                              >
                                Control areas that influenced your results
                              </h2>
                              <ReportHorizontalBarChart
                                dataNameValue={
                                  reportData.data.controlAreaMenuReport
                                    .reliability.controlAreaBarChart
                                }
                              ></ReportHorizontalBarChart>
                            </div>
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingLeft: "3%",
                                textAlign: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                  textAlign: "left",
                                }}
                              >
                                Recommendations based on Impacts
                              </h2>
                              <ReportDonutChart
                                dataNameValue2={
                                  reportData.reliabilityDonutChart
                                }
                              ></ReportDonutChart>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FFA79D",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    High Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData.reliabilityDonutChart[0]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingLeft: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FCD269",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Medium Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData.reliabilityDonutChart[1]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "3%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#9FF5A2",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Low Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData.reliabilityDonutChart[2]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Code Metrics and Analysis starts here*/}
                          {/* Code Metrics and Analysis ends here*/}
                          {/* Based on your score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "0% 0%",
                              pageBreakInside: "avoid",
                              overflow: "auto", // Adding overflow to handle content overflow
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                marginTop: 25,
                              }}
                            >
                              Based on your Score
                            </h2>
                            <div style={{ width: "98%" }}>
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "2%",
                                  textAlign: "center",
                                  margin: "0% 2% 2% 0%",
                                }}
                              >
                                <ReportGaugeChart
                                  dataValue={reportData.GaugeValue}
                                  Gaugeval={
                                    reportData.data
                                      .controlAreaProficiencyIndex[2].value
                                  }
                                ></ReportGaugeChart>
                              </div>
                              <div>
                                {" "}
                                {/* Adjusted marginTop */}
                                <ul
                                  style={{ listStyle: "none", padding: 0 }}
                                >
                                  <li
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                      marginBottom: 20,
                                      marginTop: 20, // Adjusted marginTop here too
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {
                                      reportData.data.controlAreaMenuReport
                                        ?.reliability
                                        ?.overallRecommendations
                                        ?.description
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* Based on your score ends here*/}
                          {/* Recommendation table starts here */}
                          <ThreeReportTableComponent
                            dataForGrid={reportData?.reliabilityOverall}
                            areas={false}
                          />
                          {/* Recommendation table ends here */}
                          {/* Recommendation table starts here */}
                          {/* Recommendation table ends here */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
                {/* footer style ends here */}
              </div>
            </div>
            {/**logging end */}
            {/** opreational excellence start */}
            <div style={{ WebkitPrintColorAdjust: "exact" }}>
              <div style={{ width: "100%" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {/*Header starts here */}
                        <div
                          style={{
                            pageBreakInside: "avoid",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "98%",
                              justifyContent: "center",
                              padding: "2% 1%",
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <h5
                              style={{
                                color: "#050505",
                                fontFamily: "SFProText-Medium",
                                fontSize: 22,
                                fontWeight: 600,
                                margin: 0,
                                textAlign: "center",
                                wordSpacing: 8,
                              }}
                            >
                              Operational Excellence
                            </h5>
                          </div>
                          {/*Header  ends here */}
                          {/* about score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "3% 0%",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                // marginTop: 20
                              }}
                            >
                              Know about your score
                            </h2>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#F0FAEF",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Overall Proficiency Index
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.overallProficiencyIndex}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#FFF3E9",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Peer Score
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.peerScore}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#EDFAFE",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Operational Excellence
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                              </p>
                            </div>
                          </div>
                          {/* about score ends here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "1% 0%",
                            }}
                          >
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingRight: "3%",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                }}
                              >
                                Control areas that influenced your results
                              </h2>
                              <ReportHorizontalBarChart
                                dataNameValue={
                                  reportData.data?.controlAreaMenuReport
                                    ?.operationalExcellence
                                    ?.controlAreaBarChart
                                }
                              ></ReportHorizontalBarChart>
                            </div>
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingLeft: "3%",
                                textAlign: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                  textAlign: "left",
                                }}
                              >
                                Recommendations based on Impacts
                              </h2>
                              <ReportDonutChart
                                dataNameValue2={
                                  reportData.operationalExcellenceDonutChart
                                }
                              ></ReportDonutChart>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FFA79D",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    High Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .operationalExcellenceDonutChart[0]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingLeft: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FCD269",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Medium Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .operationalExcellenceDonutChart[1]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "3%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#9FF5A2",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Low Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .operationalExcellenceDonutChart[2]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Code Metrics and Analysis starts here*/}
                          {/* Code Metrics and Analysis ends here*/}
                          {/* Based on your score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "0% 0%",
                              pageBreakInside: "avoid",
                              overflow: "auto", // Adding overflow to handle content overflow
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                marginTop: 25,
                              }}
                            >
                              Based on your Score
                            </h2>
                            <div style={{ width: "98%" }}>
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "2%",
                                  textAlign: "center",
                                  margin: "0% 2% 2% 0%",
                                }}
                              >
                                <ReportGaugeChart
                                  dataValue={reportData.GaugeValue}
                                  Gaugeval={
                                    reportData.data
                                      .controlAreaProficiencyIndex[3].value
                                  }
                                ></ReportGaugeChart>
                              </div>
                              <div>
                                {" "}
                                {/* Adjusted marginTop */}
                                <ul
                                  style={{ listStyle: "none", padding: 0 }}
                                >
                                  <li
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                      marginBottom: 20,
                                      marginTop: 20, // Adjusted marginTop here too
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {
                                      reportData.data.controlAreaMenuReport
                                        ?.operationalExcellence
                                        ?.overallRecommendations
                                        ?.description
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* Based on your score ends here*/}
                          {/* Recommendation table starts here */}
                          <ThreeReportTableComponent
                            dataForGrid={
                              reportData?.operationalExcellenceOverall
                            }
                            areas={false}
                          />
                          {/* Recommendation table ends here */}
                          {/* Recommendation table starts here */}
                          {/* Recommendation table ends here */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
                {/* footer style ends here */}
              </div>
            </div>
            {/** Modularity end */}

            {/**performance start */}
            <div style={{ WebkitPrintColorAdjust: "exact" }}>
              <div style={{ width: "100%", marginTop: "50%" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {/*Header starts here */}
                        <div
                          style={{
                            pageBreakInside: "avoid",
                            width: "100%",
                            float: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "98%",
                              justifyContent: "center",
                              padding: "2% 1%",
                              backgroundColor: "#F5F5F5",
                            }}
                          >
                            <h5
                              style={{
                                color: "#050505",
                                fontFamily: "SFProText-Medium",
                                fontSize: 22,
                                fontWeight: 600,
                                margin: 0,
                                textAlign: "center",
                                wordSpacing: 8,
                              }}
                            >
                              Performance Efficiency
                            </h5>
                          </div>
                          {/*Header  ends here */}
                          {/* about score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "3% 0%",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                // marginTop: 20
                              }}
                            >
                              Know about your score
                            </h2>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#F0FAEF",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Overall Proficiency Index
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.overallProficiencyIndex}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#FFF3E9",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Peer Score
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.peerScore}%`}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "30.3%",
                                marginRight: "1%",
                                padding: "2% 1%",
                                float: "left",
                                backgroundColor: "#EDFAFE",
                                borderRadius: 3,
                              }}
                            >
                              <h5
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 15,
                                  margin: "2% 0%",
                                  fontWeight: 400,
                                  float: "left",
                                }}
                              >
                                Performance Efficiency
                              </h5>
                              <p
                                style={{
                                  color: "#000000",
                                  fontFamily: "SFProText-Medium",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  margin: "2% 0%",
                                  float: "right",
                                }}
                              >
                                {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                              </p>
                            </div>
                          </div>
                          {/* about score ends here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "1% 0%",
                            }}
                          >
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingRight: "3%",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                }}
                              >
                                Control areas that influenced your results
                              </h2>
                              <ReportHorizontalBarChart
                                dataNameValue={
                                  reportData.data.controlAreaMenuReport
                                    .performanceEfficiency
                                    .controlAreaBarChart
                                }
                              ></ReportHorizontalBarChart>
                            </div>
                            <div
                              style={{
                                width: "47%",
                                float: "left",
                                marginTop: "2%",
                                paddingLeft: "3%",
                                textAlign: "center",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "SFProText-Medium",
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 17,
                                  marginBottom: 16,
                                  textAlign: "left",
                                }}
                              >
                                Recommendations based on Impacts
                              </h2>
                              <ReportDonutChart
                                dataNameValue2={
                                  reportData.performanceEfficiencyDonutChart
                                }
                              ></ReportDonutChart>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FFA79D",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    High Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .performanceEfficiencyDonutChart[0]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingLeft: "2%",
                                  marginTop: "2%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#FCD269",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Medium Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .performanceEfficiencyDonutChart[1]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "48%",
                                  float: "left",
                                  paddingRight: "2%",
                                  marginTop: "3%",
                                }}
                              >
                                <div
                                  style={{
                                    float: "left",
                                    width: "70%",
                                    textAlign: "left",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: 16,
                                      height: 16,
                                      backgroundColor: "#9FF5A2",
                                      borderRadius: 16,
                                      marginRight: 12,
                                    }}
                                  />
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                    }}
                                  >
                                    Low Impact
                                  </label>
                                </div>
                                <div
                                  style={{ float: "left", width: "30%" }}
                                >
                                  <label
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      float: "right",
                                    }}
                                  >
                                    {
                                      reportData
                                        .performanceEfficiencyDonutChart[2]
                                        .value
                                    }
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Code Metrics and Analysis starts here*/}
                          {/* Code Metrics and Analysis ends here*/}
                          {/* Based on your score starts here*/}
                          <div
                            style={{
                              width: "100%",
                              float: "left",
                              margin: "0% 0%",
                              pageBreakInside: "avoid",
                              overflow: "auto", // Adding overflow to handle content overflow
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "SFProText-Medium",
                                color: "#000000",
                                fontWeight: 500,
                                fontSize: 17,
                                marginBottom: 16,
                                marginTop: 25,
                              }}
                            >
                              Based on your Score
                            </h2>
                            <div style={{ width: "98%" }}>
                              <div
                                style={{
                                  float: "left",
                                  marginTop: "2%",
                                  textAlign: "center",
                                  margin: "0% 2% 2% 0%",
                                }}
                              >
                                <ReportGaugeChart
                                  dataValue={reportData.GaugeValue}
                                  Gaugeval={
                                    reportData.data
                                      .controlAreaProficiencyIndex[4].value
                                  }
                                ></ReportGaugeChart>
                              </div>
                              <div>
                                {" "}
                                {/* Adjusted marginTop */}
                                <ul
                                  style={{ listStyle: "none", padding: 0 }}
                                >
                                  <li
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 14,
                                      color: "#000000",
                                      marginBottom: 20,
                                      marginTop: 20, // Adjusted marginTop here too
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    {
                                      reportData.data.controlAreaMenuReport
                                        ?.performanceEfficiency
                                        ?.overallRecommendations
                                        ?.description
                                    }
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/* Based on your score ends here*/}
                          {/* Recommendation table starts here */}
                          <ThreeReportTableComponent
                            dataForGrid={
                              reportData?.performanceEfficiencyOverall
                            }
                            areas={false}
                          />
                          <div style={{ marginTop: "230%" }}>
                            <ReportTableComponent
                              dataForGrid={reportData.overallRecommendation}
                              areas={false}
                            ></ReportTableComponent>
                          </div>
                          {/* Recommendation table ends here */}
                          {/* Recommendation table starts here */}
                          {/* Recommendation table ends here */}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* footer style starts here */}
                {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
<img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
<div style="text-align: center;">
<img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
</div>
</div> */}
                {/* footer style ends here */}
              </div>
            </div>
            {/**Integration end */}
          </div>
        </div>)
    }
  }

  return (
    <>

      <div style={{ WebkitPrintColorAdjust: "exact" }}>
        <div style={{ width: "100%", backgroundColor: "white" }}>
          <div style={{
            marginLeft: '1%',
            fontSize: '10px',
            padding: '1%',
            display: 'inline-block',
            marginTop: '4px',
            cursor: 'pointer'
          }}><img src="images/backarrow.svg" alt="back-arrow" title="Back" onClick={(event: any) => {
            navigate("/reports", { state: { backToReport: true, data: reportData.data } })
          }} /></div>
          {buttonVisible && (reportData?.data?.verticalName === "MDPW" || reportData?.data?.verticalName === "Databricks") && (
            <button
              type="button"
              style={{
                color: "#5D44FE",
                fontSize: "15px",
                fontFamily: "SFProText-Medium",
                fontWeight: 400,
                backgroundColor: "#fff",
                border: "0.75px solid #5D44FE",
                marginTop: "10px",
                float: "right",
                borderRadius: "3px",
                padding: "6px 10px",
                cursor: "pointer",
                marginRight: "24px",
              }}
              onClick={() => {
                setToDownload("word")
                handleButtonClick();
              }}
            >
              <span style={{ padding: "6px" }}>
                <img src="images/download-icon.svg" alt="download" />
              </span>
              Download Word{" "}
            </button>
          )}
          {buttonVisible && (
            <button
              type="button"
              style={{
                color: "#5D44FE",
                fontSize: "15px",
                fontFamily: "SFProText-Medium",
                fontWeight: 400,
                backgroundColor: "#fff",
                border: "0.75px solid #5D44FE",
                marginTop: "10px",
                float: "right",
                borderRadius: "3px",
                padding: "6px 10px",
                cursor: "pointer",
                marginRight: "24px",
              }}
              onClick={() => {
                setToDownload("pdf")
                handleButtonClick();
              }}
            >
              <span style={{ padding: "6px" }}>
                <img src="images/download-icon.svg" alt="download" />
              </span>
              Download PDF{" "}
            </button>
          )}

          {reportData?.data?.verticalName == "Application Architecture" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "0px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>
              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>

                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%", marginTop: "35%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Modularity
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Modularity
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData.data.controlAreaReport
                                          .modularity.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.modularityDonutchart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.modularityDonutchart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.modularityDonutchart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.modularityDonutchart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "5%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Code Metrics and Analysis
                                </h2>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.modularitySonarCloudChart
                                    }
                                  ></ReportDonutChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "98%",
                                      float: "left",
                                      marginTop: "4%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 14,
                                          height: 14,
                                          backgroundColor: "#A4E29E",
                                          borderRadius: 2,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Lines of Code
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {(reportData.data?.codeAnalysis?.size?.linesOfCode).toLocaleString()}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "98%",
                                      float: "left",
                                      marginTop: "4%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 14,
                                          height: 14,
                                          backgroundColor: "#86E5F2",
                                          borderRadius: 2,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Statements
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {(reportData.data?.codeAnalysis?.size?.statements).toLocaleString()}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "98%",
                                      float: "left",
                                      marginTop: "4%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 14,
                                          height: 14,
                                          backgroundColor: "#FAC383",
                                          borderRadius: 2,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Functions
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {(reportData.data?.codeAnalysis?.size?.functions).toLocaleString()}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "98%",
                                      float: "left",
                                      marginTop: "4%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 14,
                                          height: 14,
                                          backgroundColor: "#E3A1FB",
                                          borderRadius: 2,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Classes
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {(reportData.data?.codeAnalysis?.size?.classes).toLocaleString()}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "98%",
                                      float: "left",
                                      marginTop: "4%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 14,
                                          height: 14,
                                          backgroundColor: "#8BB8FB",
                                          borderRadius: 2,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Files
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {(reportData.data?.codeAnalysis?.size?.files).toLocaleString()}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "98%",
                                      float: "left",
                                      marginTop: "4%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 14,
                                          height: 14,
                                          backgroundColor: "#FF9090",
                                          borderRadius: 2,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Comment Lines
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {(reportData.data?.codeAnalysis?.size?.commentLines).toLocaleString()}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "5%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.modularity?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "16%",
                                  pageBreakInside: "avoid",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Based on the Code Analysis
                                </h2>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      marginTop: 16,
                                    }}
                                  >
                                    Issues
                                  </h4>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Issues
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.issues.issues.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Open Issues
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.issues.openIssues.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Confirmed Issues
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.issues.confirmedIssues.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      False Positive Issues
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.issues.falsePositiveIssues.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Won't Fix Issues
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.issues.wontFixIssues.toLocaleString()}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      marginTop: 16,
                                    }}
                                  >
                                    Complexity
                                  </h4>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Cyclomatic Complexity
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.complexity.cyclomaticComplexity.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Cognitive Complexity
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.complexity.cognitiveComplexity.toLocaleString()}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      marginTop: 16,
                                    }}
                                  >
                                    Size
                                  </h4>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Lines of Code
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.size.linesOfCode.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Statements
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.size.statements.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Functions
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.size.functions.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Classes
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.size.classes.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Files
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.size.files.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Comment Lines
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.size.commentLines.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Comments (%)
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {`${reportData.data.codeAnalysis.size.comments.toFixed(
                                        2
                                      )}%`}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      marginTop: 16,
                                    }}
                                  >
                                    Duplications
                                  </h4>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Density
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {`${reportData.data.codeAnalysis.duplications.density.toFixed(
                                        2
                                      )}%`}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Duplicated Lines
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.duplications.DuplicatedLines.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Duplicated Blocks
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {
                                        reportData.data.codeAnalysis
                                          .duplications.duplicatedBlocks
                                      }
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Duplicated Files
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {
                                        reportData.data.codeAnalysis
                                          .duplications.duplicatedFiles
                                      }{" "}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      margin: "16px 0px",
                                    }}
                                  >
                                    Maintainability
                                  </h4>
                                  {reportData.data?.codeAnalysis
                                    ?.maintainability?.rating == "A" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#F0FAEF",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your technical debt ratio.
                                          <span style={{ color: "#57C269" }}>
                                            ( =5% the rating is A)
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis
                                    ?.maintainability?.rating == "E" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fbefef",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your technical debt ratio.
                                          <span style={{ color: "#E05656" }}>
                                            ( Over 50% is an E )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis
                                    ?.maintainability?.rating == "C" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fffaee",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your technical debt ratio.
                                          <span style={{ color: "#FFD772" }}>
                                            ( 11 to 20% the rating is a C )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis
                                    ?.maintainability?.rating == "B" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#c4cdf5",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your technical debt ratio.
                                          <span style={{ color: "#7891FF" }}>
                                            ( 6 to 10% the rating is a B )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis
                                    ?.maintainability?.rating == "D" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#f8e7da",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your technical debt ratio.
                                          <span style={{ color: "#EF9C5F" }}>
                                            ( 21 to 50% the rating is a D )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Code Smells
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.maintainability.codeSmells.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Debt
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data.codeAnalysis.maintainability.debt.toLocaleString()}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Debt Ratio
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {`${reportData.data.codeAnalysis.maintainability.debtRatio.toFixed(
                                        2
                                      )}%`}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Rating
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#57C269",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <span
                                        style={
                                          reportData.data?.codeAnalysis
                                            ?.maintainability?.rating == "A"
                                            ? { color: "#57C269" }
                                            : reportData.data?.codeAnalysis
                                              ?.maintainability?.rating == "B"
                                              ? { color: "#7891FF" }
                                              : reportData.data?.codeAnalysis
                                                ?.maintainability?.rating == "C"
                                                ? { color: "#FFD772" }
                                                : reportData.data?.codeAnalysis
                                                  ?.maintainability?.rating == "D"
                                                  ? { color: "#EF9C5F" }
                                                  : reportData.data?.codeAnalysis
                                                    ?.maintainability?.rating == ""
                                                    ? { color: "black" }
                                                    : { color: "#E05656" }
                                        }
                                      >
                                        {reportData.data?.codeAnalysis
                                          ?.maintainability?.rating == ""
                                          ? "-"
                                          : reportData.data?.codeAnalysis
                                            ?.maintainability?.rating}
                                      </span>
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Effort to Reach A
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reportData.data?.codeAnalysis?.maintainability?.effortToReachA.toFixed(
                                        2
                                      )}{" "}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      margin: "16px 0px",
                                    }}
                                  >
                                    Security
                                  </h4>
                                  {reportData.data?.codeAnalysis?.security
                                    ?.rating == "A" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#F0FAEF",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your vulnerability issues
                                          <span style={{ color: "#57C269" }}>
                                            ( A = 0 Vulnerabilities )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.security
                                    ?.rating == "E" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fbefef",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your vulnerability issues
                                          <span style={{ color: "#E05656" }}>
                                            ( E = at least 1 Blocker Vulnerability
                                            )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.security
                                    ?.rating == "C" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fffaee",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>
                                          Rating given to your project related to
                                          the value of your vulnerability issues
                                          <span style={{ color: "#FFD772" }}>
                                            ( C = at least 1 Major Vulnerability )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.security
                                    ?.rating == "B" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#c4cdf5",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your vulnerability issues
                                          <span style={{ color: "#7891FF" }}>
                                            ( B = at least 1 Minor Vulnerability )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.security
                                    ?.rating == "D" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#f8e7da",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your vulnerability issues
                                          <span style={{ color: "#EF9C5F" }}>
                                            ( D = at least 1 Critical
                                            Vulnerability )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Vulnerabilities
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {
                                        reportData.data?.codeAnalysis?.security
                                          ?.vulnerabilities
                                      }{" "}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Rating
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#E05656",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <span
                                        style={
                                          reportData.data?.codeAnalysis
                                            ?.security?.rating == "A"
                                            ? { color: "#57C269" }
                                            : reportData.data?.codeAnalysis
                                              ?.security?.rating == "B"
                                              ? { color: "#7891FF" }
                                              : reportData.data?.codeAnalysis
                                                ?.security?.rating == "C"
                                                ? { color: "#FFD772" }
                                                : reportData.data?.codeAnalysis
                                                  ?.security?.rating == "D"
                                                  ? { color: "#EF9C5F" }
                                                  : reportData.data?.codeAnalysis
                                                    ?.security?.rating == ""
                                                    ? { color: "black" }
                                                    : { color: "#E05656" }
                                        }
                                      >
                                        {reportData.data?.codeAnalysis?.security
                                          ?.rating == ""
                                          ? "-"
                                          : reportData.data?.codeAnalysis
                                            ?.security?.rating}
                                      </span>
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Remediation Effort
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {`${reportData.data?.codeAnalysis?.security?.remediationEffort}h`}
                                    </p>
                                  </div>
                                  {/* <div style="width: 100%; float: left;">
                <p style=" font-size: 15px; font-family:SFProText-Medium; font-weight: 500; padding: 10px; color:#454545; background-color:#ebebeb; border-radius: 2px;">
                <span style=" margin-right: 6px; font-weight: 600;  ">Notes : </span> Rating given to your project related to the value of your technical debt ratio.
                <span style=" color: #E05656; ;">( =5% the rating is A )</span>
              </p>
            </div> */}
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      margin: "16px 0px",
                                    }}
                                  >
                                    Security Review
                                  </h4>
                                  {reportData.data?.codeAnalysis?.securtiyReview
                                    ?.rating == "A" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#F0FAEF",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          The Security Review Rating is a letter
                                          grade based on the percentage of
                                          reviewed (Fixed or Safe) security
                                          hotspots.
                                          <span style={{ color: "#57C269" }}>
                                            ( {`A = >= 80%`})
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.securityReview
                                    ?.rating == "E" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fbefef",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          The Security Review Rating is a letter
                                          grade based on the percentage of
                                          reviewed (Fixed or Safe) security
                                          hotspots.
                                          <span style={{ color: "#E05656" }}>
                                            ( {`E = < 30%`} )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.securityReview
                                    ?.rating == "C" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fffaee",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          The Security Review Rating is a letter
                                          grade based on the percentage of
                                          reviewed (Fixed or Safe) security
                                          hotspots.
                                          <span style={{ color: "#FFD772" }}>
                                            ( {`C = >= 50% and <70%`} )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.securityReview
                                    ?.rating == "B" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#c4cdf5",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          The Security Review Rating is a letter
                                          grade based on the percentage of
                                          reviewed (Fixed or Safe) security
                                          hotspots.
                                          <span style={{ color: "#7891FF" }}>
                                            ( {`B = >= 70% and <80%`} )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.securityReview
                                    ?.rating == "D" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#f8e7da",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          The Security Review Rating is a letter
                                          grade based on the percentage of
                                          reviewed (Fixed or Safe) security
                                          hotspots.
                                          <span style={{ color: "#EF9C5F" }}>
                                            ( {`D = >= 30% and <50%`} )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Security Hotspots
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {
                                        reportData.data?.codeAnalysis
                                          ?.securityReview?.securityHotspots
                                      }{" "}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Rating
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#FFD772",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <span
                                        style={
                                          reportData.data?.codeAnalysis
                                            ?.securityReview?.rating == "A"
                                            ? { color: "#57C269" }
                                            : reportData.data?.codeAnalysis
                                              ?.securityReview?.rating == "B"
                                              ? { color: "#7891FF" }
                                              : reportData.data?.codeAnalysis
                                                ?.securityReview?.rating == "C"
                                                ? { color: "#FFD772" }
                                                : reportData.data?.codeAnalysis
                                                  ?.securityReview?.rating == "D"
                                                  ? { color: "#EF9C5F" }
                                                  : reportData.data?.codeAnalysis
                                                    ?.securityReview?.rating == ""
                                                    ? { color: "black" }
                                                    : { color: "#E05656" }
                                        }
                                      >
                                        {reportData.data?.codeAnalysis
                                          ?.securityReview?.rating == ""
                                          ? "-"
                                          : reportData.data?.codeAnalysis
                                            ?.securityReview?.rating}
                                      </span>
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Reviewed
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {
                                        reportData.data?.codeAnalysis
                                          ?.securityReview?.reviewed
                                      }{" "}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    float: "left",
                                    marginTop: "1%",
                                    pageBreakInside: "avoid",
                                  }}
                                >
                                  <h4
                                    style={{
                                      fontSize: 16,
                                      fontFamily: "SFProText-Medium",
                                      fontWeight: 500,
                                      color: "#242424",
                                      marginBottom: 0,
                                      margin: "16px 0px",
                                    }}
                                  >
                                    Reliability
                                  </h4>
                                  {reportData.data?.codeAnalysis?.reliability
                                    ?.rating == "A" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#F0FAEF",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your bug issues
                                          <span style={{ color: "#57C269" }}>
                                            ( A = 0 Bugs )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.reliability
                                    ?.rating == "E" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fbefef",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your bug issues
                                          <span style={{ color: "#E05656" }}>
                                            ( E = at least 1 Blocker Bug )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.reliability
                                    ?.rating == "C" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#fffaee",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your bug issues
                                          <span style={{ color: "#FFD772" }}>
                                            ( C = at least 1 Major Bug )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.reliability
                                    ?.rating == "B" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#c4cdf5",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your bug issues
                                          <span style={{ color: "#7891FF" }}>
                                            ( B = at least 1 Minor Bug )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  {reportData.data?.codeAnalysis?.reliability
                                    ?.rating == "D" && (
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 15,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            padding: 10,
                                            color: "#454545",
                                            backgroundColor: "#f8e7da",
                                            borderRadius: 2,
                                          }}
                                        >
                                          <span
                                            style={{
                                              marginRight: 6,
                                              fontWeight: 600,
                                            }}
                                          >
                                            Note :{" "}
                                          </span>{" "}
                                          Rating given to your project related to
                                          the value of your bug issues
                                          <span style={{ color: "#EF9C5F" }}>
                                            ( D = at least 1 Critical Bug )
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Bugs
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {
                                        reportData.data?.codeAnalysis
                                          ?.reliability?.bugs
                                      }{" "}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Rating
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#FFD772",
                                        fontWeight: 600,
                                      }}
                                    >
                                      <span
                                        style={
                                          reportData.data?.codeAnalysis
                                            ?.reliability?.rating == "A"
                                            ? { color: "#57C269" }
                                            : reportData.data?.codeAnalysis
                                              ?.reliability?.rating == "B"
                                              ? { color: "#7891FF" }
                                              : reportData.data?.codeAnalysis
                                                ?.reliability?.rating == "C"
                                                ? { color: "#FFD772" }
                                                : reportData.data?.codeAnalysis
                                                  ?.reliability?.rating == "D"
                                                  ? { color: "#EF9C5F" }
                                                  : reportData.data?.codeAnalysis
                                                    ?.reliability?.rating == ""
                                                    ? { color: "black" }
                                                    : { color: "#E05656" }
                                        }
                                      >
                                        {reportData.data?.codeAnalysis
                                          ?.reliability?.rating == ""
                                          ? "-"
                                          : reportData.data?.codeAnalysis
                                            ?.reliability?.rating}
                                      </span>
                                    </p>
                                  </div>
                                  <div style={{ width: "25%", float: "left" }}>
                                    <h5
                                      style={{
                                        fontSize: 14,
                                        fontFamily: "SFProText-Medium",
                                        color: "#454545",
                                        marginBottom: 24,
                                      }}
                                    >
                                      Remediation Effort
                                    </h5>
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {`${reportData.data?.codeAnalysis?.reliability?.remediationEffort}h`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              <div style={{ marginTop: "8%" }}>
                                <ThreeReportTableComponent
                                  dataForGrid={reportData.modularityRecom}
                                  areas={false}
                                />
                              </div>
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>

                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Services Architecture
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Services Architecture
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaReport
                                          ?.servicesArchitecture
                                          ?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.serviceArchitectureDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .serviceArchitectureDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .serviceArchitectureDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .serviceArchitectureDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "2%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.servicesArchitecture
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData.servicesArchitectureRecom
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>

                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Event Based Architecture
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Event Based Architecture
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaReport
                                          ?.eventBasedArchitecture
                                          ?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.eventBasedArchitectureDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>

                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .eventBasedArchitectureDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .eventBasedArchitectureDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .eventBasedArchitectureDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "2%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.eventBasedArchitecture
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData.eventBasedArchitectureRecom
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Logging
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Logging
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaReport
                                          ?.logging?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.loggingDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>

                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.loggingDonutChart[0].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.loggingDonutChart[1].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.loggingDonutChart[2].value}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "2%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.logging?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.loggingRecom}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Integration
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Integration
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaReport
                                          ?.integration?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.integrationDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.integrationDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.integrationDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.integrationDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "2%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.integration
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.integrationRecom}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
                {/** Platform start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Platform
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Platform
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[5].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaReport
                                          ?.platform?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.platformDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.platformDonutChart[0].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.platformDonutChart[1].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.platformDonutChart[2].value}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "2%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[5].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.platform?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.platformRecom}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Platform end */}
                {/**API Strategy start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%", marginTop: "50%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  API Strategy
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    API Strategy
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[6].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaReport
                                          ?.apiStrategy?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.apiStrategyDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.apiStrategyDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.apiStrategyDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.apiStrategyDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "2%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[6].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.apiStrategy
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.apiStrategyRecom}
                                areas={false}
                              />
                              <div style={{ marginTop: "140%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Api startegy end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName === "WAFR" &&
            <>
              <div ref={contentRef}>
                <table style={{ width: '100%' }} className="new-page">
                  <tbody>
                    <tr>
                      <td>
                        {/*Reports page starts here */}
                        <div style={{ width: '98%', height: '1100px', float: 'left', padding: '2% 2% 3% 0', pageBreakInside: 'avoid', position: 'relative' }}>
                          <img src={frontPageLogo} alt="background-vector" style={{ width: '100%', height: '80vh', position: 'absolute', zIndex: 1, bottom: 20, right: 0 }} />
                          <div style={{ width: '99%', height: '1100px', float: 'left', margin: '1% 0%', padding: '3%', backgroundColor: '#F3F3F3', position: 'relative' }}>
                            {/* Main header style */}
                            <img src={logoUrl} width="80px" height="80px" alt="prenuvo-logo" />
                            <div style={{ width: '40%', position: 'absolute', bottom: '30%', right: '10%', textAlign: 'center' }}>
                              <img src={awsLogo} alt="aws-logo" style={{ marginBottom: '10%' }} />
                              <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '20px', margin: '3% 0% 2% 0%', lineHeight: '20px' }}>
                                AWS Well-Architected
                                Framework Review</p>
                            </div>
                          </div>
                          <p style={{ fontFamily: 'Arial', textAlign: 'center', color: '#000', fontWeight: 700, fontSize: '14px', marginTop: '2%', float: 'left' }}>Date | {formatCurrentDate()}</p>
                          <img src={zebLogo} alt="zeb-logo" style={{ float: 'right', marginTop: '2%' }} />
                        </div>
                        {/*Reports page ends here */}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <WafrDownloadReport props={reportData.data} />
              </div>

            </>

          }
          {/* {reportData?.data?.verticalName === "MDPW" && <AwsMdpwDowloadReport props={reportData.data} />} */}
          {reportData?.data?.verticalName === "Databricks" && bindDatabricks()}
          {reportData?.data?.verticalName === "MDPW" && bindAwsMdpw()}
          {reportData?.data?.verticalName === "VMware" && bindVMware()}
          {reportData?.data?.verticalName == "MAP" && bindInfrastructureReport()}
          {reportData?.data?.verticalName == "Data Strategy" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Transactional Database
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Transactional Database
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaMenuReport
                                        ?.transactionalDatabase
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.transactionalDatabaseDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .transactionalDatabaseDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .transactionalDatabaseDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .transactionalDatabaseDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.transactionalDatabase
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.transactionalDatabaseOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  No SQL Database
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    No SQL Database
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaMenuReport
                                        ?.noSqlDatabase?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.noSqlDatabaseDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.noSqlDatabaseDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.noSqlDatabaseDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.noSqlDatabaseDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.noSqlDatabase
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.noSqlDatabaseOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  DataLake & DataWarehouse
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    DataLake & DataWarehouse
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaMenuReport
                                        .dataLakeAndDataWarehouse
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.dataLakeAndDataWarehouseDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .dataLakeAndDataWarehouseDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .dataLakeAndDataWarehouseDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .dataLakeAndDataWarehouseDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.dataLakeAndDataWarehouse
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.dataLakeAndDataWarehouseOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Reporting & Analytics
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Reporting & Analytics
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaMenuReport
                                        .reportingAndAnalysis
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.reportingAndAnalysisDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .reportingAndAnalysisDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .reportingAndAnalysisDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .reportingAndAnalysisDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.reportingAndAnalysis
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.reportingAndAnalysisOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  AI & ML
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    AI & ML
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaMenuReport
                                        .AIAndML.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.aIAndMLDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.aIAndMLDonutChart[0].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.aIAndMLDonutChart[1].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.aIAndMLDonutChart[2].value}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.AIAndML?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.aIAndMLOverall}
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "Mobility" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  User Experience
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    User Experience
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaMenuReport
                                        ?.userExperience?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.userExperienceDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.userExperienceDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.userExperienceDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.userExperienceDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.userExperience
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.userExperienceOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Performance Monitoring
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Performance Monitoring
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaMenuReport
                                        ?.performanceMonitoring
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.performanceMonitoringDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .performanceMonitoringDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .performanceMonitoringDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .performanceMonitoringDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.performanceMonitoring
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.performanceMonitoringOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Codebase Architecture
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Code Base Architecture
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaMenuReport
                                        .codebaseArchitecture
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.codebaseArchitectureDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .codebaseArchitectureDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .codebaseArchitectureDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .codebaseArchitectureDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.codebaseArchitecture
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.codebaseArchitectureOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Device & Build Security
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Device & Build Security
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaMenuReport
                                        .deviceAndBuildSecurity
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.deviceandBuildSecurityDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .deviceandBuildSecurityDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .deviceandBuildSecurityDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .deviceandBuildSecurityDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.deviceAndBuildSecurity
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.deviceandBuildSecurityOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  User & App Identity Management
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    User & App Identity Management
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaMenuReport
                                        .userAndAppIdentityManagement
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.userandAppIdentityDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .userandAppIdentityDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .userandAppIdentityDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .userandAppIdentityDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.userAndAppIdentityManagement
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.userandAppIdentityOverall
                                }
                                areas={false}
                              />
                              <div style={{ marginTop: "130%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "DevSecOps" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Release Management
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Release Management
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaReport
                                        ?.releaseManagement?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.releaseManagementDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .releaseManagementDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .releaseManagementDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .releaseManagementDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.releaseManagement
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.releaseManagementOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Continuous Integration and Delivery
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 12,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Continuous Integration and Delivery
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.continuousIntegrationAndContinuousDevelopementPipelines
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.ciAndDeliveryDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.ciAndDeliveryDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.ciAndDeliveryDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.ciAndDeliveryDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.continuousIntegrationAndContinuousDevelopementPipelines
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.ciAndDeliveryOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Code Management & Version Control
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 12,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Code Management & Version Control
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .codeManagementAndVersionControl
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.codeManagementDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.codeManagementDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.codeManagementDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.codeManagementDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.codeManagementAndVersionControl
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.codeManagementOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Access Management & Privileged Control
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 12,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Access Management & Privileged Control
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .accessManagementAndPrivilegedControl
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.accessMangamentDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .accessMangamentDonutChart[0].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .accessMangamentDonutChart[1].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .accessMangamentDonutChart[2].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.accessManagementAndPrivilegedControl
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.accessMangamentOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Monitoring & Incident Response
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Monitoring & Incident Response
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .monitoringAndIncidentResponse
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.monitoringAndIncidentDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .monitoringAndIncidentDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .monitoringAndIncidentDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .monitoringAndIncidentDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.monitoringAndIncidentResponse
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.monitoringAndIncidentOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Compliance & Governance
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Compliance & Governance
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[5].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.complianceAndGovernance
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.compilanceAndGovDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .compilanceAndGovDonutChart[0].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .compilanceAndGovDonutChart[1].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .compilanceAndGovDonutChart[2].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[5].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.complianceAndGovernance
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.compilanceAndGovOverall
                                }
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "QA Processes" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaNames?.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data
                                        ?.controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.overallDonuchart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** req analysis start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Requirement Analysis
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Requirement Analysis
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[1]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.requirementAnalysis
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.requirementAnalysisDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .requirementAnalysisDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .requirementAnalysisDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .requirementAnalysisDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.requirementAnalysis
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.requirementAnalysisOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/* Test Coverage starts*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Test Coverage
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Test Coverage
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[1]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.testCoverage?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.testCoverageDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.testCoverageDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.testCoverageDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.testCoverageDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.testCoverage
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.testCoverageOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/*Test Coverage Ends */}
                {/**workflow start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Workflow Management
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Workflow Management
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[2]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.workFlowManagement
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.workflowManagementDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .workflowManagementDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .workflowManagementDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .workflowManagementDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.workFlowManagement
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.workflowManagementOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/*Test case & Test Script Development starts */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Test case & Test Script Development
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 12,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Test case & Test Script Development
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[3]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.testCaseAndTestScriptDevelop
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.testCaseAndTestScriptDevDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .testCaseAndTestScriptDevDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .testCaseAndTestScriptDevDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .testCaseAndTestScriptDevDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.testCaseAndTestScriptDevelop
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.testCaseAndTestScriptDevOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>

                {/*Test case & Test Script Development Ends */}
                {/*Defect Validation Starts */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Defect Validation
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Defect Validation
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[4]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.defectValidation?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.defectValidationDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .defectValidationDonutChart[0].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .defectValidationDonutChart[1].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .defectValidationDonutChart[2].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.defectValidation
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.defectValidationOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/*Defect Validation Ends */}
                {/** test env start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Test Environment
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Test Environment
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[5]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.testEnvironment?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.testEnvironmentDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .testEnvironmentDonutChart[0].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .testEnvironmentDonutChart[1].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .testEnvironmentDonutChart[2].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[5].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.testEnvironment
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.testEnvironmentOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/**functional start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Functional Testing
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Functional Testing
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[6]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.functionalTesting?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.functionalTestingDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .functionalTestingDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .functionalTestingDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .functionalTestingDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[6].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.functionalTesting
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.functionalTestingOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
                {/** non func test start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Non - Functional Testing
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Non - Functional Testing
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData?.data?.controlAreaProficiencyIndex[7]?.value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.nonFunctionalTesting
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData?.nonFunctionalTestingDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .nonFunctionalTestingDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .nonFunctionalTestingDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .nonFunctionalTestingDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[7].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.nonFunctionalTesting
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.nonFunctionalTestingOverall
                                }
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "Product Development Processes" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Proof of Concept
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Proof of Concept
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaReport
                                        ?.proofOfConcept?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.proofOfConceptDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.proofOfConceptDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.proofOfConceptDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.proofOfConceptDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.proofOfConcept
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.proofOfConceptOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Product Design
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Product Design
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.productDesign?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.productDesignDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.productDesignDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.productDesignDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.productDesignDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data?.controlAreaReport
                                            ?.productDesign
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.productDesignOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Prototyping
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Prototyping
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .prototyping.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.prototypingDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.prototypingDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.prototypingDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.prototypingDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.prototyping
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.prototypingOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Product Development
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Product Development
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .productDevelopment.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.productDevelopmentDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .productDevelopmentDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .productDevelopmentDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .productDevelopmentDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.productDevelopment
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.productDevelopmentOverall
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Go To Market
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Go To Market
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .goToMarket.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.goToMarketDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.goToMarketDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.goToMarketDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.goToMarketDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.goToMarket?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData?.goToMarketOverall}
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "Development Processes" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Define
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Define
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaReport?.define
                                        ?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.defineDonutChart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.defineDonutChart[0].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.defineDonutChart[1].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.defineDonutChart[2].value}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.define?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.defineOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Design
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Design
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.design?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.designDonutChart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.designDonutChart[0].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.designDonutChart[1].value}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.designDonutChart[2].value}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.design?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.designOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Development
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Development
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaReport
                                        ?.development?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.developmentDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.developmentDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.developmentDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.developmentDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.development
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.developmentOverall}
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "MS O365" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Governance
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Governance
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaReport
                                        ?.governance?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.governanceDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.governanceDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.governanceDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.governanceDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.governance?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.governanceOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Compliance
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Compliance
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData?.data?.controlAreaReport
                                        ?.compliance?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.compilanceDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.compilanceDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.compilanceDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.compilanceDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.compliance?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.compilanceOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Security Audit
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Security Audit
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .securityAudit.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.securityAuditDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.securityAuditDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.securityAuditDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.securityAuditDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.securityAudit
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.securityAuditOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Site Hierarchy
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Site Hierarchy
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .siteHierarchy.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.siteHierarchyDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.siteHierarchyDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.siteHierarchyDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.siteHierarchyDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.siteHierarchy
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.siteHierarchyOverall}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Custom Apps
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Custom Apps
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .customApps.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.customAppsDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.customAppsDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.customAppsDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.customAppsDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.customApps?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.customAppsOverall}
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "Copilot" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style={{ width: '100%', float: 'left', position: 'fixed', bottom: '10px', backgroundColor: 'white' }} className="body-25vh">
                      <img src="images/footer-style.svg" alt="footer design" style={{ height: '52px', width: '100%', marginBottom: '10px' }} />
                      <div style={{ textAlign: 'center' }}>
                        <img src={avasoftLogo} alt="avasoft-logo" style={{ width: '150px', height: '52px' }} />
                      </div>
                    </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Copilot Readiness start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                  marginTop: "150%",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Copilot Readiness
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Copilot Readiness
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data?.controlAreaReport
                                        ?.copilotReadiness?.controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.copilotReadinessDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.copilotReadinessDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.copilotReadinessDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.copilotReadinessDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.copilotReadiness?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.copilotReadinessOverall}
                                areas={false}
                              />
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Copilot Readiness end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "IAM & Security" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>
              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Identity & Access Management
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Identity & Access Management
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[0].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.identityAndAccessManagement
                                          ?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.identityAndAccessDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .identityAndAccessDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .identityAndAccessDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .identityAndAccessDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              {reportData.data.isAzureADConnected != -1 && (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      marginTop: "2%",
                                      pageBreakInside: "avoid",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                      }}
                                    >
                                      Users and Roles
                                    </h2>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingRight: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.activeUsersDonutChartData
                                        }
                                        active={true}
                                      ></ReportDonutChart>
                                      {reportData.data.isAzureADConnected == 1 && (
                                        <>
                                          <div
                                            style={{
                                              width: "48%",
                                              float: "left",
                                              paddingRight: "2%",
                                              marginTop: "2%",
                                            }}
                                          >
                                            <div style={{ float: "left" }}>
                                              <span
                                                style={{
                                                  display: "inline-block",
                                                  width: 16,
                                                  height: 16,
                                                  backgroundColor: "#76E3F2",
                                                  borderRadius: 16,
                                                  marginRight: 12,
                                                }}
                                              />
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 14,
                                                  color: "#000000",
                                                }}
                                              >
                                                Guests
                                              </label>
                                            </div>
                                            <div style={{ float: "right" }}>
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 16,
                                                  fontWeight: 600,
                                                  float: "right",
                                                }}
                                              >
                                                {reportData.data?.identityAndAccessManagementMetric?.activeGuestsUser?.toLocaleString()}
                                              </label>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              width: "48%",
                                              float: "left",
                                              paddingLeft: "2%",
                                              marginTop: "2%",
                                            }}
                                          >
                                            <div style={{ float: "left" }}>
                                              <span
                                                style={{
                                                  display: "inline-block",
                                                  width: 16,
                                                  height: 16,
                                                  backgroundColor: "#8AA2F9",
                                                  borderRadius: 16,
                                                  marginRight: 12,
                                                }}
                                              />
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 14,
                                                  color: "#000000",
                                                }}
                                              >
                                                Internal
                                              </label>
                                            </div>
                                            <div style={{ float: "right" }}>
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 16,
                                                  fontWeight: 600,
                                                  float: "right",
                                                }}
                                              >
                                                {reportData.data?.identityAndAccessManagementMetric?.activeInternalUser?.toLocaleString()}
                                              </label>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.inActiveUsersDonutChartData
                                        }
                                        active={false}
                                      ></ReportDonutChart>
                                      {reportData.data.isAzureADConnected == 1 && (
                                        <>
                                          <div
                                            style={{
                                              width: "48%",
                                              float: "left",
                                              paddingRight: "2%",
                                              marginTop: "2%",
                                            }}
                                          >
                                            <div style={{ float: "left" }}>
                                              <span
                                                style={{
                                                  display: "inline-block",
                                                  width: 16,
                                                  height: 16,
                                                  backgroundColor: "#76E3F2",
                                                  borderRadius: 16,
                                                  marginRight: 12,
                                                }}
                                              />
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 14,
                                                  color: "#000000",
                                                }}
                                              >
                                                Guests
                                              </label>
                                            </div>
                                            <div style={{ float: "right" }}>
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 16,
                                                  fontWeight: 600,
                                                  float: "right",
                                                }}
                                              >
                                                {reportData.data?.identityAndAccessManagementMetric?.inActiveGuestsUser?.toLocaleString()}
                                              </label>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              width: "48%",
                                              float: "left",
                                              paddingLeft: "2%",
                                              marginTop: "2%",
                                            }}
                                          >
                                            <div style={{ float: "left" }}>
                                              <span
                                                style={{
                                                  display: "inline-block",
                                                  width: 16,
                                                  height: 16,
                                                  backgroundColor: "#8AA2F9",
                                                  borderRadius: 16,
                                                  marginRight: 12,
                                                }}
                                              />
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 14,
                                                  color: "#000000",
                                                }}
                                              >
                                                Internal
                                              </label>
                                            </div>
                                            <div style={{ float: "right" }}>
                                              <label
                                                style={{
                                                  fontFamily: "SFProText-Medium",
                                                  fontSize: 16,
                                                  fontWeight: 600,
                                                  float: "right",
                                                }}
                                              >
                                                {reportData.data?.identityAndAccessManagementMetric?.inActiveInternalUser?.toLocaleString()}
                                              </label>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <>
                                    <div
                                      style={{
                                        width: "100%",
                                        float: "left",
                                        margin: "1% 0%",
                                        pageBreakInside: "avoid",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          fontSize: 16,
                                          fontFamily: "SFProText-Medium",
                                          fontWeight: 500,
                                          color: "#242424",
                                          marginBottom: 0,
                                          marginTop: "2%",
                                        }}
                                      >
                                        Authentication and Authorization Mechanisms
                                      </h4>
                                      <div
                                        style={{
                                          width: "24%",
                                          float: "left",
                                          paddingRight: "1%",
                                        }}
                                      >
                                        <h5
                                          style={{
                                            fontSize: 14,
                                            fontFamily: "SFProText-Medium",
                                            color: "#454545",
                                            marginBottom: 24,
                                          }}
                                        >
                                          # of Applications
                                        </h5>
                                        <p
                                          style={{
                                            fontSize: 20,
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {reportData.data?.identityAndAccessManagementMetric?.noOfApplications?.toLocaleString()}
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          width: "24%",
                                          float: "left",
                                          paddingLeft: "1%",
                                        }}
                                      >
                                        <h5
                                          style={{
                                            fontSize: 14,
                                            fontFamily: "SFProText-Medium",
                                            color: "#454545",
                                            marginBottom: 24,
                                          }}
                                        >
                                          # of Groups
                                        </h5>
                                        <p
                                          style={{
                                            fontSize: 20,
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {reportData.data?.identityAndAccessManagementMetric?.noOfGroups?.toLocaleString()}
                                        </p>
                                      </div>
                                      <div
                                        style={{
                                          width: "24%",
                                          float: "left",
                                          paddingLeft: "1%",
                                        }}
                                      >
                                        <h5
                                          style={{
                                            fontSize: 14,
                                            fontFamily: "SFProText-Medium",
                                            color: "#454545",
                                            marginBottom: 24,
                                          }}
                                        >
                                          MFA Disabled Users
                                        </h5>
                                        <p
                                          style={{
                                            fontSize: 20,
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 600,
                                          }}
                                        >
                                          {reportData.data?.identityAndAccessManagementMetric?.mfaDisabledUsers?.toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                    {/* Authentication and Authorization ends here*/}
                                    {/* 2 coloumn widget (identity protection and users) starts here*/}
                                    <div
                                      style={{
                                        width: "100%",
                                        float: "left",
                                        margin: "1% 0%",
                                        pageBreakInside: "avoid",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "47%",
                                          float: "left",
                                          paddingRight: "3%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            float: "left",
                                          }}
                                        >
                                          <h4
                                            style={{
                                              fontSize: 16,
                                              fontFamily: "SFProText-Medium",
                                              fontWeight: 500,
                                              color: "#242424",
                                              marginBottom: 0,
                                              marginTop: "2%",
                                            }}
                                          >
                                            Identity Protection
                                          </h4>
                                          <div
                                            style={{
                                              width: "48%",
                                              float: "left",
                                              paddingRight: "2%",
                                            }}
                                          >
                                            <h5
                                              style={{
                                                fontSize: 14,
                                                fontFamily: "SFProText-Medium",
                                                color: "#454545",
                                                marginBottom: 24,
                                              }}
                                            >
                                              Password Policy
                                            </h5>
                                            <p
                                              style={{
                                                fontSize: 20,
                                                fontFamily: "SFProText-Medium",
                                                color: "#000000",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {
                                                reportData.data
                                                  ?.identityAndAccessManagementMetric
                                                  ?.passwordPolicy
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        {reportData.data.isAzureADConnected == 1 && (
                                          <>
                                            <div
                                              style={{
                                                width: "100%",
                                                float: "left",
                                              }}
                                            >
                                              <h4
                                                style={{
                                                  fontSize: 16,
                                                  fontFamily: "SFProText-Medium",
                                                  fontWeight: 500,
                                                  color: "#242424",
                                                  marginBottom: 0,
                                                  marginTop: "2%",
                                                }}
                                              >
                                                License Utilization
                                              </h4>
                                              <div
                                                style={{
                                                  width: "48%",
                                                  float: "left",
                                                  paddingRight: "2%",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  License assigned for Active users
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {reportData.data?.identityAndAccessManagementMetric?.licenseAssignedForActiveUsers?.toLocaleString()}
                                                </p>
                                              </div>
                                              <div
                                                style={{
                                                  width: "48%",
                                                  float: "left",
                                                  paddingLeft: "2%",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  License assigned for Inactive
                                                  users
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {reportData.data?.identityAndAccessManagementMetric?.licenseAssignedForInactiveUsers?.toLocaleString()}
                                                </p>
                                              </div>
                                              <div
                                                style={{
                                                  width: "48%",
                                                  float: "left",
                                                  paddingRight: "2%",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Un assigned license count
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {reportData.data?.identityAndAccessManagementMetric?.unAssignedLicenseCount?.toLocaleString()}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          width: "47%",
                                          float: "left",
                                          paddingLeft: "3%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <h4
                                          style={{
                                            textAlign: "left",
                                            fontSize: 16,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            color: "#242424",
                                            marginBottom: 0,
                                            margin: "2% 0% 4% 0%",
                                          }}
                                        >
                                          Identity Protection - Risky users
                                        </h4>
                                        <ReportDonutChart
                                          dataNameValue2={
                                            reportData.riskyUsersDonuChartData
                                          }
                                          totalUsers={true}
                                        ></ReportDonutChart>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingRight: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              float: "left",
                                              width: "80%",
                                              textAlign: "left",
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: "inline-block",
                                                width: 16,
                                                height: 16,
                                                backgroundColor: "#FFA79D",
                                                borderRadius: 16,
                                                marginRight: 12,
                                              }}
                                            />
                                            <label
                                              style={{
                                                fontFamily: "SFProText-Medium",
                                                fontSize: 14,
                                                color: "#000000",
                                              }}
                                            >
                                              High Risk Users
                                            </label>
                                          </div>
                                          <div
                                            style={{ float: "left", width: "20%" }}
                                          >
                                            <label
                                              style={{
                                                fontFamily: "SFProText-Medium",
                                                fontSize: 16,
                                                fontWeight: 600,
                                                float: "right",
                                              }}
                                            >
                                              {reportData.data?.identityAndAccessManagementMetric?.highRiskyUsers?.toLocaleString()}
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingLeft: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              float: "left",
                                              width: "90%",
                                              textAlign: "left",
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: "inline-block",
                                                width: 16,
                                                height: 16,
                                                backgroundColor: "#FCD269",
                                                borderRadius: 16,
                                                marginRight: 12,
                                              }}
                                            />
                                            <label
                                              style={{
                                                fontFamily: "SFProText-Medium",
                                                fontSize: 14,
                                                color: "#000000",
                                              }}
                                            >
                                              Medium Risk Users
                                            </label>
                                          </div>
                                          <div
                                            style={{ float: "left", width: "10%" }}
                                          >
                                            <label
                                              style={{
                                                fontFamily: "SFProText-Medium",
                                                fontSize: 16,
                                                fontWeight: 600,
                                                float: "right",
                                              }}
                                            >
                                              {reportData.data?.identityAndAccessManagementMetric?.mediumRiskyUsers?.toLocaleString()}
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingRight: "2%",
                                            marginTop: "3%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              float: "left",
                                              width: "80%",
                                              textAlign: "left",
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: "inline-block",
                                                width: 16,
                                                height: 16,
                                                backgroundColor: "#9FF5A2",
                                                borderRadius: 16,
                                                marginRight: 12,
                                              }}
                                            />
                                            <label
                                              style={{
                                                fontFamily: "SFProText-Medium",
                                                fontSize: 14,
                                                color: "#000000",
                                              }}
                                            >
                                              Low Risk Users
                                            </label>
                                          </div>
                                          <div
                                            style={{ float: "left", width: "20%" }}
                                          >
                                            <label
                                              style={{
                                                fontFamily: "SFProText-Medium",
                                                fontSize: 16,
                                                fontWeight: 600,
                                                float: "right",
                                              }}
                                            >
                                              {reportData.data?.identityAndAccessManagementMetric?.lowRiskyUsers?.toLocaleString()}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </>
                              )}


                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 80,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[0].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.identityAndAccessManagement
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData.identityAndAccessManagementRecom
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Email Security
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Email Security
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[1].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.emailSecurity?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.emailSecurityDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.emailSecurityDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.emailSecurityDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.emailSecurityDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {reportData.data.isAzureADConnected == 1 && (
                                <div>
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                      pageBreakInside: "avoid",
                                    }}
                                  >
                                    <h4
                                      style={{
                                        fontSize: 16,
                                        fontFamily: "SFProText-Medium",
                                        fontWeight: 500,
                                        color: "#242424",
                                        marginBottom: 0,
                                        marginTop: "2%",
                                      }}
                                    >
                                      Email Discovery
                                    </h4>
                                    <div
                                      style={{
                                        width: "24%",
                                        float: "left",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      <h5
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "SFProText-Medium",
                                          color: "#454545",
                                          marginBottom: 24,
                                        }}
                                      >
                                        Total Emails (Inbound)
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {reportData.data?.emailSecurityMetric?.inboundEmails?.toLocaleString()}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "24%",
                                        float: "left",
                                        paddingLeft: "1%",
                                      }}
                                    >
                                      <h5
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "SFProText-Medium",
                                          color: "#454545",
                                          marginBottom: 24,
                                        }}
                                      >
                                        Total Emails (Outbound)
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {reportData.data?.emailSecurityMetric?.outboundEmails?.toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                      pageBreakInside: "avoid",
                                    }}
                                  >
                                    <h4
                                      style={{
                                        fontSize: 16,
                                        fontFamily: "SFProText-Medium",
                                        fontWeight: 500,
                                        color: "#242424",
                                        marginBottom: 0,
                                        marginTop: "2%",
                                      }}
                                    >
                                      Incoming Emails - CompAuth Failures
                                    </h4>
                                    <div
                                      style={{
                                        width: "24%",
                                        float: "left",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      <h5
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "SFProText-Medium",
                                          color: "#454545",
                                          marginBottom: 24,
                                        }}
                                      >
                                        DKIM
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {reportData.data?.emailSecurityMetric?.DKIM?.toLocaleString()}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "24%",
                                        float: "left",
                                        paddingLeft: "1%",
                                      }}
                                    >
                                      <h5
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "SFProText-Medium",
                                          color: "#454545",
                                          marginBottom: 24,
                                        }}
                                      >
                                        DMARC
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {reportData.data?.emailSecurityMetric?.DMARC?.toLocaleString()}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "24%",
                                        float: "left",
                                        paddingRight: "1%",
                                      }}
                                    >
                                      <h5
                                        style={{
                                          fontSize: 14,
                                          fontFamily: "SFProText-Medium",
                                          color: "#454545",
                                          marginBottom: 24,
                                        }}
                                      >
                                        SPF Failure
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {reportData.data?.emailSecurityMetric?.SPFFailure?.toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                      pageBreakInside: "avoid",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "45%",
                                        float: "left",
                                        marginTop: "3%",
                                        paddingRight: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "4% 0%",
                                        }}
                                      >
                                        <div style={{ float: "left" }}>
                                          <h2
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 500,
                                              fontSize: 17,
                                              margin: 0,
                                            }}
                                          >
                                            Malware Threats
                                          </h2>
                                        </div>
                                        <div style={{ float: "right" }}>
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#0B579E",
                                              backgroundColor: "#EDF6FF",
                                              padding: "6px 10px",
                                              borderRadius: 4,
                                            }}
                                          >
                                            #Total{" "}
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                paddingLeft: 24,
                                              }}
                                            >
                                              {(
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[0].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[1].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[2].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[3].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[4].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[5].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.malwareThreats[6].uv
                                              )?.toLocaleString()}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <ReportAppLineChart
                                        dataForLine={
                                          reportData.data?.emailSecurityMetric
                                            ?.malwareThreats
                                        }
                                      ></ReportAppLineChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "45%",
                                        float: "left",
                                        marginTop: "3%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "4% 0%",
                                        }}
                                      >
                                        <div style={{ float: "left" }}>
                                          <h2
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 500,
                                              fontSize: 17,
                                              margin: 0,
                                            }}
                                          >
                                            Phish Threats
                                          </h2>
                                        </div>
                                        <div style={{ float: "right" }}>
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#0B579E",
                                              backgroundColor: "#EDF6FF",
                                              padding: "6px 10px",
                                              borderRadius: 4,
                                            }}
                                          >
                                            #Total{" "}
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                paddingLeft: 24,
                                              }}
                                            >
                                              {(
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[0].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[1].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[2].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[3].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[4].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[5].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.phishThreats[6].uv
                                              )?.toLocaleString()}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <ReportAppLineChart
                                        dataForLine={
                                          reportData.data?.emailSecurityMetric
                                            ?.phishThreats
                                        }
                                      ></ReportAppLineChart>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      pageBreakInside: "avoid",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "45%",
                                        float: "left",
                                        marginTop: "3%",
                                        paddingRight: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "4% 0%",
                                        }}
                                      >
                                        <div style={{ float: "left" }}>
                                          <h2
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 500,
                                              fontSize: 17,
                                              margin: 0,
                                            }}
                                          >
                                            Spam Threats
                                          </h2>
                                        </div>
                                        <div style={{ float: "right" }}>
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#0B579E",
                                              backgroundColor: "#EDF6FF",
                                              padding: "6px 10px",
                                              borderRadius: 4,
                                            }}
                                          >
                                            #Total{" "}
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                paddingLeft: 24,
                                              }}
                                            >
                                              {(
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[0].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[1].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[2].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[3].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[4].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[5].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spamThreats[6].uv
                                              )?.toLocaleString()}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <ReportAppLineChart
                                        dataForLine={
                                          reportData.data?.emailSecurityMetric
                                            ?.spamThreats
                                        }
                                      ></ReportAppLineChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "45%",
                                        float: "left",
                                        marginTop: "3%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "4% 0%",
                                        }}
                                      >
                                        <div style={{ float: "left" }}>
                                          <h2
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 500,
                                              fontSize: 17,
                                              margin: 0,
                                            }}
                                          >
                                            Spoof Threats
                                          </h2>
                                        </div>
                                        <div style={{ float: "right" }}>
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              fontWeight: 500,
                                              color: "#0B579E",
                                              backgroundColor: "#EDF6FF",
                                              padding: "6px 10px",
                                              borderRadius: 4,
                                            }}
                                          >
                                            #Total{" "}
                                            <span
                                              style={{
                                                fontWeight: 600,
                                                paddingLeft: 24,
                                              }}
                                            >
                                              {(
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[0].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[1].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[2].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[3].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[4].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[5].uv +
                                                reportData.data
                                                  ?.emailSecurityMetric
                                                  ?.spoofThreats[6].uv
                                              )?.toLocaleString()}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                      <ReportAppLineChart
                                        dataForLine={
                                          reportData.data?.emailSecurityMetric
                                            ?.spoofThreats
                                        }
                                      ></ReportAppLineChart>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {/* Based on your score starts here*/}

                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "4%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[1].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.emailSecurity
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.emailSecurityRecom}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  EndPoint Security
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    EndPoint Security
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[2].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.endpointSecurity
                                          ?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.endpointSecurityDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .endpointSecurityDonutChart[0].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .endpointSecurityDonutChart[1].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .endpointSecurityDonutChart[2].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {reportData.data.isAzureADConnected == 1 && (
                                <div>
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "6% 0% 1% 0%",
                                      pageBreakInside: "avoid",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <h4
                                          style={{
                                            fontSize: 16,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            color: "#242424",
                                            marginBottom: 0,
                                            marginTop: "2%",
                                          }}
                                        >
                                          Device Registration
                                        </h4>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingRight: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: 14,
                                              fontFamily: "SFProText-Medium",
                                              color: "#454545",
                                              marginBottom: 24,
                                            }}
                                          >
                                            Total Devices
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: 20,
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {reportData.data?.endpointSecurityMetric?.totalDevices?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingLeft: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: 14,
                                              fontFamily: "SFProText-Medium",
                                              color: "#454545",
                                              marginBottom: 24,
                                            }}
                                          >
                                            Managed Corporate Device
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: 20,
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {reportData.data?.endpointSecurityMetric?.managedCorporateDevice?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingRight: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: 14,
                                              fontFamily: "SFProText-Medium",
                                              color: "#454545",
                                              marginBottom: 24,
                                            }}
                                          >
                                            Managed BYOD Device
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: 20,
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {
                                              reportData.data
                                                ?.endpointSecurityMetric
                                                ?.managedByodDevice
                                            }
                                          </p>
                                        </div>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingLeft: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: 14,
                                              fontFamily: "SFProText-Medium",
                                              color: "#454545",
                                              marginBottom: 24,
                                            }}
                                          >
                                            Non- Compliant Device
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: 20,
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {reportData.data?.endpointSecurityMetric?.nonCompliantDevice?.toLocaleString()}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        style={{ width: "100%", float: "left" }}
                                      >
                                        <h4
                                          style={{
                                            fontSize: 16,
                                            fontFamily: "SFProText-Medium",
                                            fontWeight: 500,
                                            color: "#242424",
                                            marginBottom: 0,
                                            marginTop: "4%",
                                          }}
                                        >
                                          Disk Encryption
                                        </h4>
                                        <div
                                          style={{
                                            width: "48%",
                                            float: "left",
                                            paddingRight: "2%",
                                            marginTop: "2%",
                                          }}
                                        >
                                          <h5
                                            style={{
                                              fontSize: 14,
                                              fontFamily: "SFProText-Medium",
                                              color: "#454545",
                                              marginBottom: 24,
                                            }}
                                          >
                                            Disk not Encrypted Device Counts
                                          </h5>
                                          <p
                                            style={{
                                              fontSize: 20,
                                              fontFamily: "SFProText-Medium",
                                              color: "#000000",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {reportData.data?.endpointSecurityMetric?.nonEncryptedDevice?.toLocaleString()}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <h4
                                        style={{
                                          textAlign: "left",
                                          fontSize: 16,
                                          fontFamily: "SFProText-Medium",
                                          fontWeight: 500,
                                          color: "#242424",
                                          marginBottom: 0,
                                          margin: "2% 0% 4% 0%",
                                        }}
                                      >
                                        Device Registration
                                      </h4>
                                      <BarChart
                                        width={500}
                                        height={300}
                                        data={[
                                          {
                                            name: "Corporate",
                                            value:
                                              reportData.data.endpointSecurityMetric?.corporate.toLocaleString(),
                                          },
                                          {
                                            name: "BYOD",
                                            value:
                                              reportData.data.endpointSecurityMetric?.byod.toLocaleString(),
                                          },
                                        ]}
                                        margin={{
                                          top: 20,
                                          right: 30,
                                          left: 20,
                                          bottom: 5,
                                        }}
                                      >
                                        <CartesianGrid
                                          horizontal={false}
                                          vertical={false}
                                        />
                                        <XAxis
                                          type="category"
                                          dataKey="name"
                                          tickLine={false}
                                        />
                                        <YAxis />
                                        <Bar
                                          dataKey="value"
                                          fill="#8caaff"
                                          barSize={40}
                                        >
                                          {/* <LabelList
              dataKey="value"
              position="top"
              offset={5}
              formatter={formatBarLabel}
            /> */}
                                        </Bar>
                                      </BarChart>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "3%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[2].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.endpointSecurity
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}

                              <div
                                style={{
                                  pageBreakInside: "avoid",
                                  marginTop: `${reportData.data.isAzureADConnected == 1
                                    ? "150%"
                                    : "0"
                                    }`,
                                }}
                              >
                                <ThreeReportTableComponent
                                  dataForGrid={reportData.endpointSecurityRecom}
                                  areas={false}
                                />
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Event Based Architecture start */}
                <div
                  style={{ WebkitPrintColorAdjust: "exact", marginTop: "130%" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Application Security
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Application Security
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.applicationSecurity
                                          ?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.applicationSecurityDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .applicationSecurityDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .applicationSecurityDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .applicationSecurityDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "3%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.applicationSecurity
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData.applicationSecurityRecom
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Network Security
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Network Security
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[4].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.networkSecurity?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.networkSecurityDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .networkSecurityDonutChart[0].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .networkSecurityDonutChart[1].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .networkSecurityDonutChart[2].value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "3%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[4].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.networkSecurity
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.networkSecurityRecom}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**logging end */}
                {/**Integration start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Data Protection
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Data Protection
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.dataProtection?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.dataProtectionDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.dataProtectionDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.dataProtectionDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData.dataProtectionDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[5].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.dataProtection
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.dataProtectionRecom}
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**Integration end */}
                {/**start */}
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Operational Risk Management
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Operational Risk Management
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[5].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <div style={{ height: 300, width: "100%" }}>
                                    <ReportHorizontalBarChart
                                      dataNameValue={
                                        reportData?.data?.controlAreaMenuReport
                                          ?.operationalRiskManagement
                                          ?.controlAreaBarChart
                                      }
                                    ></ReportHorizontalBarChart>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <div
                                    style={{
                                      height: 210,
                                      width: "98%",
                                      padding: "1%",
                                    }}
                                  >
                                    <ReportDonutChart
                                      dataNameValue2={
                                        reportData.operationalRiskManagementDonutChart
                                      }
                                    ></ReportDonutChart>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .operationalRiskManagementDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .operationalRiskManagementDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .operationalRiskManagementDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  marginTop: "3%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[6].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaMenuReport
                                            ?.operationalRiskManagement
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData.operationalRiskManagementRecom
                                }
                                areas={false}
                              />
                              <div style={{ marginTop: "200%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/**end */}
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "ServiceNow" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 0px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <li
                        style={{
                          margin: "24px 0px",
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                        }}
                      >
                        Process
                      </li>
                      {reportData?.data?.processControlAreaNames
                        ?.CSMControlArea == "CSM" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            CSM
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.HRSDControlAreaName == "HRSD" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            HRSD
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.IRMControlAreaName == "IRM" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            IRM
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.ITAMControlAreaName == "ITAM" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            ITAM
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.ITOMControlAreaName == "ITOM" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            ITOM
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.ITSMControlAreaName == "ITSM" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            ITSM
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.PortalControlAreaName == "Portal" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            Portal
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.SecOpsControlAreaName == "SecOps" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            SecOps
                          </li>
                        )}
                      {reportData?.data?.processControlAreaNames
                        ?.SPMControlAreaName == "SPM" && (
                          <li
                            style={{
                              margin: "24px 0px",
                              fontFamily: "SFProText-Medium",
                              color: "#000000",
                              fontWeight: 400,
                              fontSize: 15,
                            }}
                          >
                            SPM
                          </li>
                        )}
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>
              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length +
                                      1
                                      }`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                {/** Modularity end */}
                {/** Services Architecture start*/}
                <CommonReportTempForServiceNow
                  controalAreName={"Process"}
                  overallProficiencyIndex={
                    reportData.data.overallProficiencyIndex
                  }
                  peerScore={reportData?.data?.peerScore}
                  controlAreaProficiencyIndexScore={
                    reportData.data.controlAreaProficiencyIndex[4].value
                  }
                  ReportHorizontalBarChart={
                    reportData.data?.controlAreaReport?.process
                      ?.controlAreaBarChart
                  }
                  ReportDonutChart={reportData?.serviceNowProcessDonutChart}
                  overallRecommendations={
                    reportData.data?.controlAreaReport?.process
                      ?.overallRecommendations?.description
                  }
                  threeRecomendations={reportData?.serviceNowProcessRecom}
                />
                {reportData?.data?.processControlAreaNames
                  ?.CSMControlAreaName == "CSM" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"CSM"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.CSMProficiencyIndex
                      }
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.CSM
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.CSMDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.CSM
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.CSMRecom}
                    />
                  )}
                {reportData?.data?.processControlAreaNames
                  ?.HRSDControlAreaName == "HRSD" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"HRSD"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.HRSDProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.HRSD
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.HRSDDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.HRSD
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.HRSDRecom}
                    />
                  )}

                {reportData?.data?.processControlAreaNames
                  ?.IRMControlAreaName == "IRM" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"IRM"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.IRMProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.IRM
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.IRMDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.IRM
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.IRMRecom}
                    />
                  )}

                {reportData?.data?.processControlAreaNames
                  ?.ITAMControlAreaName == "ITAM" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"ITAM"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.ITAMProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.ITAM
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.ITAMDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.ITAM
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.ITAMRecom}
                    />
                  )}
                {reportData?.data?.processControlAreaNames
                  ?.ITOMControlAreaName == "ITOM" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"ITOM"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.ITOMProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.ITOM
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.ITOMDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.ITOM
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.ITOMRecom}
                    />
                  )}

                {reportData?.data?.processControlAreaNames
                  ?.ITSMControlAreaName == "ITSM" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"ITSM"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.ITSMProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.ITSM
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.ITSMDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.ITSM
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.ITSMRecom}
                    />
                  )}

                {reportData?.data?.processControlAreaNames
                  ?.PortalControlAreaName == "Portal" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"Portal"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.PortalProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.portal
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.portalDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.portal
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.portalRecom}
                    />
                  )}

                {reportData?.data?.processControlAreaNames
                  ?.SecOpsControlAreaName == "SecOps" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"SecOps"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.SecOpsProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.secOps
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.secOpsDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.secOps
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.secOpsRecom}
                    />
                  )}

                {reportData?.data?.processControlAreaNames
                  ?.SPMControlAreaName == "SPM" && (
                    <CommonReportTempForServiceNow
                      controalAreName={"SPM"}
                      overallProficiencyIndex={
                        reportData.data.overallProficiencyIndex
                      }
                      controlAreaProficiencyIndexScore={
                        reportData?.data?.processControlAreaProficiencyIndex
                          ?.SPMProficiencyIndex
                      }
                      peerScore={reportData?.data?.peerScore}
                      ReportHorizontalBarChart={
                        reportData.data?.controlAreaReport?.spm
                          ?.controlAreaBarChart
                      }
                      ReportDonutChart={reportData.SPMDonutChart}
                      overallRecommendations={
                        reportData.data?.controlAreaReport?.spm
                          ?.overallRecommendations?.description
                      }
                      threeRecomendations={reportData.SPMRecom}
                    />
                  )}

                <CommonReportTempForServiceNow
                  controalAreName={"Environmental Detail"}
                  overallProficiencyIndex={
                    reportData.data.overallProficiencyIndex
                  }
                  controlAreaProficiencyIndexScore={
                    reportData?.data?.controlAreaProficiencyIndex[0]?.value
                  }
                  peerScore={reportData?.data?.peerScore}
                  ReportHorizontalBarChart={
                    reportData.data?.controlAreaReport?.environmentDetails
                      ?.controlAreaBarChart
                  }
                  ReportDonutChart={
                    reportData.serviceNowEnvironmentDetailsDonutChart
                  }
                  overallRecommendations={
                    reportData.data?.controlAreaReport?.environmentDetails
                      ?.overallRecommendations?.description
                  }
                  threeRecomendations={
                    reportData.serviceNowEnvironmentDetailsRecom
                  }
                />
                <CommonReportTempForServiceNow
                  controalAreName={"Security"}
                  overallProficiencyIndex={
                    reportData.data.overallProficiencyIndex
                  }
                  controlAreaProficiencyIndexScore={
                    reportData?.data?.controlAreaProficiencyIndex[1]?.value
                  }
                  peerScore={reportData?.data?.peerScore}
                  ReportHorizontalBarChart={
                    reportData.data?.controlAreaReport?.security
                      ?.controlAreaBarChart
                  }
                  ReportDonutChart={reportData.serviceNowSecurityDonutChart}
                  overallRecommendations={
                    reportData.data?.controlAreaReport?.security
                      ?.overallRecommendations?.description
                  }
                  threeRecomendations={reportData.serviceNowSecurityRecom}
                />

                <CommonReportTempForServiceNow
                  controalAreName={"Automation"}
                  overallProficiencyIndex={
                    reportData.data.overallProficiencyIndex
                  }
                  controlAreaProficiencyIndexScore={
                    reportData?.data?.controlAreaProficiencyIndex[2]?.value
                  }
                  peerScore={reportData?.data?.peerScore}
                  ReportHorizontalBarChart={
                    reportData.data?.controlAreaReport?.automation
                      ?.controlAreaBarChart
                  }
                  ReportDonutChart={reportData.serviceNowAutomationDonutChart}
                  overallRecommendations={
                    reportData.data?.controlAreaReport?.automation
                      ?.overallRecommendations?.description
                  }
                  threeRecomendations={reportData.serviceNowAutomationRecom}
                />
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%", marginTop: "120%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Integration & Customization
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Integration & Customization
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaProficiencyIndex[3].value}%`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data.controlAreaReport
                                        .integrationCustomization
                                        .controlAreaBarChart
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={
                                      reportData.serviceNowIntegrationAndCustomizationDonutChart
                                    }
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .serviceNowIntegrationAndCustomizationDonutChart[0]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .serviceNowIntegrationAndCustomizationDonutChart[1]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {
                                          reportData
                                            .serviceNowIntegrationAndCustomizationDonutChart[2]
                                            .value
                                        }
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              {/* Code Metrics and Analysis ends here*/}
                              {/* Based on your score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={
                                        reportData.data
                                          .controlAreaProficiencyIndex[3].value
                                      }
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data.controlAreaReport
                                            ?.integrationCustomization
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={
                                  reportData?.serviceNowIntegrationAndCustomizationRecom
                                }
                                areas={false}
                              />
                              {/* Recommendation table ends here */}
                              <div style={{ marginTop: "150%" }}>
                                <ReportTableComponent
                                  dataForGrid={reportData.overallRecommendation}
                                  areas={false}
                                ></ReportTableComponent>
                              </div>
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {reportData?.data?.verticalName == "Salesforce" && (
            <div ref={contentRef}>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      height: "1420px",
                      marginTop: "2%",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                        textAlign: "center",
                        marginTop: "600px",
                      }}
                    >
                      <img
                        src={logoUrl}
                        alt="aven-logo"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                    <h5
                      style={{
                        color: "#050505",
                        fontSize: 28,
                        fontWeight: 600,
                        textAlign: "center",
                        wordSpacing: 8,
                        fontFamily: "SFProText-Medium",
                        paddingTop: "75px",
                      }}
                    >
                      {organizationName}
                    </h5>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 40,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 600,
                          fontFamily: "SFProText-Medium",
                          marginRight: 16,
                        }}
                      >
                        Date |
                        <span
                          style={{
                            color: "#4B4A4A",
                            fontSize: 16,
                            fontWeight: 400,
                          }}
                        >
                          {" "}
                          {dateforReport}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  float: "left",
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              ></div>
              <div style={{ WebkitPrintColorAdjust: "exact" }}>
                <div style={{ width: "100%", backgroundColor: "white" }}>
                  {/*Header starts here */}
                  {/*Header  ends here */}
                  <div
                    style={{
                      width: "100%",
                      float: "left",
                      padding: "60px 0px 110px 0px",
                      marginBottom: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "98%",
                        justifyContent: "center",
                        padding: "2% 1%",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <h5
                        style={{
                          color: "#050505",
                          fontFamily: "SFProText-Medium",
                          fontSize: 22,
                          fontWeight: 600,
                          margin: 0,
                          textAlign: "center",
                          wordSpacing: 8,
                        }}
                      >
                        Reports
                      </h5>
                    </div>
                    <div style={{ margin: "16px 0px" }}>
                      <h1
                        style={{
                          fontFamily: "SFProText-Medium",
                          color: "#000000",
                          fontWeight: 400,
                          fontSize: 15,
                          margin: 0,
                        }}
                      >
                        Overall Summary
                      </h1>
                    </div>
                    <h1
                      style={{
                        fontSize: 16,
                        color: "#151515",
                        fontFamily: "SFProText-Medium",
                        margin: "24px 0px",
                      }}
                      className="underlineCA"
                    >
                      Control Areas
                    </h1>
                    <ul
                      style={{
                        listStyle: "none",
                        marginBottom: 0,
                        paddingLeft: 0,
                      }}
                    >
                      {bindControlArea()}
                    </ul>
                  </div>
                  {/* index ends here */}
                </div>
              </div>

              <div
                style={{ WebkitPrintColorAdjust: "exact", padding: "40px 0px" }}
              >
                <div style={{ WebkitPrintColorAdjust: "exact" }}>
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td>
                            {/*Header starts here */}
                            <div
                              style={{
                                pageBreakInside: "avoid",
                                width: "100%",
                                float: "left",
                              }}
                            >
                              <div
                                style={{
                                  width: "98%",
                                  justifyContent: "center",
                                  padding: "2% 1%",
                                  backgroundColor: "#F5F5F5",
                                }}
                              >
                                <h5
                                  style={{
                                    color: "#050505",
                                    fontFamily: "SFProText-Medium",
                                    fontSize: 22,
                                    fontWeight: 600,
                                    margin: 0,
                                    textAlign: "center",
                                    wordSpacing: 8,
                                  }}
                                >
                                  Overall Summary
                                </h5>
                              </div>
                              {/*Header  ends here */}
                              {/* about score starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "3% 0%",
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    // marginTop: 20
                                  }}
                                >
                                  Know about your score
                                </h2>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#F0FAEF",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Overall Proficiency Index
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.overallProficiencyIndex}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#FFF3E9",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Peer Score
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.peerScore}%`}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    width: "30.3%",
                                    marginRight: "1%",
                                    padding: "2% 1%",
                                    float: "left",
                                    backgroundColor: "#EDFAFE",
                                    borderRadius: 3,
                                  }}
                                >
                                  <h5
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 15,
                                      margin: "2% 0%",
                                      fontWeight: 400,
                                      float: "left",
                                    }}
                                  >
                                    Control Areas
                                  </h5>
                                  <p
                                    style={{
                                      color: "#000000",
                                      fontFamily: "SFProText-Medium",
                                      fontSize: 16,
                                      fontWeight: 600,
                                      margin: "2% 0%",
                                      float: "right",
                                    }}
                                  >
                                    {`${reportData.data.controlAreaNames.length}`}
                                  </p>
                                </div>
                              </div>
                              {/* about score ends here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "1% 0%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingRight: "3%",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                    }}
                                  >
                                    Control areas that influenced your results
                                  </h2>
                                  <ReportHorizontalBarChart
                                    dataNameValue={
                                      reportData.data
                                        .controlAreaProficiencyIndex
                                    }
                                  ></ReportHorizontalBarChart>
                                </div>
                                <div
                                  style={{
                                    width: "47%",
                                    float: "left",
                                    marginTop: "2%",
                                    paddingLeft: "3%",
                                    textAlign: "center",
                                  }}
                                >
                                  <h2
                                    style={{
                                      fontFamily: "SFProText-Medium",
                                      color: "#000000",
                                      fontWeight: 500,
                                      fontSize: 17,
                                      marginBottom: 16,
                                      textAlign: "left",
                                    }}
                                  >
                                    Recommendations based on Impacts
                                  </h2>
                                  <ReportDonutChart
                                    dataNameValue2={reportData.overallDonuchart}
                                  ></ReportDonutChart>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FFA79D",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        High Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.highImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingLeft: "2%",
                                      marginTop: "2%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#FCD269",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Medium Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.mediumImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      width: "48%",
                                      float: "left",
                                      paddingRight: "2%",
                                      marginTop: "3%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        float: "left",
                                        width: "70%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          backgroundColor: "#9FF5A2",
                                          borderRadius: 16,
                                          marginRight: 12,
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                        }}
                                      >
                                        Low Impact
                                      </label>
                                    </div>
                                    <div
                                      style={{ float: "left", width: "30%" }}
                                    >
                                      <label
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          float: "right",
                                        }}
                                      >
                                        {reportData.impact.lowImpactCount}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis starts here*/}
                              <div
                                style={{
                                  width: "100%",
                                  float: "left",
                                  margin: "0% 0%",
                                  pageBreakInside: "avoid",
                                  overflow: "auto", // Adding overflow to handle content overflow
                                }}
                              >
                                <h2
                                  style={{
                                    fontFamily: "SFProText-Medium",
                                    color: "#000000",
                                    fontWeight: 500,
                                    fontSize: 17,
                                    marginBottom: 16,
                                    marginTop: 25,
                                  }}
                                >
                                  Based on your Score
                                </h2>
                                <div style={{ width: "98%" }}>
                                  <div
                                    style={{
                                      float: "left",
                                      marginTop: "2%",
                                      textAlign: "center",
                                      margin: "0% 2% 2% 0%",
                                    }}
                                  >
                                    <ReportGaugeChart
                                      dataValue={reportData.GaugeValue}
                                      Gaugeval={reportData.overallGaugeChart}
                                    ></ReportGaugeChart>
                                  </div>
                                  <div>
                                    {" "}
                                    {/* Adjusted marginTop */}
                                    <ul
                                      style={{ listStyle: "none", padding: 0 }}
                                    >
                                      <li
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 14,
                                          color: "#000000",
                                          marginBottom: 20,
                                          marginTop: 20, // Adjusted marginTop here too
                                          lineHeight: "1.5",
                                        }}
                                      >
                                        {
                                          reportData.data
                                            ?.overallRecommendations
                                            ?.description
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {/* Code Metrics and Analysis ends here*/}
                              {!(
                                reportData?.data
                                  ?.overallCloudPerformanceInsights
                                  ?.salesServiceCloud == -1 &&
                                reportData?.data
                                  ?.overallCloudPerformanceInsights
                                  ?.marketingCloud == -1 &&
                                reportData?.data
                                  ?.overallCloudPerformanceInsights
                                  ?.commerceCloud == -1
                              ) && (
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                      }}
                                    >
                                      Cloud Performance Insights
                                    </h2>
                                    <div
                                      style={{
                                        width: "100%",
                                        float: "left",
                                        marginTop: "1%",
                                        pageBreakInside: "avoid",
                                      }}
                                    >
                                      {reportData?.data
                                        ?.overallCloudPerformanceInsights
                                        ?.salesServiceCloud != -1 && (
                                          <div
                                            style={{
                                              width: "25%",
                                              float: "left",
                                            }}
                                          >
                                            <h5
                                              style={{
                                                fontSize: 14,
                                                fontFamily: "SFProText-Medium",
                                                color: "#454545",
                                                marginBottom: 24,
                                              }}
                                            >
                                              Sales/Service Cloud
                                            </h5>
                                            <p
                                              style={{
                                                fontSize: 20,
                                                fontFamily: "SFProText-Medium",
                                                color: "#000000",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {`${reportData?.data?.overallCloudPerformanceInsights?.salesServiceCloud}%`}
                                            </p>
                                          </div>
                                        )}
                                      {reportData?.data
                                        ?.overallCloudPerformanceInsights
                                        ?.marketingCloud != -1 && (
                                          <div
                                            style={{
                                              width: "25%",
                                              float: "left",
                                            }}
                                          >
                                            <h5
                                              style={{
                                                fontSize: 14,
                                                fontFamily: "SFProText-Medium",
                                                color: "#454545",
                                                marginBottom: 24,
                                              }}
                                            >
                                              Marketing Cloud
                                            </h5>
                                            <p
                                              style={{
                                                fontSize: 20,
                                                fontFamily: "SFProText-Medium",
                                                color: "#000000",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {`${reportData?.data?.overallCloudPerformanceInsights?.marketingCloud}%`}
                                            </p>
                                          </div>
                                        )}
                                      {reportData?.data
                                        ?.overallCloudPerformanceInsights
                                        ?.commerceCloud != -1 && (
                                          <div
                                            style={{
                                              width: "25%",
                                              float: "left",
                                            }}
                                          >
                                            <h5
                                              style={{
                                                fontSize: 14,
                                                fontFamily: "SFProText-Medium",
                                                color: "#454545",
                                                marginBottom: 24,
                                              }}
                                            >
                                              Commerce Cloud
                                            </h5>
                                            <p
                                              style={{
                                                fontSize: 20,
                                                fontFamily: "SFProText-Medium",
                                                color: "#000000",
                                                fontWeight: 600,
                                              }}
                                            >
                                              {`${reportData?.data?.overallCloudPerformanceInsights?.commerceCloud}%`}
                                            </p>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                )}
                              {/* Based on your score starts here*/}
                              {/* Based on your score ends here*/}
                              {/* Recommendation table starts here */}
                              <ThreeReportTableComponent
                                dataForGrid={reportData.overallRecommendation}
                                areas={false}
                                overall={true}
                              />
                              {/* Recommendation table ends here */}
                              {/* Recommendation table starts here */}
                              {/* Recommendation table ends here */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* footer style starts here */}
                    {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
   <div style="text-align: center;">
    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
   </div>
  </div> */}
                    {/* footer style ends here */}
                  </div>
                </div>
                {/** Modularity start */}
                {(reportData?.data?.controlAreaNames.some(
                  (obj: any) => obj.controlAreaName === "Business Process"
                )
                  ? true
                  : false) && (
                    <div style={{ WebkitPrintColorAdjust: "exact" }}>
                      <div style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                {/*Header starts here */}
                                <div
                                  style={{
                                    pageBreakInside: "avoid",
                                    width: "100%",
                                    float: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "98%",
                                      justifyContent: "center",
                                      padding: "2% 1%",
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        color: "#050505",
                                        fontFamily: "SFProText-Medium",
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                        textAlign: "center",
                                        wordSpacing: 8,
                                      }}
                                    >
                                      Business Process
                                    </h5>
                                  </div>
                                  {/*Header  ends here */}
                                  {/* about score starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "3% 0%",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        // marginTop: 20
                                      }}
                                    >
                                      Know about your score
                                    </h2>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#F0FAEF",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Overall Proficiency Index
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.overallProficiencyIndex}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#FFF3E9",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Peer Score
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.peerScore}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#EDFAFE",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Business Process
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data?.selectedControlAreaProficiencyIndex?.businessProcessProficiencyIndex}%`}
                                      </p>
                                    </div>
                                  </div>
                                  {/* about score ends here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                        }}
                                      >
                                        Control areas that influenced your results
                                      </h2>
                                      <ReportHorizontalBarChart
                                        dataNameValue={
                                          reportData.data?.controlAreaReport
                                            ?.businessProcess?.controlAreaBarChart
                                        }
                                      ></ReportHorizontalBarChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                          textAlign: "left",
                                        }}
                                      >
                                        Recommendations based on Impacts
                                      </h2>
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.businessProcessDonutChart
                                        }
                                      ></ReportDonutChart>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FFA79D",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            High Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .businessProcessDonutChart[0]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingLeft: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FCD269",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Medium Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .businessProcessDonutChart[1]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#9FF5A2",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Low Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .businessProcessDonutChart[2]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "0% 0%",
                                      pageBreakInside: "avoid",
                                      overflow: "auto", // Adding overflow to handle content overflow
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        marginTop: 25,
                                      }}
                                    >
                                      Based on your Score
                                    </h2>
                                    <div style={{ width: "100%" }}>
                                      <div
                                        style={{
                                          float: "left",
                                          marginTop: "2%",
                                          textAlign: "center",
                                          margin: "0% 2% 2% 0%",
                                        }}
                                      >
                                        <ReportGaugeChart
                                          dataValue={reportData.GaugeValue}
                                          Gaugeval={
                                            reportData.data
                                              ?.selectedControlAreaProficiencyIndex
                                              ?.businessProcessProficiencyIndex
                                          }
                                        ></ReportGaugeChart>
                                      </div>
                                      <div>
                                        {" "}
                                        {/* Adjusted marginTop */}
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: 0,
                                          }}
                                        >
                                          <li
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                              marginBottom: 20,
                                              marginTop: 20, // Adjusted marginTop here too
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            {
                                              reportData.data.controlAreaReport
                                                ?.businessProcess
                                                ?.overallRecommendations
                                                ?.description
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis ends here*/}
                                  {!(
                                    reportData?.data?.controlAreaReport
                                      ?.businessProcess?.cloudPerformanceInsights
                                      ?.salesServiceCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.businessProcess?.cloudPerformanceInsights
                                      ?.marketingCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.businessProcess?.cloudPerformanceInsights
                                      ?.commerceCloud == -1
                                  ) && (
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "1% 0%",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 500,
                                            fontSize: 17,
                                            marginBottom: 16,
                                          }}
                                        >
                                          Cloud Performance Insights
                                        </h2>
                                        <div
                                          style={{
                                            width: "100%",
                                            float: "left",
                                            marginTop: "1%",
                                            pageBreakInside: "avoid",
                                          }}
                                        >
                                          {reportData?.data?.controlAreaReport
                                            ?.businessProcess
                                            ?.cloudPerformanceInsights
                                            ?.salesServiceCloud == -1 ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{
                                                width: "25%",
                                                float: "left",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: 14,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#454545",
                                                  marginBottom: 24,
                                                }}
                                              >
                                                Sales/Service Cloud
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: 20,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#000000",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {`${reportData?.data?.controlAreaReport?.businessProcess?.cloudPerformanceInsights?.salesServiceCloud}%`}
                                              </p>
                                            </div>
                                          )}
                                          {reportData?.data?.controlAreaReport
                                            ?.businessProcess
                                            ?.cloudPerformanceInsights
                                            ?.marketingCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Marketing Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.businessProcess?.cloudPerformanceInsights?.marketingCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                          {reportData?.data?.controlAreaReport
                                            ?.businessProcess
                                            ?.cloudPerformanceInsights
                                            ?.commerceCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Commerce Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.businessProcess?.cloudPerformanceInsights?.commerceCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {/* Based on your score starts here*/}

                                  {/* Based on your score ends here*/}
                                  {/* Recommendation table starts here */}
                                  <ThreeReportTableComponent
                                    dataForGrid={reportData?.businessProcessRecom}
                                    areas={false}
                                  />
                                  {/* Recommendation table ends here */}
                                  {/* Recommendation table starts here */}
                                  {/* Recommendation table ends here */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* footer style starts here */}
                        {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
                    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
                   <div style="text-align: center;">
                    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
                   </div>
                  </div> */}
                        {/* footer style ends here */}
                      </div>
                    </div>
                  )}

                {/** Modularity end */}
                {/** Services Architecture start*/}
                {(reportData?.data?.controlAreaNames.some(
                  (obj: any) => obj.controlAreaName === "Automation"
                )
                  ? true
                  : false) && (
                    <div style={{ WebkitPrintColorAdjust: "exact" }}>
                      <div style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                {/*Header starts here */}
                                <div
                                  style={{
                                    pageBreakInside: "avoid",
                                    width: "100%",
                                    float: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "98%",
                                      justifyContent: "center",
                                      padding: "2% 1%",
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        color: "#050505",
                                        fontFamily: "SFProText-Medium",
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                        textAlign: "center",
                                        wordSpacing: 8,
                                      }}
                                    >
                                      Automation
                                    </h5>
                                  </div>
                                  {/*Header  ends here */}
                                  {/* about score starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "3% 0%",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        // marginTop: 20
                                      }}
                                    >
                                      Know about your score
                                    </h2>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#F0FAEF",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Overall Proficiency Index
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.overallProficiencyIndex}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#FFF3E9",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Peer Score
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.peerScore}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#EDFAFE",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Automation
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data?.selectedControlAreaProficiencyIndex?.automationProficiencyIndex}%`}
                                      </p>
                                    </div>
                                  </div>
                                  {/* about score ends here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                        }}
                                      >
                                        Control areas that influenced your results
                                      </h2>
                                      <ReportHorizontalBarChart
                                        dataNameValue={
                                          reportData?.data?.controlAreaReport
                                            ?.automation?.controlAreaBarChart
                                        }
                                      ></ReportHorizontalBarChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                          textAlign: "left",
                                        }}
                                      >
                                        Recommendations based on Impacts
                                      </h2>
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.automationDonutChart
                                        }
                                      ></ReportDonutChart>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FFA79D",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            High Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData.automationDonutChart[0]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingLeft: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FCD269",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Medium Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData.automationDonutChart[1]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#9FF5A2",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Low Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData.automationDonutChart[2]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "0% 0%",
                                      pageBreakInside: "avoid",
                                      overflow: "auto", // Adding overflow to handle content overflow
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        marginTop: 25,
                                      }}
                                    >
                                      Based on your Score
                                    </h2>
                                    <div style={{ width: "100%" }}>
                                      <div
                                        style={{
                                          float: "left",
                                          marginTop: "2%",
                                          textAlign: "center",
                                          margin: "0% 2% 2% 0%",
                                        }}
                                      >
                                        <ReportGaugeChart
                                          dataValue={reportData.GaugeValue}
                                          Gaugeval={
                                            reportData.data
                                              ?.selectedControlAreaProficiencyIndex
                                              ?.automationProficiencyIndex
                                          }
                                        ></ReportGaugeChart>
                                      </div>
                                      <div>
                                        {" "}
                                        {/* Adjusted marginTop */}
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: 0,
                                          }}
                                        >
                                          <li
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                              marginBottom: 20,
                                              marginTop: 20, // Adjusted marginTop here too
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            {
                                              reportData.data.controlAreaReport
                                                ?.automation
                                                ?.overallRecommendations
                                                ?.description
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis ends here*/}
                                  {!(
                                    reportData?.data?.controlAreaReport
                                      ?.automation?.cloudPerformanceInsights
                                      ?.salesServiceCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.automation?.cloudPerformanceInsights
                                      ?.marketingCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.automation?.cloudPerformanceInsights
                                      ?.commerceCloud == -1
                                  ) && (
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "1% 0%",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 500,
                                            fontSize: 17,
                                            marginBottom: 16,
                                          }}
                                        >
                                          Cloud Performance Insights
                                        </h2>
                                        <div
                                          style={{
                                            width: "100%",
                                            float: "left",
                                            marginTop: "1%",
                                            pageBreakInside: "avoid",
                                          }}
                                        >
                                          {reportData?.data?.controlAreaReport
                                            ?.automation?.cloudPerformanceInsights
                                            ?.salesServiceCloud == -1 ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{
                                                width: "25%",
                                                float: "left",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: 14,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#454545",
                                                  marginBottom: 24,
                                                }}
                                              >
                                                Sales/Service Cloud
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: 20,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#000000",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {`${reportData?.data?.controlAreaReport?.automation?.cloudPerformanceInsights?.salesServiceCloud}%`}
                                              </p>
                                            </div>
                                          )}
                                          {reportData?.data?.controlAreaReport
                                            ?.automation?.cloudPerformanceInsights
                                            ?.marketingCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Marketing Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.automation?.cloudPerformanceInsights?.marketingCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                          {reportData?.data?.controlAreaReport
                                            ?.automation?.cloudPerformanceInsights
                                            ?.commerceCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Commerce Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.automation?.cloudPerformanceInsights?.commerceCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {/* Based on your score starts here*/}
                                  {/* Based on your score ends here*/}
                                  {/* Recommendation table starts here */}
                                  <ThreeReportTableComponent
                                    dataForGrid={reportData?.automationRecom}
                                    areas={false}
                                  />
                                  {/* Recommendation table ends here */}
                                  {/* Recommendation table starts here */}
                                  {/* Recommendation table ends here */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* footer style starts here */}
                        {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
                    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
                   <div style="text-align: center;">
                    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
                   </div>
                  </div> */}
                        {/* footer style ends here */}
                      </div>
                    </div>
                  )}

                {/** Services Architecture end*/}
                {/**Event Based Architecture start */}
                {(reportData?.data?.controlAreaNames.some(
                  (obj: any) => obj.controlAreaName === "Environment Details"
                )
                  ? true
                  : false) && (
                    <div style={{ WebkitPrintColorAdjust: "exact" }}>
                      <div style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                {/*Header starts here */}
                                <div
                                  style={{
                                    pageBreakInside: "avoid",
                                    width: "100%",
                                    float: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "98%",
                                      justifyContent: "center",
                                      padding: "2% 1%",
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        color: "#050505",
                                        fontFamily: "SFProText-Medium",
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                        textAlign: "center",
                                        wordSpacing: 8,
                                      }}
                                    >
                                      Environment Details
                                    </h5>
                                  </div>
                                  {/*Header  ends here */}
                                  {/* about score starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "3% 0%",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        // marginTop: 20
                                      }}
                                    >
                                      Know about your score
                                    </h2>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#F0FAEF",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Overall Proficiency Index
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.overallProficiencyIndex}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#FFF3E9",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Peer Score
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.peerScore}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#EDFAFE",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Environment Details
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data?.selectedControlAreaProficiencyIndex?.environmentDetailsProficiencyIndex}%`}
                                      </p>
                                    </div>
                                  </div>
                                  {/* about score ends here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                        }}
                                      >
                                        Control areas that influenced your results
                                      </h2>
                                      <ReportHorizontalBarChart
                                        dataNameValue={
                                          reportData.data.controlAreaReport
                                            .environmentDetails
                                            .controlAreaBarChart
                                        }
                                      ></ReportHorizontalBarChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                          textAlign: "left",
                                        }}
                                      >
                                        Recommendations based on Impacts
                                      </h2>
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.environmentDetailsDonutChart
                                        }
                                      ></ReportDonutChart>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FFA79D",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            High Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .environmentDetailsDonutChart[0]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingLeft: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FCD269",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Medium Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .environmentDetailsDonutChart[1]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#9FF5A2",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Low Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .environmentDetailsDonutChart[2]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "0% 0%",
                                      pageBreakInside: "avoid",
                                      overflow: "auto", // Adding overflow to handle content overflow
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        marginTop: 25,
                                      }}
                                    >
                                      Based on your Score
                                    </h2>
                                    <div style={{ width: "100%" }}>
                                      <div
                                        style={{
                                          float: "left",
                                          marginTop: "2%",
                                          textAlign: "center",
                                          margin: "0% 2% 2% 0%",
                                        }}
                                      >
                                        <ReportGaugeChart
                                          dataValue={reportData.GaugeValue}
                                          Gaugeval={
                                            reportData.data
                                              ?.selectedControlAreaProficiencyIndex
                                              ?.environmentDetailsProficiencyIndex
                                          }
                                        ></ReportGaugeChart>
                                      </div>
                                      <div>
                                        {" "}
                                        {/* Adjusted marginTop */}
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: 0,
                                          }}
                                        >
                                          <li
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                              marginBottom: 20,
                                              marginTop: 20, // Adjusted marginTop here too
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            {
                                              reportData.data.controlAreaReport
                                                ?.environmentDetails
                                                ?.overallRecommendations
                                                ?.description
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis ends here*/}
                                  {!(
                                    reportData?.data?.controlAreaReport
                                      ?.environmentDetails
                                      ?.cloudPerformanceInsights
                                      ?.salesServiceCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.environmentDetails
                                      ?.cloudPerformanceInsights
                                      ?.marketingCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.environmentDetails
                                      ?.cloudPerformanceInsights?.commerceCloud ==
                                    -1
                                  ) && (
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "1% 0%",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 500,
                                            fontSize: 17,
                                            marginBottom: 16,
                                          }}
                                        >
                                          Cloud Performance Insights
                                        </h2>
                                        <div
                                          style={{
                                            width: "100%",
                                            float: "left",
                                            marginTop: "1%",
                                            pageBreakInside: "avoid",
                                          }}
                                        >
                                          {reportData?.data?.controlAreaReport
                                            ?.environmentDetails
                                            ?.cloudPerformanceInsights
                                            ?.salesServiceCloud == -1 ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{
                                                width: "25%",
                                                float: "left",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: 14,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#454545",
                                                  marginBottom: 24,
                                                }}
                                              >
                                                Sales/Service Cloud
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: 20,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#000000",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {`${reportData?.data?.controlAreaReport?.environmentDetails?.cloudPerformanceInsights?.salesServiceCloud}%`}
                                              </p>
                                            </div>
                                          )}
                                          {reportData?.data?.controlAreaReport
                                            ?.environmentDetails
                                            ?.cloudPerformanceInsights
                                            ?.marketingCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Marketing Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.environmentDetails?.cloudPerformanceInsights?.marketingCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                          {reportData?.data?.controlAreaReport
                                            ?.environmentDetails
                                            ?.cloudPerformanceInsights
                                            ?.commerceCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Commerce Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.environmentDetails?.cloudPerformanceInsights?.commerceCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {/* Based on your score starts here*/}

                                  {/* Based on your score ends here*/}
                                  {/* Recommendation table starts here */}
                                  <ThreeReportTableComponent
                                    dataForGrid={
                                      reportData?.environmentDetailsRecom
                                    }
                                    areas={false}
                                  />
                                  {/* Recommendation table ends here */}
                                  {/* Recommendation table starts here */}
                                  {/* Recommendation table ends here */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* footer style starts here */}
                        {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
                    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
                   <div style="text-align: center;">
                    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
                   </div>
                  </div> */}
                        {/* footer style ends here */}
                      </div>
                    </div>
                  )}

                {/**Event Based Architecture start */}
                {/**logging start */}
                {(reportData?.data?.controlAreaNames.some(
                  (obj: any) => obj.controlAreaName === "Security"
                )
                  ? true
                  : false) && (
                    <div style={{ WebkitPrintColorAdjust: "exact" }}>
                      <div style={{ width: "100%" }}>
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td>
                                {/*Header starts here */}
                                <div
                                  style={{
                                    pageBreakInside: "avoid",
                                    width: "100%",
                                    float: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "98%",
                                      justifyContent: "center",
                                      padding: "2% 1%",
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        color: "#050505",
                                        fontFamily: "SFProText-Medium",
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                        textAlign: "center",
                                        wordSpacing: 8,
                                      }}
                                    >
                                      Security
                                    </h5>
                                  </div>
                                  {/*Header  ends here */}
                                  {/* about score starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "3% 0%",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        // marginTop: 20
                                      }}
                                    >
                                      Know about your score
                                    </h2>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#F0FAEF",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Overall Proficiency Index
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.overallProficiencyIndex}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#FFF3E9",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Peer Score
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.peerScore}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#EDFAFE",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Security
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data?.selectedControlAreaProficiencyIndex?.securityProficiencyIndex}%`}
                                      </p>
                                    </div>
                                  </div>
                                  {/* about score ends here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                        }}
                                      >
                                        Control areas that influenced your results
                                      </h2>
                                      <ReportHorizontalBarChart
                                        dataNameValue={
                                          reportData.data.controlAreaReport
                                            .security.controlAreaBarChart
                                        }
                                      ></ReportHorizontalBarChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                          textAlign: "left",
                                        }}
                                      >
                                        Recommendations based on Impacts
                                      </h2>
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.salesforceSecurityDonutChart
                                        }
                                      ></ReportDonutChart>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FFA79D",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            High Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .salesforceSecurityDonutChart[0]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingLeft: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FCD269",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Medium Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .salesforceSecurityDonutChart[1]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#9FF5A2",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Low Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .salesforceSecurityDonutChart[2]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "0% 0%",
                                      pageBreakInside: "avoid",
                                      overflow: "auto", // Adding overflow to handle content overflow
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        marginTop: 25,
                                      }}
                                    >
                                      Based on your Score
                                    </h2>
                                    <div style={{ width: "100%" }}>
                                      <div
                                        style={{
                                          float: "left",
                                          marginTop: "2%",
                                          textAlign: "center",
                                          margin: "0% 2% 2% 0%",
                                        }}
                                      >
                                        <ReportGaugeChart
                                          dataValue={reportData.GaugeValue}
                                          Gaugeval={
                                            reportData.data
                                              ?.selectedControlAreaProficiencyIndex
                                              ?.securityProficiencyIndex
                                          }
                                        ></ReportGaugeChart>
                                      </div>
                                      <div>
                                        {" "}
                                        {/* Adjusted marginTop */}
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: 0,
                                          }}
                                        >
                                          <li
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                              marginBottom: 20,
                                              marginTop: 20, // Adjusted marginTop here too
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            {
                                              reportData.data.controlAreaReport
                                                ?.security?.overallRecommendations
                                                ?.description
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis ends here*/}
                                  {!(
                                    reportData?.data?.controlAreaReport?.security
                                      ?.cloudPerformanceInsights
                                      ?.salesServiceCloud == -1 &&
                                    reportData?.data?.controlAreaReport?.security
                                      ?.cloudPerformanceInsights
                                      ?.marketingCloud == -1 &&
                                    reportData?.data?.controlAreaReport?.security
                                      ?.cloudPerformanceInsights?.commerceCloud ==
                                    -1
                                  ) && (
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "1% 0%",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 500,
                                            fontSize: 17,
                                            marginBottom: 16,
                                          }}
                                        >
                                          Cloud Performance Insights
                                        </h2>
                                        <div
                                          style={{
                                            width: "100%",
                                            float: "left",
                                            marginTop: "1%",
                                            pageBreakInside: "avoid",
                                          }}
                                        >
                                          {reportData?.data?.controlAreaReport
                                            ?.security?.cloudPerformanceInsights
                                            ?.salesServiceCloud == -1 ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{
                                                width: "25%",
                                                float: "left",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: 14,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#454545",
                                                  marginBottom: 24,
                                                }}
                                              >
                                                Sales/Service Cloud
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: 20,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#000000",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {`${reportData?.data?.controlAreaReport?.security?.cloudPerformanceInsights?.salesServiceCloud}%`}
                                              </p>
                                            </div>
                                          )}
                                          {reportData?.data?.controlAreaReport
                                            ?.security?.cloudPerformanceInsights
                                            ?.marketingCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Marketing Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.security?.cloudPerformanceInsights?.marketingCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                          {reportData?.data?.controlAreaReport
                                            ?.security?.cloudPerformanceInsights
                                            ?.commerceCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Commerce Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.security?.cloudPerformanceInsights?.commerceCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {/* Based on your score starts here*/}
                                  {/* Based on your score ends here*/}
                                  {/* Recommendation table starts here */}
                                  <ThreeReportTableComponent
                                    dataForGrid={
                                      reportData?.salesforceSecurityRecom
                                    }
                                    areas={false}
                                  />
                                  {/* Recommendation table ends here */}
                                  {/* Recommendation table starts here */}
                                  {/* Recommendation table ends here */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* footer style starts here */}
                        {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
  <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
 <div style="text-align: center;">
  <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
 </div>
</div> */}
                        {/* footer style ends here */}
                      </div>
                    </div>
                  )}

                {/**logging end */}
                {/**Integration start */}
                {(reportData?.data?.controlAreaNames.some(
                  (obj: any) =>
                    obj.controlAreaName === "Integration & Customization"
                )
                  ? true
                  : false) && (
                    <div style={{ WebkitPrintColorAdjust: "exact" }}>
                      <div style={{ width: "100%" }}>
                        <table style={{ width: "100%", marginTop: "120%" }}>
                          <tbody>
                            <tr>
                              <td>
                                {/*Header starts here */}
                                <div
                                  style={{
                                    pageBreakInside: "avoid",
                                    width: "100%",
                                    float: "left",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "98%",
                                      justifyContent: "center",
                                      padding: "2% 1%",
                                      backgroundColor: "#F5F5F5",
                                    }}
                                  >
                                    <h5
                                      style={{
                                        color: "#050505",
                                        fontFamily: "SFProText-Medium",
                                        fontSize: 22,
                                        fontWeight: 600,
                                        margin: 0,
                                        textAlign: "center",
                                        wordSpacing: 8,
                                      }}
                                    >
                                      Integration & Customization
                                    </h5>
                                  </div>
                                  {/*Header  ends here */}
                                  {/* about score starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "3% 0%",
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        // marginTop: 20
                                      }}
                                    >
                                      Know about your score
                                    </h2>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#F0FAEF",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Overall Proficiency Index
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.overallProficiencyIndex}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#FFF3E9",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Peer Score
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data.peerScore}%`}
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        width: "30.3%",
                                        marginRight: "1%",
                                        padding: "2% 1%",
                                        float: "left",
                                        backgroundColor: "#EDFAFE",
                                        borderRadius: 3,
                                      }}
                                    >
                                      <h5
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 15,
                                          margin: "2% 0%",
                                          fontWeight: 400,
                                          float: "left",
                                        }}
                                      >
                                        Integration & Customization
                                      </h5>
                                      <p
                                        style={{
                                          color: "#000000",
                                          fontFamily: "SFProText-Medium",
                                          fontSize: 16,
                                          fontWeight: 600,
                                          margin: "2% 0%",
                                          float: "right",
                                        }}
                                      >
                                        {`${reportData.data?.selectedControlAreaProficiencyIndex?.integrationCustomizationProficiencyIndex}%`}
                                      </p>
                                    </div>
                                  </div>
                                  {/* about score ends here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "1% 0%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingRight: "3%",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                        }}
                                      >
                                        Control areas that influenced your results
                                      </h2>
                                      <ReportHorizontalBarChart
                                        dataNameValue={
                                          reportData.data.controlAreaReport
                                            .integrationAndCustomization
                                            .controlAreaBarChart
                                        }
                                      ></ReportHorizontalBarChart>
                                    </div>
                                    <div
                                      style={{
                                        width: "47%",
                                        float: "left",
                                        marginTop: "2%",
                                        paddingLeft: "3%",
                                        textAlign: "center",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontFamily: "SFProText-Medium",
                                          color: "#000000",
                                          fontWeight: 500,
                                          fontSize: 17,
                                          marginBottom: 16,
                                          textAlign: "left",
                                        }}
                                      >
                                        Recommendations based on Impacts
                                      </h2>
                                      <ReportDonutChart
                                        dataNameValue2={
                                          reportData.integrationAndCustomizationDonutChart
                                        }
                                      ></ReportDonutChart>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FFA79D",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            High Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .integrationAndCustomizationDonutChart[0]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingLeft: "2%",
                                          marginTop: "2%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#FCD269",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Medium Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .integrationAndCustomizationDonutChart[1]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "48%",
                                          float: "left",
                                          paddingRight: "2%",
                                          marginTop: "3%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            float: "left",
                                            width: "70%",
                                            textAlign: "left",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "inline-block",
                                              width: 16,
                                              height: 16,
                                              backgroundColor: "#9FF5A2",
                                              borderRadius: 16,
                                              marginRight: 12,
                                            }}
                                          />
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                            }}
                                          >
                                            Low Impact
                                          </label>
                                        </div>
                                        <div
                                          style={{ float: "left", width: "30%" }}
                                        >
                                          <label
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 16,
                                              fontWeight: 600,
                                              float: "right",
                                            }}
                                          >
                                            {
                                              reportData
                                                .integrationAndCustomizationDonutChart[2]
                                                .value
                                            }
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis starts here*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      float: "left",
                                      margin: "0% 0%",
                                      pageBreakInside: "avoid",
                                      overflow: "auto", // Adding overflow to handle content overflow
                                    }}
                                  >
                                    <h2
                                      style={{
                                        fontFamily: "SFProText-Medium",
                                        color: "#000000",
                                        fontWeight: 500,
                                        fontSize: 17,
                                        marginBottom: 16,
                                        marginTop: 25,
                                      }}
                                    >
                                      Based on your Score
                                    </h2>
                                    <div style={{ width: "100%" }}>
                                      <div
                                        style={{
                                          float: "left",
                                          marginTop: "2%",
                                          textAlign: "center",
                                          margin: "0% 2% 2% 0%",
                                        }}
                                      >
                                        <ReportGaugeChart
                                          dataValue={reportData.GaugeValue}
                                          Gaugeval={
                                            reportData.data
                                              ?.selectedControlAreaProficiencyIndex
                                              ?.integrationCustomizationProficiencyIndex
                                          }
                                        ></ReportGaugeChart>
                                      </div>
                                      <div>
                                        {" "}
                                        {/* Adjusted marginTop */}
                                        <ul
                                          style={{
                                            listStyle: "none",
                                            padding: 0,
                                          }}
                                        >
                                          <li
                                            style={{
                                              fontFamily: "SFProText-Medium",
                                              fontSize: 14,
                                              color: "#000000",
                                              marginBottom: 20,
                                              marginTop: 20, // Adjusted marginTop here too
                                              lineHeight: "1.5",
                                            }}
                                          >
                                            {
                                              reportData.data.controlAreaReport
                                                ?.integrationAndCustomization
                                                ?.overallRecommendations
                                                ?.description
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Code Metrics and Analysis ends here*/}
                                  {!(
                                    reportData?.data?.controlAreaReport
                                      ?.integrationAndCustomization
                                      ?.cloudPerformanceInsights
                                      ?.salesServiceCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.integrationAndCustomization
                                      ?.cloudPerformanceInsights
                                      ?.marketingCloud == -1 &&
                                    reportData?.data?.controlAreaReport
                                      ?.integrationAndCustomization
                                      ?.cloudPerformanceInsights?.commerceCloud ==
                                    -1
                                  ) && (
                                      <div
                                        style={{
                                          width: "100%",
                                          float: "left",
                                          margin: "1% 0%",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            fontFamily: "SFProText-Medium",
                                            color: "#000000",
                                            fontWeight: 500,
                                            fontSize: 17,
                                            marginBottom: 16,
                                          }}
                                        >
                                          Cloud Performance Insights
                                        </h2>
                                        <div
                                          style={{
                                            width: "100%",
                                            float: "left",
                                            marginTop: "1%",
                                            pageBreakInside: "avoid",
                                          }}
                                        >
                                          {reportData?.data?.controlAreaReport
                                            ?.integrationAndCustomization
                                            ?.cloudPerformanceInsights
                                            ?.salesServiceCloud == -1 ? (
                                            ""
                                          ) : (
                                            <div
                                              style={{
                                                width: "25%",
                                                float: "left",
                                              }}
                                            >
                                              <h5
                                                style={{
                                                  fontSize: 14,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#454545",
                                                  marginBottom: 24,
                                                }}
                                              >
                                                Sales/Service Cloud
                                              </h5>
                                              <p
                                                style={{
                                                  fontSize: 20,
                                                  fontFamily: "SFProText-Medium",
                                                  color: "#000000",
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {`${reportData?.data?.controlAreaReport?.integrationAndCustomization?.cloudPerformanceInsights?.salesServiceCloud}%`}
                                              </p>
                                            </div>
                                          )}
                                          {reportData?.data?.controlAreaReport
                                            ?.integrationAndCustomization
                                            ?.cloudPerformanceInsights
                                            ?.marketingCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Marketing Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.integrationAndCustomization?.cloudPerformanceInsights?.marketingCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                          {reportData?.data?.controlAreaReport
                                            ?.integrationAndCustomization
                                            ?.cloudPerformanceInsights
                                            ?.commerceCloud != -1 && (
                                              <div
                                                style={{
                                                  width: "25%",
                                                  float: "left",
                                                }}
                                              >
                                                <h5
                                                  style={{
                                                    fontSize: 14,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#454545",
                                                    marginBottom: 24,
                                                  }}
                                                >
                                                  Commerce Cloud
                                                </h5>
                                                <p
                                                  style={{
                                                    fontSize: 20,
                                                    fontFamily: "SFProText-Medium",
                                                    color: "#000000",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {`${reportData?.data?.controlAreaReport?.integrationAndCustomization?.cloudPerformanceInsights?.commerceCloud}%`}
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {/* Based on your score starts here*/}

                                  {/* Based on your score ends here*/}
                                  {/* Recommendation table starts here */}
                                  <ThreeReportTableComponent
                                    dataForGrid={
                                      reportData?.integrationAndCustomizationRecom
                                    }
                                    areas={false}
                                  />
                                  {/* Recommendation table ends here */}
                                  <div style={{ marginTop: "150%" }}>
                                    <ReportTableComponent
                                      dataForGrid={
                                        reportData.overallRecommendation
                                      }
                                      areas={false}
                                    ></ReportTableComponent>
                                  </div>
                                  {/* Recommendation table starts here */}
                                  {/* Recommendation table ends here */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* footer style starts here */}
                        {/* <div style="width: 100%; float: left; position: fixed; bottom: 0px; background-color: white; " class="body-25vh">
                    <img src="images/footer-design.png" alt="footer design" style="height: 32px; width: 100%; margin-bottom: 10px;">
                   <div style="text-align: center;">
                    <img src="images/zeb-logo.svg" alt="zeb-logo" style="width: 88px; height: 32px;">
                   </div>
                  </div> */}
                        {/* footer style ends here */}
                      </div>
                    </div>
                  )}
                {/**Integration end */}
              </div>
            </div>
          )}
        </div >
      </div >
      <Loader isLoading={isLoading} />
      {hideToast ? <></> : <ToastComponent name={toastProp} />}
    </>
  );
}
