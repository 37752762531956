import React, { useEffect, useState } from 'react';
import { getInventoryDetails, postInventoryDetails, deleteInventoryDetails } from '../../service/QuestionnaireApi';

interface CurrentDataEnvironmentInventoryProps {
    reportData: any;
    setIsLoading: any;
}
interface InventoryItem {
    objectId: string;
    objectName: string;
    entityDetails: Array<{
        entityId: string;
        objectType: string;
        entityCount: string;
    }>;
}
interface FormValidationMsg {
    objectName: string;
    objectType: string;
    entityCount: string;
}

//PS_CurrentEnvironmentInventory_03 - PS_CurrentEnvironmentInventory_20
const CurrentDataEnvironmentInventory: React.FC<CurrentDataEnvironmentInventoryProps> = ({ reportData, setIsLoading }) => {
    // State hooks as defined in PS_05 - PS_25
    // const initialInventoryData = reportData?.inventoryData[0] || [];
    const [inventoryData, setInventoryData] = useState<InventoryItem[]>([]); const [objectName, setObjectName] = useState('');
    const [isObjectFieldDisabled, setIsObjectFieldDisabled] = useState(false);
    const [error, setError] = useState('');
    const [showSaveMessage, setShowSaveMessage] = useState<boolean>(false);
    const [showToasterFailedPopup, setShowToasterFailedPopup] = useState(false);
    const [deletingItemId, setDeletingItemId] = useState<string | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showAddNewObjectModal, setShowAddNewObjectModal] = useState(false);
    const [newObjectName, setNewObjectName] = useState('');
    const [newObjectType, setNewObjectType] = useState('');
    const [newEntityCount, setNewEntityCount] = useState('');
    const [isAddingNewObject, setIsAddingNewObject] = useState(false);
    const [isAddingNewObjectType, setIsAddingNewObjectType] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [entityId, setentityId] = useState('');
    const [formValidationMsg, setFormValidationMsg] = useState<FormValidationMsg>({
        objectName: '',
        objectType: '',
        entityCount: '',
    });
    const [formTouched, setFormTouched] = useState({
        objectName: false,
        objectType: false,
        entityCount: false,
    });

    const charRegex = /^(?=.*[A-Za-z])[A-Za-z0-9\s]*$/;
    const numberRegex = /^\d{1,5}(\.\d{1,2})?$/

    //PS_CurrentEnvironmentInventory_21
    useEffect(() => {
        getInitialInventoryData();
    }, []);

    //PS_CurrentEnvironmentInventory_22 - PS_CurrentEnvironmentInventory_38
    const getInitialInventoryData = async () => {
        setIsLoading(true);
        const payload = {
            organizationId: reportData.organizationId,
            verticalId: reportData.verticalId,
            projectId: reportData.projectId,
            connectorId: reportData.connectors.find((c: any) => c.connectorName === "Current Data Environment Inventory")?.connectorId,
        };
        try {
            const inventoryResponse = await getInventoryDetails(payload);
            if (inventoryResponse.status === 200) {
                setInventoryData(inventoryResponse.data || []);
            }

        } catch (error) {
            setShowToasterFailedPopup(true)
            console.error('Failed to fetch inventory details:', error);
        } finally {
            setIsLoading(false);
            setShowToasterFailedPopup(false)
        }
    };

    //PS_CurrentEnvironmentInventory_105 - 107
    const validateInput = (field: keyof FormValidationMsg, value: string) => {
        let errorMessage = '';
        switch (field) {
            case 'objectName':
                if (!value.trim()) {
                    errorMessage = 'Please Enter Object Name';
                } else if (!charRegex.test(value)) {
                    errorMessage = 'Enter a valid Object Name';
                }
                break;
            case 'objectType':
                if (value.trim() && !charRegex.test(value)) {
                    errorMessage = 'Enter a valid Object Type';
                }
                break;
            case 'entityCount':
                if (!value.trim()) {
                    errorMessage = 'Please Enter Entity Count';
                } else if (!numberRegex.test(value)) {
                    errorMessage = 'Enter a valid Entity Count';
                }
                break;
        }
        return errorMessage;
    };

    //PS_CurrentEnvironmentInventory_100 - PS_CurrentEnvironmentInventory_104
    const handleInputChange = (field: keyof FormValidationMsg, value: string) => {
        const errorMessage = validateInput(field, value);
        setFormValidationMsg(prev => ({ ...prev, [field]: errorMessage }));
        setFormTouched(prev => ({ ...prev, [field]: true }));

        switch (field) {
            case 'objectName':
                setNewObjectName(value);
                break;
            case 'objectType':
                setNewObjectType(value);
                break;
            case 'entityCount':
                setNewEntityCount(value);
                break;
        }
    };


    //PS_CurrentEnvironmentInventory_88 - PS_CurrentEnvironmentInventory_94
    const handleAddObject = () => {
        setIsAddingNewObject(true);
        setIsAddingNewObjectType(false)
        setIsObjectFieldDisabled(false);
        setNewObjectName('');
        setNewObjectType('');
        setNewEntityCount('');
        resetFormState();
    };

    //PS_CurrentEnvironmentInventory_80 - PS_CurrentEnvironmentInventory_86
    const handleAddObjectType = (objectName: string) => {
        setIsAddingNewObject(false);
        setIsAddingNewObjectType(true)
        setIsObjectFieldDisabled(true);
        setNewObjectName(objectName);
        setNewObjectType('');
        setNewEntityCount('');
        resetFormState();
    };

    //PS_CurrentEnvironmentInventory_72 - PS_CurrentEnvironmentInventory_78
    const handleOpenEditModal = (entity: any) => {
        setIsAddingNewObject(false);
        setIsAddingNewObjectType(false)
        setIsObjectFieldDisabled(false);
        setNewObjectName(entity.objectName);
        setNewObjectType(entity.objectType);
        setNewEntityCount(entity.entityCount.toString());
        setentityId(entity.entityId);
        resetFormState();
    };

    //PS_CurrentEnvironmentInventory_67 - PS_CurrentEnvironmentInventory_70
    const resetFormState = () => {
        setFormValidationMsg({ objectName: '', objectType: '', entityCount: '' });
        setFormTouched({ objectName: false, objectType: false, entityCount: false });
        setShowAddNewObjectModal(true);
    };

    //PS_CurrentEnvironmentInventory_96 - PS_CurrentEnvironmentInventory_99
    const handleCloseModal = () => {
        setShowAddNewObjectModal(false);
        setNewObjectName('');
        setNewObjectType('');
        setNewEntityCount('');
        setIsAddingNewObject(false);
        setFormValidationMsg({ objectName: '', objectType: '', entityCount: '' });
        setFormTouched({ objectName: false, objectType: false, entityCount: false });
    };

    //PS_CurrentEnvironmentInventory_40 - PS_CurrentEnvironmentInventory_66
    const handleSaveNewObject = async () => {
        const touchedFields = { objectName: true, objectType: true, entityCount: true };
        setFormTouched(touchedFields);

        const newValidationMsg = {
            objectName: validateInput('objectName', newObjectName),
            objectType: validateInput('objectType', newObjectType),
            entityCount: validateInput('entityCount', newEntityCount),
        };

        setFormValidationMsg(newValidationMsg);

        if (Object.values(newValidationMsg).every(msg => msg === '')) {
            let payload;

            if (isAddingNewObject) {
                payload = {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    connectorId: reportData.connectors.find((c: any) => c.connectorName === "Current Data Environment Inventory")?.connectorId,
                    connectorName: 'Current Data Environment Inventory',
                    objectId: "",
                    objectName: newObjectName,
                    objectType: newObjectType,
                    entityCount: parseInt(newEntityCount),
                };

            } 
            else if(isAddingNewObjectType){
                const correspondingObject = inventoryData.find((item: InventoryItem) =>
                    item.objectName === newObjectName
                );

                 if (!correspondingObject) {
                    console.error('Corresponding object not found');
                    return;
                }
                payload = {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    connectorId: reportData.connectors.find((c: any) => c.connectorName === "Current Data Environment Inventory")?.connectorId,
                    connectorName: 'Current Data Environment Inventory',
                    objectId: correspondingObject.objectId,
                    objectName: newObjectName,
                    objectType: newObjectType,
                    entityCount: parseInt(newEntityCount),

                }

            }
            
            else {
                const correspondingObject = inventoryData.find((item: InventoryItem) =>
                    item.entityDetails.some((entity: any) => entity.entityId === entityId)
                );

                if (!correspondingObject) {
                    console.error('Corresponding object not found');
                    return;
                }

                payload = {
                    organizationId: reportData.organizationId,
                    verticalId: reportData.verticalId,
                    projectId: reportData.projectId,
                    connectorId: reportData.connectors.find((c: any) => c.connectorName === "Current Data Environment Inventory")?.connectorId,
                    connectorName: 'Current Data Environment Inventory',
                    objectId: correspondingObject.objectId,
                    objectName: newObjectName,
                    entityId:entityId,
                    objectType: newObjectType,
                    entityCount: parseInt(newEntityCount),
                };
            }
            handleCloseModal();
            setIsLoading(true)

            try {
                const postInventoryDetailsAPIResponse = await postInventoryDetails(payload);
                if (postInventoryDetailsAPIResponse.status === 200) {
                    getInitialInventoryData();
                    setIsLoading(false)
                    setShowSaveMessage(true)
                    setTimeout(() => {
                        setShowSaveMessage(false);
                    }, 3000);
                }
            } catch (error) {
                setShowToasterFailedPopup(true)
                console.error('Error saving inventory data:', error);
                setError('An error occurred while saving inventory data.');
                setTimeout(() => {
                    setShowToasterFailedPopup(false);
                }, 3000);
            } finally {
                setIsLoading(false)
            }
        }
    };
    const handleDelete = (entity: any) => {
        setDeletingItemId(entity.entityId);
        setShowDeleteModal(true);
    };
    const closeModal = () => {
        setShowDeleteModal(false);
        setDeletingItemId(null)
    };

    //PS_CurrentEnvironmentInventory_114 - 123
    const confirmDeleteEntity = async () => {
        setShowDeleteModal(false)
        setIsLoading(true);
        const payload = {
            entityId: deletingItemId,
        };
        try {
            const response = await deleteInventoryDetails(payload);
            if (response.status === 200) {
                getInitialInventoryData();
                setIsLoading(false);
                closeModal();
                setShowDeletePopup(true);
                const updatedData = inventoryData.filter((item: any) => item.objectId !== deletingItemId);
                setInventoryData(updatedData);
                setTimeout(() => {
                    setShowDeletePopup(false);
                }, 3000);
            }
        } catch (error) {
            setShowToasterFailedPopup(true)
            console.error('Error deleting inventory data:', error);
            setTimeout(() => {
                setShowToasterFailedPopup(false);
            }, 3000);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="" id="bussiness-oerview" role="tabpanel">
            <div className="col-lg-12 col-md-12 col-12">
                <div className="ps-lg-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="font-18 font-semibold color-black mb-5">
                            Current Data Environment Inventory
                        </h3>
                        <button type="button" className="btn file-upload-outline d-flex align-items-center gap-2 font-12 font-semibold mt-2" onClick={handleAddObject}>Add Object</button>
                    </div>
                    <div className="row">
                        {inventoryData.map((item: any, index: number) => (
                            <div key={index} className="col-md-4 pb-3 pe-3 ps-0">
                                <div className="aws-card env-inventory-card d-flex justify-content-between align-items-center">
                                    <span className="d-flex align-items-center gap-3">
                                        <img src={`../images/${item.objectName.toLowerCase()}-icon.svg`} alt={`${item.objectName}-icon`}
                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                                e.currentTarget.src = '../images/object-icon.svg';
                                            }} />
                                        <span className="font-14 font-semibold">{item.objectName}</span>
                                        <div className="dropdown">
                                            <button className="link-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="../images/link-right-icon.svg" alt="link-right-icon" />
                                            </button>
                                            <ul className="dropdown-menu inventory-dropdown p-3">
                                                {item?.entityDetails?.map((entity: any, entityIndex: number) => (
                                                    entity.objectType ? (
                                                        <li key={entityIndex}>
                                                            <a className="dropdown-item d-flex align-items-center justify-content-between px-1 py-2" href="#">
                                                                <span className="font-regular font-12 text-color-1">{entity.objectType}</span>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="font-12 font-bold text-color-1 me-3">{entity.entityCount}</span>
                                                                    <button className="action-btn btn py-0 px-1" onClick={() => handleOpenEditModal({ ...entity, objectName: item.objectName })}>
                                                                        <img className="mb-1" src="images/editIcon.svg" alt="edit" />
                                                                    </button>
                                                                    <button className="action-btn btn py-0 px-1" onClick={() => handleDelete(entity)}>
                                                                        <img className="mb-1" src="images/deleteIcon.svg" alt="delete" />
                                                                    </button>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    ) : <></>
                                                ))}
                                                <a className="d-flex align-items-center justify-content-between px-1 pt-2 text-decoration-none" onClick={() => handleAddObjectType(item.objectName)}>
                                                    <span className="font-regular font-12 link-blue">Add Object Type</span>
                                                </a>
                                            </ul>
                                        </div>
                                    </span>
                                    <span className="font-24 font-semibold">
                                        {item?.entityDetails?.reduce((sum: number, entity: any) => {
                                            const count = parseInt(entity.entityCount);
                                            return sum + (isNaN(count) ? 0 : count);
                                        }, 0) || 0}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {showAddNewObjectModal && (
                <><div className="modal-backdrop fade show"></div>
                    <div className="modal fade show" id="AddNewObject" style={{ display: 'block' }} tabIndex={-1} aria-labelledby="accept-promptLabel" aria-modal="true" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content custom-popup">
                                <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                                    <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                                        {isAddingNewObject ? 'Add New Object' : (isObjectFieldDisabled ? 'Add Object Type' : 'Edit Object')}
                                    </h2>
                                    <button type="button" className="btn close-icon" onClick={handleCloseModal}>
                                        <img src="../images/popup-close.svg" alt="Close" />
                                    </button>
                                </div>

                                <div className="modal-body border-0 px-3 pt-0">
                                    <div className="row mt-4 px-2 pb-3">
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="objectName" className="form-label font-14 font-semibold color-black">
                                                Object <span className="mandate">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control theme-form font-regular font-14"
                                                id="objectName"
                                                placeholder="Enter Object Name"
                                                value={newObjectName}
                                                onChange={(e) => handleInputChange('objectName', e.target.value)}
                                                disabled={isObjectFieldDisabled}
                                            />
                                            {formTouched.objectName && formValidationMsg.objectName &&
                                                <span className="font-12 font-medium alert-text">{formValidationMsg.objectName}</span>
                                            }
                                        </div>

                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="objectType" className="form-label font-14 font-semibold color-black">
                                                Object Type
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control theme-form font-regular font-14"
                                                id="objectType"
                                                placeholder="Enter Object Type"
                                                value={newObjectType}
                                                onChange={(e) => handleInputChange('objectType', e.target.value)}
                                            />
                                            {formTouched.objectType && formValidationMsg.objectType &&
                                                <span className="font-12 font-medium alert-text">{formValidationMsg.objectType}</span>
                                            }
                                        </div>

                                        <div className="col-12 col-lg-6 mb-3">
                                            <label htmlFor="entityCount" className="form-label font-14 font-semibold color-black">
                                                Entity Count <span className="mandate">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control theme-form font-regular font-14"
                                                id="entityCount"
                                                placeholder="Enter Entity Count"
                                                value={newEntityCount}
                                                onChange={(e) => handleInputChange('entityCount', e.target.value)}
                                            />
                                            {formTouched.entityCount && formValidationMsg.entityCount &&
                                                <span className="font-12 font-medium alert-text">{formValidationMsg.entityCount}</span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-0 gap-1">
                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" onClick={handleCloseModal}>
                                        Cancel
                                    </a>
                                    <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold" onClick={handleSaveNewObject}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showDeletePopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Object Type has been Deleted successfully.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showSaveMessage && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/toast-success.svg" alt="toast-success" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Object type has been saved successfully
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showToasterFailedPopup && (
                <div className="position-fixed bg-white top-110px start-50 translate-middle w-md-100">
                    <div className="shadow-sm d-flex rounded-3 custom-toast p-3">
                        <div className="toast-body d-flex align-items-center ">
                            <div className="d-block me-3">
                                <img src="images/fail-icon.svg" alt="toast-fail" />
                            </div>
                            <div className="d-block">
                                <span className="font-medium font-16 color-grey">
                                    Api failed.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteModal && (
                <div className="modal fade show" id="delete" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style={{ display: 'block' }} >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-3">
                            <div className="modal-header pb-0 border-0">
                                <button type="button" className="btn-close cursor-pointer" onClick={closeModal} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="w-100 text-center mb-3">
                                    <img src="images/delete-icon.svg" alt="delete" />
                                </div>
                                <p className="font-semibold font-24 text-center mb-2 red-400">
                                    Delete Confirmation</p>
                                <p className="font-semibold font-16 text-center mb-5">
                                    Are you sure you want to delete? </p>
                                <div className="d-grid gap-2 d-md-inline-flex justify-content-md-center mb-2 mb-md-0 w-100">
                                    <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer px-4" onClick={confirmDeleteEntity}> Yes</a>
                                    <button type="button" className="btn btn-danger red-400 btn-lg px-4 text-white font-14 font-semibold" onClick={closeModal}>   No </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default CurrentDataEnvironmentInventory;

