import { apiCall, apiCall2 } from "../client/apiCall";
import { baseUrl } from "../constant/constant";

// PC_API_1.2
export async function getLeftNavigation(id: string) {
  const response = await apiCall(
    baseUrl + `/connectors/list/vertical/${id}`,
    "GET",
    {}
  );
  return response.data;
}

// PC_API_1.3
export async function getControlAreas(data: any) {
  const response = await apiCall(baseUrl + `/connectors/controlareas/list`, 'POST', data)
  return response.data;
}

// PC_API_1.4
export async function getControlAreaSubMenuQuestions(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getQuestionnarie`, 'POST', data)
  return response.data;
}

// PC_API_1.5
export async function getProjects(verticalId: string, organizationId: string) {
  const response = await apiCall(
    baseUrl + `/connectors/projects/${organizationId}/list/${verticalId}`,
    "GET",
    {}
  );
  return response.data;
}

// PC_API_1.6
export async function getConnectedConnectors(data: any) {
  const response = await apiCall(baseUrl + `/connectors/list`, "POST", data);
  return response.data;
}

// PC_API_1.7
export async function postConnectors(data: any) {
  const response = await apiCall(baseUrl + `/connectors/add`, "POST", data);
  return response;
}

export async function validateAwsConnector(data: any) {
  const response = await apiCall(baseUrl + `/report/validateAwsConnectors`, "POST", data);
  return response;
}

export async function validateAzureConnector(data: any) {
  const response = await apiCall(baseUrl + `/report/validateAzureConnectors`, "POST", data);
  return response;
}

export async function PostAutoQuestionnarie(data: any) {
  const response = apiCall2(baseUrl + `/connectors/autoQuestionnarie`, "POST", data);
  return response;
}
export async function PostInventoryCountDetails(data: any) {
  const response = apiCall2(baseUrl + `/connectors/postInventoryCountDetails`, "POST", data);
  return response;
}
export async function getProfileAndWorkLoad(data: any) {
  const response = await apiCall(baseUrl + `/report/getProfileAndWorkload`, "POST", data);
  return response.data;
}

export async function postProfileAndWorkload(data: any) {
  const response = await apiCall(baseUrl + `/report/postProfileAndWorkload`, "POST", data);
  return response;
}

export async function checkSecurityHubStatus(data: any) {
  const response = await apiCall(baseUrl + `/report/checkSecurityHubStatus`, "POST", data);
  return response.data;
}

export async function checkTrustedAdvisorStatus(data: any) {
  const response = await apiCall(baseUrl + `/report/checkTrustedAdvisorStatus`, "POST", data);
  return response.data;
}
export async function getInventoryDetails(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getEnvironmentInventoryDetails`, "POST", data);
  return response;
}
export async function postInventoryDetails(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postEnvironmentInventoryDetails`, "POST", data);
  return response;
}
export async function deleteInventoryDetails(data: any) {
  const response = await apiCall(baseUrl + `/connectors/deleteEnvironmentDetails`, "Delete", data);
  return response;
}


// PC_API_1.8
export async function postQuestionnaire(
  organizationId: string,
  verticalId: string,
  data: any
) {
  const response = await apiCall(
    baseUrl +
    `/connectors/controlareas/questions/${organizationId}/${verticalId}`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.12
export async function deleteConnectors(attributeId: string) {
  const response = await apiCall(baseUrl + `/connectors/delete`, "POST", {
    attributeId: attributeId,
  });
  return response;
}

// PC_API_1.14
export async function addProjects(data: any) {
  const response = await apiCall(
    baseUrl + `/connectors/project/add`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.15
export async function deleteProject(data: any) {
  const response = await apiCall(
    baseUrl + `/connectors/project/delete`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.16
export async function postQuestionnaireReport(
  organizationId: string,
  verticalId: string,
  projectId: string | null,
  data: any
) {
  const response = await apiCall(
    baseUrl +
    `/connectors/questionnaire/report/${organizationId}/${verticalId}/${projectId}`,
    "POST",
    data
  );
  return response;
}

export async function postChanllengesAndRecommendations(data: any) {
  const response = await apiCall(baseUrl + `/report/postChanllengesAndRecommendations`, 'POST', data)
  return response.data;
}

// PC_API_1.17
export async function getGenerateReportButtonFlag(data: any) {
  const response = await apiCall(baseUrl + `/connectors/generateReportButton`, 'POST', data)
  return response.data;
}

// PC_API_1.18
export async function postReassessProject(data: any) {
  const response = await apiCall(
    baseUrl + `/assessments/reassess`,
    "POST",
    data
  );
  return response;
}

// PC_API_1.18
export async function getSalesforceCloud(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getSalesforceCloud`, 'POST', data)
  return response.data;
}

// PC_API_1.18
export async function postSalesforceCloud(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postSalesforceCloud`, 'POST', data)
  return response;
}

export async function getServiceNowDropdown() {
  const response = await apiCall(baseUrl + `/connectors/getServiceNowDropdowns`, 'GET', {})
  return response.data;
}

export async function getNotReviewedItems(data: any) {
  const response = await apiCall(baseUrl + `/connectors/reviewQuestionsAnswers`, 'POST', data)
  return response.data;
}

export async function getIntraAssessmentDetails() {
  const response = await apiCall(baseUrl + "/assessments/infraAssessment/getInfraOrganizationMenus", 'GET')
  return response;
}

export async function GetCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/GetCostEstimation", 'POST', data)
  return response;
}


export async function UploadCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/uploadCostEstimation", 'POST', data)
  return response;
}

export async function postBusinessWorkFlow(data: any) {
  const response = await apiCall(baseUrl + "/connectors/postAttributeDetails", 'POST', data)
  return response;
}

export async function generateConclusionDetails(data: any) {
  const response = await apiCall(baseUrl + "/connectors/generateConclusion", "POST", data)
  return response
}
export async function getAttributeDetails(data: any) {
  const response = await apiCall(baseUrl + "/connectors/getAttributeDetails", "POST", data)
  return response
}
export const postSwotDetails = async (payload: any) => {
  const response = await apiCall(baseUrl +"/connectors/postSwotDetails", "POST", payload);
  return response;
};
export async function fetchChallengesAndRecommendation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/GetAllChallengesAndRecommendation", "POST", data)
  return response
}

export async function postArchitectureDetails(data: any) {
  const response = await apiCall(baseUrl + "/connectors/architectureDetails", "POST", data)
  return response
}

export async function deleteAttributeRecords(data: any) {
  const response = await apiCall(baseUrl + "/connectors/deleteAttribute", "DELETE", data)
  return response
}

export async function PostCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/postCostEstimation", 'POST', data)
  return response;
}

export async function DeleteCostEstimation(data: any) {
  const response = await apiCall(baseUrl + "/connectors/deleteCostEstimation", 'POST', data)
  return response;
}
export async function postCurrentCost(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postCurrentCost`, "POST", data);
  return response;
}

export async function getFlowComponents(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getFlowComponents`, "POST", data);
  return response;
}

export async function postFlowComponents(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postFlowDetails`, "POST", data);
  return response;
}

// PS_challengesRecommendation_06, PS_challengesRecommendation_08
export async function GetChallengesRecommendations(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getChallengesRecommendations`, "POST", data);
  return response;
}
// PS_challengesRecommendation_19, PS_challengesRecommendation_21, PS_challengesRecommendation_34, PS_challengesRecommendation_35
export async function PostChallengesRecommendations(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postChallengesRecommendations`, "POST", data);
  return response;
}
// PS_challengesRecommendation_46, PS_challengesRecommendation_48
export async function DeleteChallengesRecommendations(data: any) {
  const response = await apiCall(baseUrl + `/connectors/deleteChallengesRecommendations`, "POST", data);
  return response;
}
//PS_Databricks_Inventory_71
export async function PostNotebookDetails(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postNotebookDetails`, "POST", data);
  return response;
}
//PS_Databricks_Inventory_22
export async function GetNotebookDetails(data: any) {
  const response = await apiCall(baseUrl + `/connectors/getNotebookDetails`, "POST", data);
  return response;
}

export async function GetDatawarehouseArchitecture(data: any) {
  const response = await apiCall(baseUrl + "/connectors/getDatawarehouseArchitecture", 'POST', data)
  return response;
}

export async function PostDatawarehouseArchitecture(data: any) {
  const response = await apiCall(baseUrl + "/connectors/postDatawarehouseArchitecture", 'POST', data)
  return response;
}

export async function DeleteDatawarehouseArchitecture(data: any) {
  const response = await apiCall(baseUrl + "/connectors/deleteDataWarehouseArchitecture", 'POST', data)
  return response;
}

export async function AddDatawarehouseArchitecture(data: any) {
  const response = await apiCall(baseUrl + "/connectors/addDataWarehouseArchitecture", 'POST', data)
  return response;
}

export async function postAzureCost(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postAzureCost`, "POST", data);
  return response;
}


export async function PostDatawarehouseArchitectureAi(data: any) {
  const response = await apiCall(baseUrl + `/connectors/postDatawarehouseArchitectureAi`, "POST", data);
  return response;
}

export async function GenerateAiInventory(data: any) {
  const response = await apiCall(baseUrl + `/connectors/generateAiInventory`, "POST", data);
  return response;
}

export async function GenerateAiChallengesRecommendation(data: any) {
  const response = await apiCall(baseUrl + `/connectors/generateAiChallengesRecommendation`, "POST", data);
  return response;
}

export async function DeleteAiChallengesRecommendation(data: any) {
  const response = await apiCall(baseUrl + `/connectors/deleteAiChallengesRecommendations`, "POST", data);
  return response;
}

export async function GetAllConnector(data: any) {
  const response = await apiCall(baseUrl + `/connectors/GetAllConnectors`, "POST", data);
  return response;
}