import React, { createContext, ReactNode, SetStateAction, useContext, useState } from "react"

const DatabricksContext = createContext<DataContextType | undefined>(undefined);

interface DataContextType {
    businessOverviewData: string;
    setBusinessOverviewData: React.Dispatch<SetStateAction<string>>;
    isBusinessOverviewUpdated: boolean;
    setIsBusinessOverviewUpdated: React.Dispatch<SetStateAction<boolean>>;
    isChallengesRecommendationsDataUpdatedswot:boolean
    isChallengesRecommendationsDataUpdatedconclusion:boolean
    setIsChallengesRecommendationsDataUpdatedswot :React.Dispatch<SetStateAction<boolean>>;
    setIsChallengesRecommendationsDataUpdatedconclusion:React.Dispatch<SetStateAction<boolean>>;
    handlechallengesandrecommendationsUpdated : (modified: boolean) => void

}


interface DatabricksDataProviderType {
    children: ReactNode
}


const DatabricksContextObject: React.FC<DatabricksDataProviderType> = ({ children }) => {
    const [businessOverviewData, setBusinessOverviewData] = useState<string>("");
    const [isBusinessOverviewUpdated, setIsBusinessOverviewUpdated] = useState<boolean>(false);
    const [isChallengesRecommendationsDataUpdatedswot, setIsChallengesRecommendationsDataUpdatedswot] = useState<boolean>(false);
    const [isChallengesRecommendationsDataUpdatedconclusion, setIsChallengesRecommendationsDataUpdatedconclusion] = useState<boolean>(false);

    const handlechallengesandrecommendationsUpdated = (modified:boolean) => {
       
        setIsChallengesRecommendationsDataUpdatedswot(modified)
        setIsChallengesRecommendationsDataUpdatedconclusion(modified)
        
    };
    
    const dataContextObject: DataContextType = {
        businessOverviewData,
        setBusinessOverviewData,
        isBusinessOverviewUpdated,
        setIsBusinessOverviewUpdated,
        isChallengesRecommendationsDataUpdatedswot,
        setIsChallengesRecommendationsDataUpdatedswot,
        isChallengesRecommendationsDataUpdatedconclusion,
        setIsChallengesRecommendationsDataUpdatedconclusion,
        handlechallengesandrecommendationsUpdated
    }

    return (
        <DatabricksContext.Provider value={dataContextObject}>
            {children}
        </DatabricksContext.Provider>
    )
}


export const useDatabricksContextObject = () => {
    
    const context = useContext(DatabricksContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context

}


export default DatabricksContextObject;