import react, { useState, useEffect } from "react"
import { domainsModel, editedAssessmentModel, formValidationMsgModel, verticalModel } from "../interface/AssessmentGridModel"

const AssessmentForm = (props: any) => {
    const initialValidationMsg = {
        domainId: "",
        verticalId: "",
        projectName: ""
    }
    const selectedVertical = props.domains?.find((element: domainsModel) => {
        return element.domainId === props?.editedData.domainId
    })?.verticals
    const [assessmentData, setAssessmentData] = useState<editedAssessmentModel>({ ...props?.editedData, formVerticalId: selectedVertical?.length > 1 ? props?.editedData.projectId : "" })
    const [verticals, setVerticals] = useState<verticalModel[]>(selectedVertical?.length > 1 ? selectedVertical : [])
    const [validationMsg, setValidationMsg] = useState<formValidationMsgModel>(initialValidationMsg)
    //Function to handle the on change event
    const onChangeValue = (id: string, value: string) => {
        if (id === "domainId") {
            setValidationMsg({ ...validationMsg, verticalId: "", domainId: "" })
            const selectedDomain = props.domains?.find((element: domainsModel) => {
                return element.domainId === value
            })
            if (selectedDomain?.verticals?.length > 1) {
                setVerticals(selectedDomain?.verticals)
                setAssessmentData({ ...assessmentData, [id]: value, verticalId: "" })
            }
            else {
                setVerticals([])
                setAssessmentData({ ...assessmentData, [id]: value, verticalId: selectedDomain?.verticals[0]?.verticalId })
            }

        }
        else if (id === "verticalId") {
            setAssessmentData({ ...assessmentData, verticalId: value })
        }
        else {
            setValidationMsg({ ...validationMsg, [id]: "" })
            setAssessmentData({ ...assessmentData, [id]: value })
        }
    }
    //On submit form
    const onSubmitForm = () => {
        if (assessmentData?.domainId?.length === 0 || assessmentData?.projectName?.length === 0 || assessmentData?.verticalId?.length === 0) {
            setValidationMsg({
                domainId: assessmentData?.domainId?.length === 0 ? "Select any Practice" : "",
                verticalId: assessmentData?.verticalId?.length === 0 ? "Select any Assessment Type" : "",
                projectName: assessmentData?.projectName?.length === 0 ? "Enter an Assessment Name" : ""
            })
            return
        }
        props.addAssessment(assessmentData)
    }
    //Function to bind the assessment type
    const bindVerticals = () => {
        return (
            verticals?.map((data: verticalModel) => {
                return (
                    <option value={data.verticalId} key={data.verticalId}>{data.verticalName}</option>
                )
            })
        )
    }

    //Function to bind the domains
    const bindDomains = () => {
        return (
            props?.domains?.map((data: domainsModel) => {
                return (
                    <option value={data.domainId} key={data.domainName}>{data.domainName}</option>
                )
            })
        )
    }

    return (
        <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content custom-popup w-650">
                    <div className="modal-header border-0 justify-content-between align-items-center position-relative px-4 pt-4 pb-2">
                        <h2 className="modal-title mb-0 font-20 font-semibold primary-textcolor" id="accept-promptLabel">
                            Start New Assessment</h2>
                        <button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" onClick={() => { props.setHideForm(true) }}><img src="images/popup-close.svg" alt="Close" /></button>
                    </div>
                    <div className="modal-body border-0 px-4">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="selectDomain">Select Practices<span className="mandate">*</span></label>
                                <select name="selectDomain" id="selectDomain" className="form-select form-control custom-form" value={assessmentData.domainId} onChange={(event: any) => { onChangeValue("domainId", event.target.value) }} disabled={assessmentData.projectId?.length !== 0}>
                                    <option selected disabled={true} value="">Select Practice</option>
                                    {bindDomains()}
                                </select>
                                <span className='color-red'>{validationMsg.domainId}</span>
                            </div>

                            <div className="col-md-6 mb-4">
                                <label className="form-label font-14 font-semibold" htmlFor="assesType">Assessment Type<span className="mandate">*</span></label>

                                <select name="assesType" id="assesType" className="form-select form-control custom-form" value={verticals?.length > 1 ? assessmentData.verticalId : ""} onChange={(event: any) => { onChangeValue("verticalId", event.target.value) }} disabled={assessmentData?.projectId?.length != 0 || verticals?.length == 0}>
                                    <option disabled={true} value="">Select assessment</option>
                                    {bindVerticals()}
                                </select>
                                <span className='color-red'>{validationMsg.verticalId}</span>

                            </div>

                            <div className="col-md-6">
                                <label className="form-label font-14 font-semibold" htmlFor="assesName">Assessment Name<span className="mandate">*</span></label>
                                <input type="text" id="assesName" className="form-control custom-form" placeholder="Enter assessment name" value={assessmentData.projectName} onChange={(event: any) => { onChangeValue("projectName", event.target.value) }} />
                                <span className='color-red'>{validationMsg.projectName}</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer px-4 border-0 justify-content-end pb-4 pt-4 gap-1">
                        <a className="theme-link-btn font-14 font-semibold text-center order-md-first cursor-pointer" data-bs-dismiss="modal" onClick={() => { props.setHideForm(true) }}>Cancel</a>
                        <button type="button" className="btn btn-dark theme-primary-btn border-0 font-semibold " onClick={() => { onSubmitForm() }}>Save</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AssessmentForm