import { jwtDecode } from "jwt-decode";
import { encryptStorage } from "../../constant/constant";
import { getConnectorsData } from "../../helpers/encryption";
import { getSasToken } from "../../service/ReportApi";
import { useRef, useState } from "react";
import { read, utils } from "xlsx";

export default function TemplateBasedConnectorForm(props: any) {
  const { activeTab, activeConnector, verticalName, setExcelTemplateData, validateConnectorInput, setConnectorFormData, resetValidationMessages, setShowConnectorForm, editConnector, handleDeleteClick, connectorGridData, connectedConnectorShow } = props;
  const attachment = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [validationMessage, setValidationMessage] = useState<string>("");

  const DatabricksInventoryTemplate = [
    "Object",
    "ObjectType",
    "ObjectName",
    "SourceObjectType",
    "SourceObjectPath",
    "EntityCount",
  ];

  const attachmentRemover = () => {
    if (attachment?.current) {
      attachment.current.files = null;
      attachment.current.value = "";
    }
  };

  const removeFile = () => {
    setFileName("");
    setValidationMessage("");
    attachmentRemover();
  };

  const readFile = (event: any) => {
    let array: any = [];
    const file = event.target.files?.[0];
    if (file) {
      // Check if the file is an XLSX file
      const typeArr = ["vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
      const fileType = file.type.split("/")[1];
      const isValidFileType = typeArr.includes(fileType);
  
      if (isValidFileType) {
        setFile(file); // Set the file in state
        setValidationMessage(""); // Clear any previous validation messages
  
        const reader = new FileReader();
        reader.onload = (e) => {
          // Read the file content
          const wb = read(e?.target?.result);
          const sheets = wb.SheetNames;
          const rows: any = utils.sheet_to_json(wb.Sheets[sheets[0]]);
  
          if (rows.length >= 1) {
            setFileName(file.name); // Set the file name in state
            setValidationMessage(""); // Clear any previous validation messages
  
            const columnTitle = utils.sheet_to_json(wb.Sheets[sheets[0]], {
              header: 1,
            })[0];
  
            // Validate column titles
            const expectedColumns = activeConnector === "Inventory" && verticalName === "Databricks"
              ? DatabricksInventoryTemplate
              : null;
  
            if (JSON.stringify(expectedColumns) === JSON.stringify(columnTitle)) {
              // Process each row and validate required fields
              rows.forEach((data: any) => {
                const requiredFields = ["Object"];
                const isValidRow = requiredFields.every((field) => {
                  const value = data[field];
                  return (value && (typeof value === "string" && value.trim() !== "")) || typeof value === "number";
                });
  
                if (!isValidRow) {
                  attachmentRemover();
                  setFileName("");
                  setValidationMessage("Please upload the correct xlsx file");
                  return;
                }
  
                let obj = {
                  object: data["Object"],
                  objectType: data["ObjectType"],
                  objectName: data["ObjectName"],
                  sourceObjectType: data["SourceObjectType"],
                  sourceObjectPath: data["SourceObjectPath"],
                  entityCount: data["EntityCount"],
                };
                array.push(obj);
              });
  
              // Set the processed data
              setExcelTemplateData(array);
            } else {
              // Invalid column titles
              attachmentRemover();
              setFileName("");
              setValidationMessage("Please upload the correct xlsx file with valid column titles.");
            }
          } else {
            // File is empty
            setValidationMessage("The uploaded file is empty. Please upload a valid file.");
            attachmentRemover();
            setFileName("");
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        // Not a valid XLSX file
        attachmentRemover();
        setFileName("");
        setValidationMessage("Please upload a valid file.");
      }
    }
  };

  const downloadTemplate = async () => {
    try {
      let value = encryptStorage.getItem("jwt");
      let parsedJtk: any = "";
      let storageAccountName: any, containerName: any;
      if (value) {
        parsedJtk = jwtDecode(value);
        storageAccountName = getConnectorsData({
          key: parsedJtk.ek,
          encryptedMessage: process.env.REACT_APP_BLOB_ACCOUNT_NAME!,
        });
        containerName = getConnectorsData({
          key: parsedJtk.ek,
          encryptedMessage: process.env.REACT_APP_BLOB_CONTAINER!,
        });
      }
      const sas = await getSasToken("r");
      if (activeConnector === "Solarwinds") {
        window.open(
          `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/SolarWinds Infra Template.xlsx` +
          `?${sas.data}`
        );
      } else if (activeConnector === "Inventory") {
        switch (verticalName) {
          case "MDPW":
            window.open(
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/AWS MDPW Inventory Template.xlsx` +
              `?${sas.data}`
            );
            break;
          case "VMware":
            window.open(
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Infrastructure VMware Inventory Template.xlsx` +
              `?${sas.data}`
            );
            break;
          case "Databricks":
            window.open(
              `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Databricks Inventory Template.xlsx` +
              `?${sas.data}`
            );
            break;
        }
      } else if (activeConnector === "Azure") {
        window.open(
          `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/Azure Template - Infra connector.xlsx` +
          `?${sas.data}`
        );
      } else {
        window.open(
          `https://${storageAccountName}.blob.core.windows.net/${containerName}/Templates/AWS template - Infra connector.xlsx` +
          `?${sas.data}`
        );
      }
    } catch (error) {
      console.error("Error occurred while downloading the template.", error);
    }
  };

  const hasTemplateData = Array.isArray(connectorGridData) &&
    connectorGridData.some(grid =>
      grid.Attributes && grid.Attributes.some((attr: any) => attr.attributeName === "template")
    );

  const renderConnectorGrid = () => {
    return connectorGridData.filter((grid: any) =>
      grid.Attributes.some((attr: any) => attr.attributeName === "template")
    ).map((grid: any, index: number) => (
      <div
        key={index}
        className="table-responsive mb-4 border border-1 rounded p-2"
      >
        <table className="table table-borderless mb-0">
          <thead className="font-semibold"></thead>
          <tbody className="font-regular">
            <tr className="text-nowrap connector-table">
              <td className="text-start text-nowrap">
                <img src="images/ms-excel-icon.svg" alt="logo" className="me-2" />
                <span className="font-14 font-semibold color-black-v2 me-3">
                  Inventory
                  {connectorGridData.length > 1}
                </span>
                <span
                  hidden={!connectedConnectorShow.includes(activeConnector)}
                  className="severity severity-low-bg me-2 font-12"
                >
                  <span className="severity-point severity-low me-2" />
                  Connected
                </span>
              </td>
              <td className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-outline-danger custom-btn ms-3 font-medium"
                  onClick={() => handleDeleteClick(grid.attributeId)}
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ));
  };

  return (
    <div className={activeTab === "Template-based" ? "tab-pane fade show active" : "tab-pane fade"} id="template-based" role="tabpanel">
      {hasTemplateData ? renderConnectorGrid() : (
        <>

          <p className="font-14 font-bold color-black mb-4">Step 1</p>
          <div className="d-flex mb-3">
            <span
              onClick={() => {
                downloadTemplate();
              }}
            >
              <img
                src="images/download.svg"
                alt="download"
                title="download"
                className="me-2"
              />
              <span className="link-blue font-12 font-semibold link-blue cursor-pointer">
                Download Template
              </span>
            </span>
          </div>
          <p className="form-label font-14 font-regular color-black mb-4">
            Download the template
          </p>
          <p className="font-14 font-bold color-black mb-4">Step 2</p>
          <p className="form-label font-14 font-regular color-black mb-4">
            Enter valid details on the template
          </p>
          <p className="font-14 font-bold color-black mb-3">Step 3</p>
          <div className="d-flex mb-3 cursor-pointer">
            <span className="upload-btn-wrapper me-2 d-block">
              <img src="images/upload.svg" alt="browse" className="me-2" />
              <span className="link-blue font-12 font-semibold link-blue">
                Upload Template
              </span>
              <input
                type="file"
                name="myfile"
                ref={attachment}
                disabled={fileName !== "" ? true : false}
                className="cursor-pointer"
                onChange={(e) => {
                  readFile(e);
                }}
              />
            </span>
          </div>
          <div>
            <span className="font-14 font-medium color-grey mt-1 d-block">
              {fileName}
              <span className="cursor-pointer ms-3" title="Cancel">
                {fileName ? (
                  <img
                    src="images/upload-close.svg"
                    alt="upload-close"
                    onClick={() => {
                      removeFile();
                    }}
                  />
                ) : (
                  <></>
                )}
              </span>
            </span>
            <p className="mt-2 mb-0 color-red font-14 font-regular">
              {validationMessage}
            </p>
          </div>
          <div className="d-grid gap-2 d-md-inline-flex justify-content-md-end mb-5 w-100">
            <button
              type="button"
              className="btn btn-dark theme-primary-btn border-0 font-14 font-semibold "
              disabled={!file ? true : false}
              onClick={() => { validateConnectorInput(file) }}
            >
              Save
            </button>
            <a
              className="theme-link-btn cursor-pointer  font-semibold text-center order-md-first"
              onClick={() => {
                setConnectorFormData({});
                resetValidationMessages(activeConnector);
                setShowConnectorForm(true);
              }}
            >
              Cancel
            </a>
          </div>
        </>
      )}
    </div>
  );
}